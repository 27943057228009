import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, CloseButton, ValidationInput} from "components";
import {Colors, ErrorText, FindErrorItem, FindLoad, FindSuccessItem, useGlobalTextStyles} from "utils";
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, systemActions} from "store";
import {Button} from "@material-ui/core";
import {modalsStyle} from "components";
import {agentsInfoFragments} from "../../agents/agentInfo/core";

export const SystemPayrollModal = ({handleClose, info}) => {
    const classes = modalsStyle()
    const agentsStyle = agentsInfoFragments()
    const globalText = useGlobalTextStyles();
    const dispatch = useDispatch()
    const [revenue, setRevenue] = useState(info ? info.start : '')
    const [percentage, setPercentage] = useState(info ? info.percent : 'not')
    const [error, setError] = useState('')
    const success = FindSuccessItem('ADD_SYSTEM_BRACKET')
    const edit = FindSuccessItem('EDIT_SYSTEM_BRACKET')
    const createLoader = FindLoad('ADD_SYSTEM_BRACKET')
    const editLoader = FindLoad('EDIT_SYSTEM_BRACKET')
    const backErr = FindErrorItem(info ? 'EDIT_SYSTEM_BRACKET' : 'ADD_SYSTEM_BRACKET')

    const addBracket = () => {
        const data = {
            start: parseInt(revenue),
            percent: parseInt(percentage),
        }

        const rev =
            revenue === 0 ? true :
                revenue === '0' ? true :
                    revenue ? revenue : ''

        if (rev && percentage !== 'not' && +percentage !== 0) {
            if (info) {
                dispatch(systemActions.editBracket(info.id || info._id, data))
            } else {
                dispatch(systemActions.addBracket(data))
            }
        } else {
            setError(
                !revenue && revenue !== 0 ? 'revenue' :
                    !percentage ? 'percentage' :
                        percentage === 'not' ? 'percentage' :
                            +percentage === '0' ? 'percentage' :
                                +percentage === 0 ? 'percentage' :
                                    ''
            )
        }
    }

    useEffect(() => {
        if (success) {
            handleClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess('ADD_BRACKET'))
        }
        if (edit) {
            handleClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_AGENT_BRACKET'))
        }
    }, [success, edit])

    const handleChangeRevenue = (ev) => {
        error === 'revenue' && setError('')
        setRevenue(ev.target.value)
        handleRemoveError()
    }

    const handleChangePercentage = (ev) => {
        if (ev.target.value <= 100) {
            error === 'percentage' && setError('')
            setPercentage(ev.target.value)
        }
    }

    const handleRemoveError = () => {
        backErr?.error === 'A bracket with this start value exists' && dispatch(httpRequestsOnErrorsActions.removeError('ADD_SYSTEM_BRACKET'))
        backErr?.error === 'A bracket with this start value already exists' && dispatch(httpRequestsOnErrorsActions.removeError('EDIT_SYSTEM_BRACKET'))
    }

    useEffect(() => {
         handleRemoveError()
    }, [])

    return (
        <div className={classes.payroll}>
            <div className={classes.closeButton}>
                <CloseButton handleCLic={handleClose}/>
            </div>

            <div className={classes.deleteModalWrapper}>
                <p className={globalText.modalTitle}>{info ? 'Edit Payroll Bracket' : 'Want to Add Payroll Bracket?'}</p>
                <p className={globalText.modalText}>{!info && 'Please fill out the fields below fields to add a Payroll Bracket.'}</p>

                <div className={agentsStyle.payrollInputs}>
                    <ValidationInput
                        onChange={handleChangeRevenue}
                        value={revenue === 0 ? '0' : revenue}
                        typeError={error === 'revenue' ? ErrorText.field :
                            backErr?.error === 'A bracket with this start value exists' ? 'A bracket with this start value exists' :
                              backErr?.error === 'A bracket with this start value already exists' ? 'A bracket with this start value already exists' :
                                ''}
                        variant={"outlined"}
                        name={"revenue"}
                        label={"Revenue*"}
                        type={"number"}
                    />
                    <ValidationInput
                        onChange={handleChangePercentage}
                        typeError={error === 'percentage' ? ErrorText.field : ''}
                        value={percentage === 'not' ? '' : percentage}
                        style={agentsStyle.percentage}
                        variant={"outlined"}
                        name={"percentage"}
                        label={"Percentage*"}
                        type={"number"}
                    />
                </div>

                <div className={classes.editSave}>
                    <div style={{width: '100%'}}>
                        <AddModalButton
                            loading={!!createLoader?.length || !!editLoader?.length}
                            styles={info ? {width: "100%", marginTop: '10px'} : {marginTop: '10px',}}
                            handleClick={addBracket}
                            text={info ? 'Save' : 'Add Payroll Bracket'}
                        />
                    </div>
                    {info &&
                        <Button
                            style={{
                                textTransform: "capitalize",
                                width: "100%",
                                height: "48px",
                                background: Colors.ThemeGray,
                                borderRadius: "8px",
                                marginLeft: "16px",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: Colors.TextPrimary,
                            }}
                            onClick={handleClose}
                        >
                            {'Cancel'}
                        </Button>
                    }
                </div>
            </div>
        </div>
    )
}