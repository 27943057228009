import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Colors,
  Images,
  PermissionsList,
  RolePermission,
  useGlobalStyles,
  useGlobalText,
  useModal, usePrimaryModal,
  userType,
} from 'utils';
import { Circle, CsvFile, FullTable, Line, MinLoader } from 'components';
import { CreateLoadBtn } from '../../createLoad/core';
import { groupLoadsBody, groupLoadsHead, ACTION_TYPE } from './constants';
import { loadActions, templateActions } from 'store';
import { loadCsv } from '../../../../pages/loads/fragments/constants';
import { buttonsStyle } from '../../../../components/buttons/styles';
import { ExportInvoice } from './exportInvoice';
import { LoadDetailSwitcher } from '../../../../pages';

export const GroupLoads = ({}) => {
  const { groupLoads, groupById } = useSelector((state) => ({
    groupLoads: state.loads.groupLoads,
    groupById: state.loads.groupById,
  }));
  const classes = buttonsStyle();
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const dispatch = useDispatch();
  const history = useHistory();
  // const info = history?.location?.state;
  const params = useParams();
  const { open } = useModal();
  const { openPrimary } = usePrimaryModal();


  useEffect(() => {
    dispatch(loadActions.getGroupPayableLoads(params?.id));
    dispatch(loadActions.getGroupLoads(params?.id));
  }, []);

  const pushPageDetails = (id) => {
    openPrimary(<LoadDetailSwitcher loadId={id} />)

    // history.push({
    //   pathname: `/grouped-load/${id}/group/${groupById?.id}`,
    //   state: { loadParams: { ...info } },
    // });
  };

  return (
    <div>
      <div className="space-between">
        <div className={globalStyles.centerItem}>
          <Circle number={3} back={Colors.ThemeAqua} />
          <p className={globalText.title}>Loads</p>
        </div>
        <div className="align-center" style={{ gap: 12 }}>
          <button
            className={classes.csvExportBtn}
            onClick={() => open(<ExportInvoice />)}
          >
            <img src={Images.download} alt="icon" />
            <p className={globalText.downloadText}>
              Generate Invoice
            </p>
          </button>
          {groupById?.status !== 'CANCELLED' && userType !== 'ADMIN' &&
            <CreateLoadBtn groupId={params?.id} />
          }
        </div>
      </div>

      <div className={globalStyles.flexAndFullWidth}>
        <div className={'MuiAccordionDetails-root'}>
          <Line height={'auto'} />
        </div>
        <div className="full-width" style={{ marginBottom: 24 }}>
          <FullTable
            height={'medium'}
            head={groupLoadsHead}
            body={groupLoadsBody}
            loadingType={ACTION_TYPE}
            list={groupLoads || []}
            noText={'Loads'}
            handleClick={pushPageDetails}
          />
        </div>
      </div>
    </div>
  );
};