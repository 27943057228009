import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionsList, RolePermission, useGlobalStyles, useModal } from 'utils';
import { AddExpensesModal, officesInfoFragments } from './index';
import { FullTable, Line, TableWrapper } from 'components';
import { expensesBody, expensesHeader } from './constant';
import { CustomDeleteModal } from '../../../modals';
import { officeActions } from 'store';
import { useParams } from 'react-router-dom';

const ACTION_TYPE_DELETE = 'DELETE_OFFICE_EXPENSES'

export const Expenses = () => {
  const { officeById } = useSelector((state) => ({
      officeById: state.offices.officeById,
    }),
  );
  const dispatch = useDispatch();
  const params = useParams();
  const { open, close } = useModal();
  const classes = officesInfoFragments();
  const globalStyle = useGlobalStyles();

  const handleCLick = (item, name) => {
    if (name === 'edit') {
      open(<AddExpensesModal info={item} params={params} />);
    }
    if (name === 'delete') {
      open(
        <CustomDeleteModal
          actionType={ACTION_TYPE_DELETE}
          text='Delete Expense?'
          info={'Expense'}
          handleDel={() => {
            dispatch(officeActions.deleteOfficeExpense(params?.id, item?.id));
          }}
          afterSuccess={close}
        />,
      );
    }
  };


  return (
    <div className={classes.officeManagement}>
      <div className={classes.lineWrapper}>
        <Line height={'auto'} />
        <div className={globalStyle.fullScreen}>
          <div className={classes.expensesWrapper}>
            <TableWrapper
              addButton={'Add Expense'}
              tab={'none'}
              buttonsTab={true}
              buttonsTabAddButton={true}
              handleClick={() => {
                open(<AddExpensesModal params={params}/>);
              }}
              createPermisson={PermissionsList?.CREATE_UPDATE_OFFICE}
            >
              <FullTable
                height={'small'}
                head={expensesHeader}
                body={expensesBody()}
                list={officeById?.expenses}
                noText={'Expenses'}
                handleClickButton={handleCLick}
              />
            </TableWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};