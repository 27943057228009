import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FullTable, SimpleModal, TableWrapper} from "components";
import {httpRequestsOnSuccessActions, loadActions} from "store";
import {ACTION_TYPE, PaymentHead, PaymentVBody, VERIFICATION} from "./constants";
import {CollectDebt} from "fragments";
import {authService} from "../../store/loads/loads.service";
import { FindSuccessItem, usePrimaryModal } from '../../utils';
import { LoadDetailSwitcher, RENDER_LOADS } from '../loads';

export const PaymentVerification = ({}) => {
    const {loadsList} = useSelector((state) => ({
        loadsList: state.loads.loadsList,
    }));
    const history = useHistory()
    const info = history?.location?.state
    const dispatch = useDispatch()
    const [selectedRow, setSelectedRow] = useState('')
    const [openCollect, setOpenCollect] = useState(false)
    const { openPrimary } = usePrimaryModal();
    const renderLoads = FindSuccessItem(RENDER_LOADS);

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        return filteredInfo
    }

    useEffect(() => {
        handleGet('loading')
        const interval = setInterval(() => {
            handleGet('noLoad')
        }, 30000);
        return () => clearInterval(interval);
    }, [info]);

    useEffect(() => {
        if (renderLoads) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_LOADS))
            handleGet('noLoad');
        }
    }, [renderLoads]);

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad'
        const filteredInfo = renderParams()
        dispatch(loadActions.getLoadsByStatus({...filteredInfo, department: VERIFICATION}, loader))
    }

    const handleSubmit = async (rowItem, item) => {
        setSelectedRow(rowItem)
        if (item === 'APPROVE') {
            dispatch(httpRequestsOnSuccessActions.appendSuccess('MINI_LOAD', rowItem.id))
            try {
                await authService.appendPayableService(rowItem.id)
                handleGet('noLoad')
                dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD', rowItem.id))
            } catch (e) {
                dispatch(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD', rowItem.id))
            }
        }
        if (item === 'COLLECT') {
            handleCollect()
        }
    }

    const handleCollect = () => {
        setOpenCollect(!openCollect)
    }

    const pushPageDetails = (id) => {
        openPrimary(<LoadDetailSwitcher loadId={id} />)

        // history.push({
        //     pathname: `/load/${id}`,
        //     state: {loadParams: {...info}, parent: '/paymentVerification', detail: 'payableDetails',}
        // })
    }

    return (
        <div>
            <TableWrapper
                buttonsTab={true}
                link={true}
                tab={'none'}
                buttonsTabAddButton={false}
            >
                <FullTable
                    head={PaymentHead}
                    body={PaymentVBody}
                    loadingType={ACTION_TYPE}
                    list={loadsList?.loads}
                    listCount={loadsList?.count}
                    handleClick={pushPageDetails}
                    noText={'Processing'}
                    dateFilter={true}
                    handleClickButton={handleSubmit}
                />
            </TableWrapper>

            <SimpleModal
                handleOpenClose={handleCollect}
                openDefault={openCollect}
                content={
                    <CollectDebt
                        handleClose={handleCollect}
                        data={selectedRow}
                    />
                }
            />
        </div>
    )
}