import React, { useState } from 'react';
import { Popover } from '@material-ui/core';

export const ContactInfoPopover = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (e) => {
    setAnchorEl(prev => prev ? null : e?.currentTarget);
  };


  const convertedPhone = (phone) => {
    return phone ? '(' + phone?.substr(0, 3) + ') ' + phone?.substr(3, 3) + '-' + phone?.substr(6, 6) : 'Not Set';
  };

  const renderText = `${items?.[0]?.name}
   ${items?.[0]?.email ? ', ' + items?.[0]?.email : ''}
     ${convertedPhone(items?.[0]?.phone) ? ', ' + convertedPhone(items?.[0]?.phone) : ''}`;

  return (
    <div className='contact-info-popover-wrapper'>
      <div className='contact-info-main-text-button'>
        <p className='contact-info-main-text'>
          {renderText}
        </p>
        <button
          className='contact-inf-more-button'
          onClick={handleClick}
        >
          More
        </button>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        classes={{
          paper:'contact-info-main-popover-wrapper'
        }}
      >
        <div className='contact-info-popover-box'>
          <p className='contact-info-popover-box-title'>
            More Contacts
          </p>

          <div className='contact-info-popover-items-wrapper'>
            {items?.map((item, index) => (
              <div className='contact-info-popover-item-box' key={index}>
                <div className='contact-info-popover-item-box-row'>
                  <p className='contact-info-popover-item-box-row-name'>
                    Contact Name:
                  </p>

                  <p className='contact-info-popover-item-box-row-desc'>
                    {item?.name || 'Not Set'}
                  </p>
                </div>

                <div className='contact-info-popover-item-box-row'>
                  <p className='contact-info-popover-item-box-row-name'>
                    Phone Number:
                  </p>

                  <p className='contact-info-popover-item-box-row-desc'>
                    {convertedPhone(item?.phone) || 'Not Set'}
                  </p>
                </div>

                <div className='contact-info-popover-item-box-row'>
                  <p className='contact-info-popover-item-box-row-name'>
                    Email:
                  </p>

                  <p className='contact-info-popover-item-box-row-desc'>
                    {item?.email || 'Not Set'}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

