import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from '@material-ui/core';

export const TextToolTip = ({
                              text,
                              content,
                              lineClamp = 1,
                              arrow = false,
                              priceRow = false,
                              phoneRow = false,
                              allowPlusPref = false,
                              allowMinusPref = false,
                              classNameWrapper,
                              classNameText,
                              textStyles,
                              placement = 'bottom'
                            }) => {
  const [dontShowTooltip, setShowingTooltip] = useState(true);
  const targetRef = useRef(null);

  const convertedPhone = text ? '(' + text?.substr(0, 3) + ') ' + text?.substr(3, 3) + '-' + text?.substr(6, 6) : 'Not Set';

  const checkOverflow = () => {
    if (targetRef.current?.scrollHeight > targetRef.current?.clientHeight) {
      setShowingTooltip(false);
    } else {
      setShowingTooltip(true);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <div className={`text-tool-tip-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
      <Tooltip
        placement={placement}
        title={
          priceRow ? (
            <NumericFormat
              value={content}
              displayType={'text'}
              thousandSeparator
              allowNegative={false}
              prefix={'$'}
            />
          ) : phoneRow ? convertedPhone : (
            content || 'Not Set'
          )
        }
        disableHoverListener={dontShowTooltip}
        arrow={arrow}
        classes={{ tooltip: 'text-tool-tip-box' }}
      >
        <div
          id={'text-tooltip'}
          className={`main-tooltip-text ${classNameText ? classNameText : ''}`}
          style={{ WebkitLineClamp: lineClamp, ...textStyles }}
          ref={targetRef}
        >
          {priceRow ? (
            <NumericFormat
              value={Number(text).toFixed(2)}
              displayType={'text'}
              thousandSeparator
              allowNegative={false}
              prefix={allowPlusPref ? '+$' : allowMinusPref ? '-$' : '$'}
            />
          ) : phoneRow ? (
            <a
              className={`text-tooltip-phone ${classNameText ? classNameText : ''}`}
              style={{ WebkitLineClamp: lineClamp, ...textStyles }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={`tel:+1${text}`}
            >
              {convertedPhone || 'Not Set'}
            </a>
          ) : (
            text || 'Not Set'
          )}
        </div>
      </Tooltip>
    </div>
  );
};
