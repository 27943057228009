import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequestsOnSuccessActions, loadActions } from 'store';
import { FullTable, TableWrapper } from 'components';
import { ACTION_TYPE, LOAD_DEPARTMENT_ENUMS, onClaimHead, onClaimBody } from './constants';
import { LoadDetailSwitcher, RENDER_LOADS } from '../loads';
import { FindSuccessItem, usePrimaryModal } from '../../utils';

export const OnBond = ({}) => {
  const { loadsList } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const { openPrimary } = usePrimaryModal();
  const renderLoads = FindSuccessItem(RENDER_LOADS);

  const renderParams = () => {
    const filteredInfo = {
      ...info,
      department: LOAD_DEPARTMENT_ENUMS.ON_BOND,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  }

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);


  // useEffect(() => {
  //   const filteredInfo = {
  //     ...info,
  //     department: LOAD_DEPARTMENT_ENUMS.ON_BOND,
  //   };
  //   filteredInfo.skip = info?.skip ? info?.skip : 0;
  //   filteredInfo.limit = 100;
  //   delete filteredInfo.page;
  //   delete filteredInfo.activeTab;
  //   dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }));
  //   const interval = setInterval(() => {
  //     dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }, 'noLoad'));
  //   }, 30000);
  //   return () => clearInterval(interval);
  // }, [info]);


  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo }, loader));
  };


  useEffect(() => {
    if (renderLoads) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_LOADS))
      handleGet('noLoad');
    }
  }, [renderLoads]);


  const pushPageDetails = (id) => {
    openPrimary(<LoadDetailSwitcher loadId={id} />)
    // history.push({
    //   pathname: `/load/${id}`,
    // });
  };

  return (
    <div>
      <TableWrapper
        buttonsTab={true}
        link={true}
        tab={'none'}
        buttonsTabAddButton={false}
      >
        <FullTable
          head={onClaimHead}
          body={onClaimBody}
          loadingType={ACTION_TYPE}
          list={loadsList?.loads}
          listCount={loadsList?.count}
          handleClick={pushPageDetails}
          noText={'Bond'}
          dateFilter={true}
        />
      </TableWrapper>
    </div>
  );
};