import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CorrectionsModal, FullTable, SimpleModal, TableWrapper} from "components";
import {httpRequestsOnSuccessActions, loadActions, LoadServices} from "store";
import {billingsBody, billingsHead, ACTION_TYPE, BILLABLE} from "./constants";
import {SubmitBillModal} from "../../fragments/billing/billingDetails/core";
import { LoadDetailSwitcher, RENDER_LOADS } from '../loads';
import { FindSuccessItem, usePrimaryModal } from '../../utils';

export const Billing = ({}) => {
    const {loadsList} = useSelector((state) => ({
        loadsList: state.loads.loadsList,
    }));
    const history = useHistory()
    const info = history?.location?.state
    const dispatch = useDispatch()
    const [openSubmitBill, setOpenSubmitBill] = useState(false)
    const [openCorrection, setOpenCorrection] = useState(false)
    const [loadCorrect, setLoaderCorrect] = useState(false)
    const [correction, setCorrection] = useState('')
    const [data, setData] = useState()
    const { openPrimary } = usePrimaryModal();
    const renderLoads = FindSuccessItem(RENDER_LOADS);

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 100
        delete filteredInfo.page
        delete filteredInfo.activeTab
        return filteredInfo
    }

    useEffect(() => {
        handleGet('loading')
        const interval = setInterval(() => {
            handleGet('noLoad')
        }, 30000);
        return () => clearInterval(interval);
    }, [info]);

    useEffect(() => {
        if (renderLoads) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_LOADS))
            handleGet('noLoad');
        }
    }, [renderLoads]);

    const handleGet = (loading) => {
        const loader = loading === 'loading' ? 'loading' : 'noLoad'
        const filteredInfo = renderParams()
        dispatch(loadActions.getLoadsByStatus({...filteredInfo, department: BILLABLE}, loader))
    }

    const handleSubmit = (e, info) => {
        setData(e)
        setOpenSubmitBill(!openSubmitBill)
    }

    const handleOpenCorrection = () => {
        setOpenCorrection(!openCorrection)
    }

    const handleSend = async () => {
        setLoaderCorrect(true)
        try {
            await LoadServices.SendBillingCorrection(data?.id, correction)
            handleGet('noLoad')
            dispatch(httpRequestsOnSuccessActions.appendSuccess('CORRECTION_SENDED'))
            setLoaderCorrect(false)
            setOpenCorrection(false)
            setOpenSubmitBill(false)
        } catch (e) {
            setLoaderCorrect(false)
        }
    }

    const pushPageDetails = (id) => {
        openPrimary(<LoadDetailSwitcher loadId={id} />)

        // history.push({
        //     pathname: `/load/${id}`,
        //     state: {loadParams: {...info}, parent: '/billing', detail: 'billing',}
        // })
    }

    return (
        <div>
            <TableWrapper
                buttonsTab={true}
                link={true}
                tab={'none'}
                buttonsTabAddButton={false}
            >
                <FullTable
                    head={billingsHead}
                    body={billingsBody}
                    loadingType={ACTION_TYPE}
                    list={loadsList?.loads}
                    listCount={loadsList?.count}
                    handleClick={pushPageDetails}
                    noText={'Billing'}
                    handleClickButton={handleSubmit}
                    dateFilter={true}
                />
            </TableWrapper>

            <SimpleModal
                handleOpenClose={handleSubmit}
                openDefault={openSubmitBill}
                content={
                    <SubmitBillModal
                        handleGet={handleGet}
                        bill={true}
                        handleSend={handleOpenCorrection}
                        loadById={data}
                        handleClose={handleSubmit}
                    />
                }
            />

            <SimpleModal
                handleOpenClose={handleOpenCorrection}
                openDefault={openCorrection}
                content={
                    <CorrectionsModal
                        handleChangeText={(e) => setCorrection(e)}
                        loadingCor={loadCorrect}
                        handleSend={handleSend}
                        type={'notVerified'}
                        handleClose={handleOpenCorrection}
                    />
                }
            />
        </div>
    )
}