import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, TextField } from '@material-ui/core';
import { groupList, loadList, loadTabs } from '../../pages/loads/constants';
import { format, isAdmin, isFactored, RolePermission, SavePage, useGlobalStyles } from 'utils';
import { AddButton } from '../buttons';
import { tableStyle } from './styles';
import { filtersFragment } from '../filters/styles';

import { LoadStatus } from '../inputs/autocompleteSelectInput';
import { LOAD_REVISION, LOAD_VERIFICATION } from '../../pages/accounting/constants';
import { UserInputsDropdown } from '../dropdowns';
import { customerActions, mcActions, officeActions } from '../../store';
import { useDispatch, useSelector } from 'react-redux';


export const FilterForTable = ({
                                 startEnd,
                                 typeForFilter,
                                 addButton,
                                 createPermisson,
                                 handleOpenModal,
                                 type,
                                 dateFrom,
                                 searchBtnStyles,
                                 office
                               }) => {
  const { mcsList, officesList } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
    officesList: state.offices.officesList,
  }));
  const classes = tableStyle();
  const globalStyle = useGlobalStyles();
  const filterClasses = filtersFragment();
  const history = useHistory();
  const info = history?.location?.state;
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdmin) {
      dispatch(mcActions.getMcs());
      dispatch(officeActions.getOffices());
    }
  }, []);

  const handleChangeDate = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSearch = () => {
    const pushInfo = {
      ...info,
    };
    pushInfo.skip = 0;
    pushInfo.page = 1;
    if (startEnd) {
      inputs.startDate ? pushInfo.startDate = moment(inputs?.startDate).format('YYYY-MM-DDT00:00:01') : delete pushInfo.startDate;
      inputs.endDate ? pushInfo.endDate = moment(inputs.endDate).format('YYYY-MM-DDT23:59:59') : delete pushInfo.endDate;
    } else {
      inputs.startDate ? pushInfo.from = moment(inputs?.startDate).format('YYYY-MM-DDT00:00:01') : delete pushInfo.from;
      inputs.endDate ? pushInfo.to = moment(inputs.endDate).format('YYYY-MM-DDT23:59:59') : delete pushInfo.to;
    }
    SavePage(history, info, { ...pushInfo });
  };

  useEffect(() => {
    if (info) {
      const startEndDate = {};
      if (startEnd) {
        info.startDate ? startEndDate.startDate = moment(info.startDate).format('YYYY-MM-DDT00:00:01') : '';
        info.endDate ? startEndDate.endDate = moment(info.endDate).format('YYYY-MM-DDT23:59:59') : '';
      } else {
        info.from ? startEndDate.startDate = moment(info.from).format('YYYY-MM-DDT00:00:01') : '';
        info.to ? startEndDate.endDate = moment(info.to).format('YYYY-MM-DDT23:59:59') : '';
      }
      setInputs(startEndDate);
    }
  }, [info]);


  const handleSelect = (items, v) => {
    // handleSelectStatus && handleSelectStatus()
    const revision = items?.length && items.find((i) => i.id === LOAD_REVISION);
    const verification = items?.length && items.find((i) => i.id === LOAD_VERIFICATION);
    let statusList = [];

    const newObj = {
      ...info,
    };

    items?.length && items.forEach(function(status) {
      // if (status.id !== LOAD_REVISION && status.id !== LOAD_VERIFICATION) {
      statusList.push(status.id);
      // }
    });


    if (revision) {
      newObj.isRevision = true;
    } else {
      delete newObj.isRevision;
    }

    if (verification) {
      newObj.isVerification = true;
    } else {
      delete newObj.isVerification;
    }

    newObj.statuses = statusList;
    SavePage(history, info, { ...newObj });
  };

  const handleChange = (value, name) => {
    const params = {
      ...info,
    };

    if (value) {
      params[name] = value.id;
    } else {
      delete params[name];
    }
    SavePage(history, info, { ...params });
  };

  return (
    <div>
      <div className={globalStyle.filtersWrapper}>
        <div className={filterClasses.filterWrapper}>
          <TextField
            className={filterClasses.dateFiled}
            type={'date'}
            name={'startDate'}
            variant="outlined"
            InputProps={{
              inputProps: {
                max:
                  inputs?.endDate ?
                    moment(inputs?.endDate).format('YYYY-MM-DD')
                    :
                    '',
                // moment().format('YYYY-MM-DD')
              },
            }}
            value={inputs?.startDate ? moment(inputs?.startDate).format('YYYY-MM-DD') : ''}
            onChange={handleChangeDate}
          />
          <TextField
            className={filterClasses.dateFiled}
            type={'date'}
            name={'endDate'}
            variant="outlined"
            InputProps={{
              inputProps: {
                min: inputs?.startDate ?
                  format(new Date(inputs?.startDate))
                  : '',
              },
            }}
            defaultValue={dateFrom ? moment('').format('YYYY-MM-DD') : ''}
            value={dateFrom ?
              !inputs?.endDate ? moment().format('YYYY-MM-DD') : moment(inputs?.endDate).format('YYYY-MM-DD')
              :
              inputs?.endDate ?
                moment(inputs?.endDate).format('YYYY-MM-DD') : ''
            }

            onChange={handleChangeDate}
          />
          <Button
            onClick={handleSearch}
            className={filterClasses.search}
            style={{ ...searchBtnStyles }}
          >
            Search
          </Button>

          {typeForFilter &&
            <div className="align-center" style={{ gap: 16 }}>
              <LoadStatus
                handleChange={handleSelect}
                permissionsList={info?.tabType === loadTabs?.LOAD_GROUPS ? groupList : loadList}
                label={'Select Status'}
                info={info}
              />
              {isAdmin &&
                <UserInputsDropdown
                  styles={{ marginRight: 0 }}
                  dropdownOptions={mcsList?.mcs}
                  onPass={(option) => handleChange(option, 'mc')}
                  selected={info?.mc ? info?.mc : ''}
                  noText={'All Authority'}
                  firstText={'name'}
                />
              }
              {!isFactored && office &&
              <UserInputsDropdown
                dropdownOptions={officesList?.offices}
                onPass={(option) => handleChange(option, 'office')}
                selected={info?.office ? info?.office : ''}
                noText={'All Offices'}
                firstText={'name'}
              />
              }
            </div>
          }
        </div>

        <div className={classes.alignCenter}>
          {addButton &&
            RolePermission([createPermisson], type) &&
            <AddButton
              handleClick={handleOpenModal}
              text={addButton}
            />
          }
        </div>
      </div>
    </div>
  );
};