import React, { useEffect, useState } from 'react';
import { useGlobalStyles, usePrimaryModal } from 'utils';
import { FullTable, ItemPriceTitle } from 'components';
import { collectionsBody, collectionsHead } from './constants';
import { LoadDetailSwitcher } from '../../../../pages';

export const CollectionsTable = ({ info }) => {
  const globalStyle = useGlobalStyles();
  const [total, setTotal] = useState(0);
  const { openPrimary } = usePrimaryModal();

  useEffect(() => {
    if (info && info.debt) {
      let totalAmount = 0;
      info.debt.forEach((i) => totalAmount = totalAmount + i.amount);
      setTotal(totalAmount);
    }
  }, [info]);

  return (
    <div style={{ marginBottom: '16px' }} className={globalStyle.tableWrapperNoShadow}>
      <div style={{ marginBottom: '20px' }} className={globalStyle.spaceBetween}>
        <div />
        <div>
          <ItemPriceTitle name={'Total Charges:'} value={`$ ${total}`} />
        </div>
      </div>
      <div>
        <FullTable
          head={collectionsHead}
          body={collectionsBody}
          loadingType={'GET_PAYMENTS_CARRIER'}
          list={info?.debt}
          noText={'Collections'}
          handleClick={(item) =>
            openPrimary(<LoadDetailSwitcher loadId={item?.loadId} />)
          }
          clickType={'item'}
        />
      </div>
    </div>
  );
};