import { PriceRow, TextRow } from 'components';
import { Images, PermissionsList, RolePermission } from 'utils';

// export const fileTypeList = [
//   { name: 'PERSONAL', id: 'PERSONAL' },
//   { name: 'AVATAR', id: 'AVATAR' },
//   { name: 'DOCUMENT', id: 'DOCUMENT' },
// ];


const officeTypeEnums = {
  VOIDED_CHECK: 'VOIDED_CHECK',
  DRIVERS_LICENSE: 'DRIVERS_LICENSE',
  PAYMENT_AUTHORIZATION: 'PAYMENT_AUTHORIZATION',
  AGENTPANDP: 'AGENTPANDP',
  SERVICEAGREEMENT: 'SERVICEAGREEMENT',
  W9: 'W9',
  OTHER: 'OTHER',
};
export const officeDockTypes = [
  { name: 'Agent P&P', id: officeTypeEnums.AGENTPANDP },
  { name: 'Service Agreement', id: officeTypeEnums.SERVICEAGREEMENT },
  { name: 'W9', id: officeTypeEnums.W9 },
  { name: 'Other', id: officeTypeEnums.OTHER },
  { name: 'Voided Check', id: officeTypeEnums.VOIDED_CHECK },
  { name: 'Driver\'s License', id: officeTypeEnums.DRIVERS_LICENSE },
  { name: 'Payment Authorization', id: officeTypeEnums.PAYMENT_AUTHORIZATION },
];



export const directories = [
  {
    name: 'Polo TMS',
    directoryName: 'poloTMS',
  },

  {
    name: 'Polo4PL',
    directoryName: 'polo4PL',
  },

  {
    name: 'TransFG',
    directoryName: 'transFG',
  },
];

export const expensesHeader = [
  { name: '', title: 'Name', custom: false, },
  { name: '', title: 'Amount', custom: false },
  { name: '', title: 'Qty', custom: false },
  RolePermission([PermissionsList?.CREATE_UPDATE_OFFICE]) &&
  { name: '', title: 'Action', custom: false },
];


export const expensesBody = () => [
  { rowText: (item) => <TextRow name={item?.name} /> },
  { rowText: (item) => <PriceRow info={+item?.amount} /> },
  { rowText: (item) => <TextRow name={item?.qty} /> },
  RolePermission([PermissionsList?.CREATE_UPDATE_OFFICE]) &&
  {
    button: (item) =>
      <div className="align-center" style={{gap: 8}}>
        <button
          onClick={() => item('edit')}
          className="reset-btn">
          <img src={Images.edit} alt="edit" />
        </button>

        <button
          onClick={() => item('delete')}
          className="reset-btn">
          <img src={Images.clear} alt="edit" />
        </button>
      </div>, notClickable: true,
  },
];