import axios from 'axios';

export const  vendorProspectsService = {
  /** Create, Upload */
  getVendorProspectsService: (params) => axios.get('/vendor-prospects', {auth:true, params}),

  deleteVendorProspectsService: (id) => axios.delete(`/vendor-prospects/${id}`,{auth:true}),

  createVendorProspectsService: (info) => axios.post('/vendor-prospects', info,{auth:true}),

  editVendorProspectsService: (id, info) => axios.patch(`/vendor-prospects/${id}`, info,{auth:true}),
  /** End */
};