import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  EditSaveButtons,
  Line, MinLoader,
} from 'components';
import {
  AntSwitch, Colors,
  createInputsWrapper, FindLoad, FindSuccessItem,
  PermissionsList,
  RolePermission,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import RichTextEditorEdit from '../../../system/core/textEditor';
import { httpRequestsOnSuccessActions, mcActions } from 'store';


const ACTION_TYPE_EDIT = 'EDIT_TERMS_AND_CONDITIONS_MC';
const ACTION_TYPE_SET = 'SET_DEFAULT_TERMS_AND_CONDITIONS_MC';

export const TermsAndConditionsAuth = ({ info }) => {
  const [isDefault, setIsDefault] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [text, setText] = useState('');
  const success = FindSuccessItem(ACTION_TYPE_EDIT);
  const successSetDefault = FindSuccessItem(ACTION_TYPE_SET);
  const setDefaultLoad = FindLoad(ACTION_TYPE_SET);
  const dispatch = useDispatch();

  const globalWrappers = useGlobalStyles();
  const globalStyle = useGlobalText();


  useEffect(() => {
    if (info) {
      if (info?.termsConditions) {
        setText(info?.termsConditions);
      }

      if (info?.isDefaultTerms) {
        setIsDefault(info?.isDefaultTerms);
      }
    }
  }, [info]);

  useEffect(() => {
    if (success) {
      setDisabled(true);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE_EDIT));
    }

    if (successSetDefault) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE_SET));
    }
  }, [success, successSetDefault]);


  const handleCreate = () => {
    const params = {
      terms: text,
    };

    dispatch(mcActions.editTermsAndConditionsMc(info?.id, params));
  };

  const handleSetDefault = () => {
    setIsDefault(prev => !prev);

    const params = {
      isDefault: !isDefault,
    };

    dispatch(mcActions.setDefaultTermsAndConditionsMc(info?.id, params));
  };

  return (
    <div className='authorities-terms-and-conditions-wrapper'>

      <div className={globalWrappers.spaceBetween}>
        <div className={globalWrappers.centerItem}>
          {!info && <p className={globalStyle.title}>Terms And Conditions</p>}
        </div>

        {info && RolePermission([PermissionsList.CREATE_UPDATE_MC?.code]) &&
          <div className='authorities-terms-and-conditions-actions-wrapper'>
            <div className='authorities-terms-and-conditions-switcher-wrapper'>
              <p className='authorities-terms-and-conditions-switcher-label'>
                Default
              </p>

              {setDefaultLoad?.length ?
                <MinLoader
                  margin={'0'}
                  color={Colors.ThemeBlue}
                  position={'static'}
                />
                :
                <AntSwitch
                  name={'isDefault'}
                  onClick={handleSetDefault}
                  checked={isDefault}
                />
              }
            </div>

            <EditSaveButtons
              type={ACTION_TYPE_EDIT}
              handleChancel={() => setDisabled(true)}
              handleSetEdit={() => setDisabled(false)}
              handleSaveInfo={handleCreate}
            />
          </div>
        }
      </div>

      <div className={globalWrappers.flexAble}>
        {info && <Line height={'281px'} />}

        <div className='authorities-rich-text-editor'>
          <RichTextEditorEdit
            success={success}
            disabled={disabled}
            text={text}
            onChange={(ev) => setText(ev)}
            placeholder={'Enter the text here...'}
          />
        </div>
      </div>
    </div>
  );
};

