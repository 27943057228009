import {
  CREATE_VENDOR_PROSPECTS,
  DELETE_VENDOR_PROSPECTS,
  EDIT_VENDOR_PROSPECTS,
  GET_VENDOR_PROSPECTS,
} from './vendorProspects.types';

/**Get, Edit, Delete  Vendor Prospects */

export const getVendorProspects = (params, load) => {
  return {
    type: GET_VENDOR_PROSPECTS,
    payload: { params, load },
  };
};

export const deleteVendorProspects = (id) => {
  return {
    type: DELETE_VENDOR_PROSPECTS,
    payload: { id },
  };
};

export const createVendorProspects = (body) => {
  return {
    type: CREATE_VENDOR_PROSPECTS,
    payload: { body },
  };
};

export const editVendorProspects = (id, body) => {
  return {
    type: EDIT_VENDOR_PROSPECTS,
    payload: { id, body },
  };
};
