import React from "react";
import { wrapperStyle } from "./styles";
import { useGlobalStyles } from "utils";
import { TableHeadComponent } from "components";
import { Paper, Table, TableCell, TableContainer } from "@material-ui/core";

export const CreateWrapper =({head, body,  noHead, type, tableVerification, wrapperStyles }) => {
  const globalStyle =useGlobalStyles()
  const classes = wrapperStyle()

  return(
      <div style={{...wrapperStyles}} className={globalStyle.tableWrapperSmall}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            {noHead !== true &&
            <TableHeadComponent tableVerification={tableVerification}>
              <TableCell>
                {head}
              </TableCell>
            </TableHeadComponent>
            }
            <div className={classes.createOfficesBody}>
              {body}
            </div>
          </Table>
        </TableContainer>
    </div>
  )
}