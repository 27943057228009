import {HistoryCard} from "components/card";
import React, {useEffect, useState} from "react";
import {FindLoad} from "utils";
import {Button, TextField} from "@material-ui/core";
import {Loader, MinLoader} from "../loader";
import {historyActions} from "store";
import {useDispatch, useSelector} from "react-redux";
import {history} from './styles'
import {NoYet} from "../messages";

export const History = ({resourceId, onModel}) => {
    const classes = history()
    const dispatch = useDispatch()
    const loader = FindLoad('GET_HISTORY')
    const searchLoader = FindLoad('SEARCH_HISTORY')
    const [date, setDate] = useState('')
    console.log(resourceId,'resourceId');
    useEffect(() => {
        dispatch(historyActions.getHistory(onModel, {onResource: resourceId}));
    }, [])

    const {historyList} = useSelector((state) => ({
        historyList: state.history.historyList,
    }));

    const searchLoads = () => {
        dispatch(historyActions.getHistory(onModel, {onResource: resourceId, date: date}, 'search'));
    }

    if (loader?.length) return <Loader/>

    return (
        <div>
            <div className={classes.globalHistory}>
                <div className={classes.filterButtons}>
                    <TextField
                        className={classes.dateFiled}
                        type={'date'}
                        name={'endDate'}
                        variant="outlined"
                        onChange={(e) => setDate(e.target.value)}
                    />

                    <Button
                        onClick={searchLoads}
                        className={classes.search}>
                        {searchLoader.length ?
                            <MinLoader color={'white'} margin={'0px'}/>
                            :
                            'Search'
                        }
                    </Button>

                </div>
                {historyList?.history?.length ? historyList.history.map((item, index) => {
                        return (
                            <div key={index}>
                                <HistoryCard data={item}/>
                            </div>
                        )
                    }) :
                    <NoYet position={'center'} text={'No History Yet'} styles={{margin: '50px auto'}}/>
                }
            </div>
        </div>
    )
}