import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableWrapper, FullTable, SearchInput, AddButton, Loader, NoYet } from 'components';
import { authActions, directoryActions } from 'store';
import { FindLoad, isAdmin, PermissionsList, RolePermission, SaveParams, useModal } from 'utils';
import { ACTION_TYPE, directoryBody, directoryHead, directoryTypes, directoryTypesEnum } from './constants';
import { CustomDeleteModal } from '../../fragments';
import { CreateDirectory } from './createDirectory';

const PROFILE_ACTION_TYPE = 'GET_MY_PROFILE';
export const Directory = () => {
  const { directoryList, myProfile } = useSelector((state) => ({
    directoryList: state.directory.directoryList,
    myProfile: state.auth.myProfile,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const { open } = useModal();
  const loader = FindLoad(PROFILE_ACTION_TYPE);
  const hasAccess = isAdmin ? true : myProfile?.directoryAccess && Object.values(myProfile?.directoryAccess).some(value => value === true);

  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;

    info?.companyType ?
      filteredInfo.companyType = info?.companyType :
      isAdmin ? filteredInfo.companyType = directoryTypesEnum.POLO_TMS :
        directoryTypes(myProfile?.directoryAccess)?.[0]?.id ? filteredInfo.companyType = directoryTypes(myProfile?.directoryAccess)?.[0]?.id :
          delete filteredInfo?.companyType;


    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  };

  useEffect(() => {
    getDirectory();
  }, [info]);

  useEffect(() => {
    dispatch(authActions.getMyProfile());
  }, []);

  const getDirectory = (loading) => {
    dispatch(directoryActions.getDirectory(renderParams(), loading));
  };

  const handleClick = (item, name) => {
    if (name === 'edit') {
      open(<CreateDirectory
        info={item}
        afterSuccess={() => getDirectory('noLoad')}
      />);
    }
    if (name === 'delete') {
      open(<CustomDeleteModal
        afterSuccess={() => getDirectory('noLoad')}
        actionType={'DELETE_DIRECTORY'}
        text="Delete Directory?"
        info={'Directory'}
        handleDel={() => dispatch(directoryActions.deleteDirectory(item?.id))}
      />);
    }
  };

  const selectType = (item, name) => {
    const params = {
      ...info,
    };
    params[name] = item;
    SaveParams(history, params);
  };


  const renderActive = (item) => {
    if (item?.id === info?.companyType || (isAdmin ? item.id === directoryTypesEnum.POLO_TMS && !info?.companyType : directoryTypes(myProfile?.directoryAccess)?.[0]?.id === item?.id && !info?.companyType)) {
      return 'selected';
    } else {
      return 'not-selected';
    }
  };

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div className="directory-page">

      <div className="directory-header-filters">
        {RolePermission([PermissionsList.MANAGE_DIRECTORY]) &&
          <div style={{ marginLeft: 'auto' }}>
            <AddButton
              handleClick={() => open(<CreateDirectory afterSuccess={() => getDirectory('noLoad')} />)}
              text="Add Directory"
            />
          </div>
        }
      </div>

      {(isAdmin || directoryTypes(myProfile?.directoryAccess)?.length > 0) &&
        <div className="directory-types-wrapper">
          <div className="directory-types-box">
            {directoryTypes(myProfile?.directoryAccess).map((item, index) => (
              <button
                onClick={() => selectType(item?.id, 'companyType')}
                className={`directory-type-btn ${renderActive(item)}`}
                key={index}
              >
                {item?.name}
              </button>
            ))}
          </div>
        </div>
      }

      {hasAccess ?
        <FullTable
          head={directoryHead}
          body={directoryBody(handleClick)}
          loadingType={ACTION_TYPE}
          list={directoryList?.directories}
          listCount={directoryList?.count}
          noText={'Directories'}
        />
        :
        <NoYet position={'center'} text={'You haven\'t Directory Access'} />
      }
    </div>
  );
};