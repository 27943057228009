
/**Create Mc */
export const CREATE_MC = 'CREATE_MC';

/**Edit Mc */
export const EDIT_MC = 'EDIT_MC'

/**Get Mcs */
export const GET_MCS = 'GET_MCS';
export const GET_MCS_SUCCESS = 'GET_MCS_SUCCESS';
export const GET_ASSIGNED_MCS = 'GET_ASSIGNED_MCS';
export const GET_ASSIGNED_MCS_SUCCESS = 'GET_ASSIGNED_MCS_SUCCESS';
export const GET_OFFICE_ASSIGNED_MCS = 'GET_OFFICE_ASSIGNED_MCS';
export const GET_OFFICE_ASSIGNED_MCS_SUCCESS = 'GET_OFFICE_ASSIGNED_MCS_SUCCESS';
export const GET_BRANCH_ASSIGNED_MCS_SUCCESS = 'GET_BRANCH_ASSIGNED_MCS_SUCCESS';
export const GET_AGENT_ASSIGNED_MCS_SUCCESS = 'GET_AGENT_ASSIGNED_MCS_SUCCESS';
export const GET_AGENT_ASSIGNED_MCS = 'GET_AGENT_ASSIGNED_MCS';

/**Get Mc by id */
export const GET_MC_BY_ID = 'GET_MC_BY_ID';
export const GET_MC_BY_ID_SUCCESS = 'GET_MC_BY_ID_SUCCESS';

/**Activate Inactivate Mc */
export const ACTIVATE_MC = 'ACTIVATE_MC';
export const INACTIVATE_MC = 'INACTIVATE_MC';

/**Assign Mc */
export const ASSIGNED_MC = 'ASSIGNED_MC';
export const GET_ASSIGNED_MC = 'GET_ASSIGNED_MC';
export const GET_ASSIGNED_MC_SUCCESS = 'GET_ASSIGNED_MC_SUCCESS';
export const DELETE_ASSIGNED_MC = 'DELETE_ASSIGNED_MC';
export const BRANCH_ASSIGNED_MC = 'BRANCH_ASSIGNED_MC';
export const BRANCH_ASSIGNED_MC_SUCCESS = 'BRANCH_ASSIGNED_MC_SUCCESS';

export const EDIT_TERMS_AND_CONDITIONS_MC = 'EDIT_TERMS_AND_CONDITIONS_MC';
export const SET_DEFAULT_TERMS_AND_CONDITIONS_MC = 'SET_DEFAULT_TERMS_AND_CONDITIONS_MC';