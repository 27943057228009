import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { customerActions, customerPaymentActions } from 'store';
import { CsvFile, FullTable, SearchInput, useWidth } from 'components';
import { AddCustomerPayment } from 'fragments';
import {
  customerPmtBody,
  customerPmtHead,
  ACTION_TYPE,
  customerPaymentCsv,
} from './constants';
import { PermissionsList, RolePermission, useModal } from 'utils';

export const CustomerPayment = ({}) => {
  const { customerPayments } = useSelector((state) => ({
    customerPayments: state.customerPayments.customerPayments,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const width = useWidth();
  const { open } = useModal();

  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    if (!info?.loadDisplayId) {
      delete filteredInfo.loadDisplayId;
    }
    return filteredInfo;
  }

  useEffect(() => {
    dispatch(customerActions.getCustomers({ status: 'ACTIVE' }));
  }, []);

  useEffect(() => {
    getCustomerPayment()
  }, [info]);

  const getCustomerPayment = () => {
    dispatch(customerPaymentActions.getCustomerPayment( renderParams()));
  }

  const pushPageDetails = (id) => {
    history.push({
      pathname: `/customerPayment/${id}`,
      state: { customerPaymentParams: { ...info } },
    });
  };

  return (
    <div>
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.EXPORT_CUSTOMER_PMT_CSV?.code]) &&
      <div className='flex-end' style={{marginBottom:'16px' }}>
         <CsvFile params={customerPaymentCsv}/>
      </div>
      }
      <div style={{ position: 'absolute', right: width > 1919 ? '295px' : '280px', }}>
        <SearchInput name='loadDisplayId' placeholder='Search for Load ID'/>
      </div>
      <FullTable
        head={customerPmtHead}
        body={customerPmtBody}
        loadingType={ACTION_TYPE}
        list={customerPayments?.pmts}
        listCount={customerPayments?.count}
        handleClick={pushPageDetails}
        noText={'Carrier Payments'}
        dateFilter={true}
        addButton={'Add Customer Payment'}
        startEnd={true}
        handleOpenModal={() => open(<AddCustomerPayment afterSuccess={getCustomerPayment} />)}
        createPermisson={PermissionsList.CREATE_CUSTOMER_PAYMENT?.code}
      />
    </div>
  );
};