import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddressInput, CheckboxesTags,
  CreateChancel,
  FormatAddress,
  MaskInput,
  MultipleSelect,
  SelectInput,
  ValidationInput,
} from 'components';
import {
  EmailValidator,
  ErrorText, FindErrorItem,
  FindLoad,
  FindSuccessItem,
  useGlobalStyles,
  useGlobalTextStyles,
  useModal,
  Images,
} from 'utils';
import {
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
  vendorProspectsActions,
} from 'store';
import { v4 as uuid } from 'uuid';
import { vendorTypes } from '../../vendors/fragments/constants';
import { vendorEnums } from '../../vendors';
import { loadTypeList } from './constants';

export const CreateVendorProspect = ({ info, afterSuccess }) => {
  const ACTION_TYPE = info?.id ? 'EDIT_VENDOR_PROSPECTS' : 'CREATE_VENDOR_PROSPECTS';
  const [inputs, setInputs] = useState({
    modes: [],
  });
  const [contactsInputs, setContactsInputs] = useState([{
    id: uuid(),
    email: '',
    name: '',
    phone: '',
  }]);
  const [contactsErrors, setContactsErrors] = useState([]);

  const [error, setError] = useState('');
  const { officesList } = useSelector((state) => ({
    officesList: state.offices.officesList,
  }));
  const dispatch = useDispatch();
  const { close } = useModal();
  const success = FindSuccessItem(ACTION_TYPE);
  const load = FindLoad(ACTION_TYPE);
  const loadOffices = FindLoad('GET_OFFICES');
  const backError = FindErrorItem(ACTION_TYPE);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalTextStyles();

  useEffect(() => {
    if (info) {
      const newData = { ...info };
      if (info?.modes) newData.modes = loadTypeList.filter((type) => info?.modes?.includes(type?.id));

      if (info?.contacts?.length > 0) {

        setContactsInputs(info?.contacts.map((contact) => {
          return ({
            id: uuid(),
            name: contact?.name,
            email: contact?.email,
            phone: contact?.phone,
          });
        }));

        delete newData.contacts;
      }

      setInputs({ ...newData });
    }
  }, [info]);

  useEffect(() => {
    if (success) {
      afterSuccess();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      close();
    }
  }, [success]);

  useEffect(() => {
    return () => {
      setContactsErrors([]);
      setError('');
      dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    };
  }, []);

  const handleAddContactsInput = () => {
    const noAllowedInfoData = contactsInputs.find((contact) => !contact?.name);


    if (!noAllowedInfoData) {
      setContactsInputs(prev => ([
        ...prev,
        {
          id: uuid(),
          email: '',
          name: '',
          phone: '',
        },
      ]));
    } else {
      handleContactsInputError(noAllowedInfoData, 'name', true);
    }
  };


  const handleContactsInputChange = (item, path, value) => {
    setContactsInputs(prev => prev.map((input) => input?.id === item?.id ? {
      ...input,
      [path]: value,
    } : input));

    setContactsErrors(prev => prev.filter((error) => error?.id !== item?.id));
  };

  const handleContactsInputError = (item, path, value) => {
    const error = contactsErrors?.find((error) => error?.id === item?.id);

    if (value) {
      setContactsErrors(prev => {
        if (error) {
          return prev.map((err) => ({
            ...err,
            [path]: value,
          }));
        } else {
          return [
            ...prev,
            {
              id: item?.id,
              [path]: value,
            },
          ];
        }
      });
    }
  };

  const handleRemoveEmailInput = (id) => {
    setContactsInputs(prev => prev.filter((input) => input?.id !== id));
    setContactsErrors(prev => prev.filter((error) => error?.id !== id));
  };


  const handleChange = (e, path) => {
    if (path) {
      if (path === 'address') {
        setInputs(prev => ({
          ...prev,
          [path]: e?.netValue,
        }));
      } else {
        setInputs(prev => ({
          ...prev,
          [path]: e,
        }));
      }
      (error === path) && setError('');
    } else {
      setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      (error === e.target.name) && setError('');
    }
  };


  const handleCreate = () => {
    const contacts = contactsInputs.filter((contact) => contact?.name);

    contactsInputs.forEach((contact) => {
      if (!contact?.name) {
        handleContactsInputError(contact, 'name', true);
      }
    });

    const sendData = {
      office: inputs?.office,
      vendorType: inputs?.vendorType,
      name: inputs?.name,
    };

    inputs?.modes ? sendData.modes = inputs?.modes?.map((mode) => mode?.id) : delete sendData?.modes;
    inputs?.address ? sendData.address = inputs?.address : delete sendData?.address;
    inputs?.serviceAreas ? sendData.serviceAreas = inputs?.serviceAreas : delete sendData?.serviceAreas;
    inputs?.comments ? sendData.comments = inputs?.comments : delete sendData?.comments;

    if (inputs?.office && inputs?.name && inputs?.vendorType && contacts?.length && !contactsErrors?.length) {

      if (contacts.length > 0) {
        sendData.contacts = contacts.map((contact) => ({
          name: contact?.name,
          email: contact?.email,
          phone: contact?.phone && parseInt(contact?.phone?.replace(/[^0-9]/g, '')) + '',
        }));
      }

      if (!info) {
        dispatch(vendorProspectsActions.createVendorProspects(sendData, {}));
      } else {
        dispatch(vendorProspectsActions.editVendorProspects(info?.id, sendData));
      }
    } else {
      setError(
        !inputs?.office ? 'office' :
          !inputs?.name ? 'name'
            : !inputs.vendorType ? 'vendorType' : '',
      );
    }
  };


  return (
    <div className='create-vendor-prospect-modal-wrapper'>
      <p className={globalText.modalTitleWithMargin}>
        {info ? 'Edit' : 'Add'} Vendor
      </p>
      <div className='create-vendor-prospect-modal-inputs-block'>
        <SelectInput
          className={inputs.office && globalStyles.inputTextFieldBlue}
          name={'office'}
          label={'Choose Office*'}
          handleSelect={handleChange}
          value={inputs.office}
          list={officesList?.offices}
          typeError={error === 'office' && ErrorText.field}
          type={'id'}
          showErrorAlways
          loader={loadOffices}
        />

        <ValidationInput
          className={inputs?.name && globalStyles.inputTextFieldBlue}
          onChange={handleChange}
          value={inputs.name}
          typeError={error === 'name' && ErrorText.field}
          label={'Vendor Name*'}
          type={'text'}
          variant={'outlined'}
          name={'name'}
        />

        <SelectInput
          className={inputs.vendorType && globalStyles.inputTextFieldBlue}
          name={'vendorType'}
          label={'Vendor Type*'}
          handleSelect={handleChange}
          value={inputs.vendorType}
          list={vendorTypes.filter((type) => type?.id !== vendorEnums.FACTORED)}
          typeError={error === 'vendorType' && ErrorText.field}
          type={'id'}
          showErrorAlways
        />

        <div style={{ marginBottom: '25px' }}>
          <CheckboxesTags
            value={inputs.modes}
            handleChange={(item) => handleChange(item, 'modes')}
            permissionsList={loadTypeList}
            label={'Modes'}
            renderName={(item) => item?.name}
            noChip
            allowBorderBlue
          />
        </div>

        <FormatAddress
          value={inputs?.address}
          noSuite
          oneInput
          noBlue={!inputs?.address}
          setCurrentAddress={(value) => handleChange(value, 'address')}
          errorBoolean={error === 'address'}
          placeholder='Address'
          netValue
        />

        <ValidationInput
          className={inputs.serviceAreas && globalStyles.inputTextFieldBlue}
          onChange={handleChange}
          value={inputs.serviceAreas}
          typeError={error === 'serviceAreas' && ErrorText.field}
          label={'Service Areas'}
          type={'text'}
          variant={'outlined'}
          name={'serviceAreas'}
        />

        <ValidationInput
          style={'vendor-prospect-modal-textarea'}
          className={inputs.comments && globalStyles.inputTextFieldBlue}
          onChange={handleChange}
          value={inputs.comments}
          typeError={error === 'comments' && ErrorText.field}
          label={'Comments here...'}
          type={'text'}
          variant={'outlined'}
          name={'comments'}
          multiline
        />


        <div className='create-vendor-prospect-contact-information-wrapper'>
          <div className='prospect-contact-information-header'>
            <p className='prospect-contact-information-header-text'>
              Contact information
            </p>

            <button
              className='create-vendor-prospect-add_more-button'
              onClick={handleAddContactsInput}
              disabled={contactsErrors?.length}
            >
              <img src={Images.pluseIcon} alt='icon' />
              Add More
            </button>
          </div>

          <div className='vendor-prospect-contact-information-body'>
            {contactsInputs.map((input, index) => (
              <div
                key={index}
                className={`vendor-prospect-contact-information-block ${index !== 0 && 'vendor-prospect-contact-information-secondary-block'}`}>
                <div className='vendor-prospect-contact-information-inputs'>
                  <ValidationInput
                    className={input.name && globalStyles.inputTextFieldBlue}
                    onChange={(e) => handleContactsInputChange(input, 'name', e.target.value)}
                    value={input.name}
                    typeError={contactsErrors.find((error) => error?.id === input?.id)?.name && ErrorText.field}
                    label={'Contact Name*'}
                    type={'text'}
                    variant={'outlined'}
                    name={'name'}
                  />

                  <ValidationInput
                    className={input.email && globalStyles.inputTextFieldBlue}
                    onChange={(e) => handleContactsInputChange(input, 'email', e.target.value)}
                    value={input?.email}
                    name={'email'}
                    label={'Email'}
                    type={'email'}
                    validator={EmailValidator}
                    typeError={contactsErrors.find((error) => error?.id === input?.id)?.email && 'Not valid email'}
                    sendBoolean={(bool) => handleContactsInputError(input, 'email', bool)}
                    variant={'outlined'}
                  />

                  <MaskInput
                    name='phone'
                    label='Phone Number'
                    value={input.phone}
                    handleChange={(e) => handleContactsInputChange(input, 'phone', e.target.value)}
                    info={info}
                    error={
                      contactsErrors?.phone === 'phone' ? ErrorText.field :
                        errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
                  />
                </div>

                {index !== 0 &&
                  <button
                    className='create-vendor-prospect-remove-button'
                    onClick={() => handleRemoveEmailInput(input?.id)}
                  >
                    <img src={Images.trash} alt='icon' />
                  </button>
                }
              </div>
            ))}
          </div>

        </div>
      </div>

      <CreateChancel
        width={'100%'}
        loader={!!load?.length}
        classes={globalStyles.buttonsStyle}
        create={info ? 'Save' : 'Add'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={() => close()}
      />
    </div>
  );
};

