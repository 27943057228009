import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, useGlobalStyles, useGlobalTextStyles, useModal } from 'utils';
import { httpRequestsOnSuccessActions, officeActions } from 'store';
import { officesInfoFragments } from './styles';


export const AddExpensesModal = ({ info }) => {
  const ACTION_TYPE = info?.id ? 'EDIT_OFFICE_EXPENSES' : 'ADD_OFFICE_EXPENSES';
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { close } = useModal();
  const success = FindSuccessItem(ACTION_TYPE);
  const load = FindLoad(ACTION_TYPE);
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalTextStyles();
  const officeStyles = officesInfoFragments();

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      close();
    }
  }, [success]);

  useEffect(() => {
    if (info?.id) {
      setInputs({
        name: info?.name,
        amount: info?.amount,
        qty: info?.qty,
      });
    }
  }, [info]);

  const handleChange = (e) => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
  };

  const handleCreate = () => {
    const senderInfo = {
      name: inputs.name,
      amount: +inputs.amount,
      qty: +inputs.qty,
    };
    if (inputs.name && inputs?.amount && inputs.qty) {
      if (info) {
        dispatch(officeActions.editOfficeExpense(params?.id, info?.id, senderInfo));
      } else {
        dispatch(officeActions.addOfficeExpense(params?.id, senderInfo));
      }

    } else {
      setError(
        !inputs.name ? 'name' :
          !inputs.amount ? 'amount' :
            !inputs.phone ? 'qty' :
              '',
      );
    }
  };

  return (
    <div className={officeStyles.expenseModalWrapper}>
      <p className={globalText.modalTitleWithMargin}>
        {info ? 'Edit' : 'Add'} Expense
      </p>

      <ValidationInput
        className={inputs.name && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.name}
        typeError={error === 'name' && ErrorText.field}
        label={'Name*'}
        type={'text'}
        variant={'outlined'}
        name={'name'}
      />

      <ValidationInput
        className={inputs.amount && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.amount}
        typeError={error === 'amount' && ErrorText.field}
        label={'Amount*'}
        type={'number'}
        variant={'outlined'}
        name={'amount'}
        min={1}
        dontMinus
        handleDown={(e) => {
          if (!inputs.amount && e?.key === '0') {
            e.preventDefault();
          }
        }}
      />

      <ValidationInput
        className={inputs.qty && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.qty}
        typeError={error === 'qty' && ErrorText.field}
        label={'Qty*'}
        type={'number'}
        variant={'outlined'}
        name={'qty'}
        min={0}
        dontMinus
      />

      <CreateChancel
        width={'100%'}
        loader={!!load?.length}
        classes={globalStyles.buttonsStyle}
        create={info ? 'Change' : 'Add'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={() => close()}
      />
    </div>
  );
};

