import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  Consign,
  Rejected,
  ConsignmentRequest,
 MyProfilePage, CreditPageWrapper, Loads
} from 'pages';

export const FactoredRouter = ({}) => {
  return (
    <Switch>
      <Route path="/my-profile" component={MyProfilePage} />
      <Route path="/loads" component={Loads} />

      {/*<Route path="/create-load" component={CreateLoadSwitcher} />*/}

      {/*<Route path="/create-load/:group" component={CreateLoadSwitcher} />*/}
      {/*<Route path="/create-group" component={CreateLoadGroup} />*/}
      {/*<Route path="/load-group/:id" component={GroupedLoadDetails} />*/}
      {/*<Route path="/load/:id" component={LoadDetailSwitcher} />*/}


      {/*<Route path="/factored-loads" component={FactoredLoads} />*/}
      {/*<Route path="/create-factored-load" component={CreateFactoredLoadTable} />*/}
      {/*<Route path="/load/:id" component={LoadDetailSwitcher} />*/}
      {/*Credit */}

      <Route path="/credit" component={CreditPageWrapper} />
      <Route path="/rejected" component={Rejected} />
      <Route path="/consignmentRequest" component={ConsignmentRequest} />
      <Route path="/consign" component={Consign} />
      <Redirect to={'/'} />
    </Switch>
  );
};
