import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Images, SavePage } from 'utils';
import { inputsStyle } from './styles';

export const SearchInput = ({ name, placeholder }) => {
  const classes = inputsStyle();
  const [value, setValue] = useState('');
  const ref = useRef();
  const history = useHistory();
  const info = history?.location?.state;

  const handleSend = () => {
    const params = {};
    value ? params[name] = value : delete params[name];
    SavePage(history, info, params);
  };

  return (
    <div>
      <div style={value ? { borderColor: '#438AFE' } : {}} className={classes.searchInputWrapper}>
        <div>
          <input
            ref={ref}
            type='search'
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
          />
        </div>
        <button onClick={handleSend} style={value ? { borderColor: '#438AFE' } : {}}>
          <img src={Images.searchIcon} alt={'icon'} />
        </button>
      </div>
    </div>
  );
};