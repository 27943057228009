import React from "react";
import {Items, handleGetDate, renderDates} from "components";

export const PmtVerificationRows = ({loadById, classes}) => {
    return (
        <>
            <div className={classes.itemsSection}>
                <Items title='Created Date:' text={renderDates(loadById?.payable?.submittedAt)} width={95}/>
                <Items title='Vendor:' text={loadById?.carrier?.name} width={50}/>
                <Items title='Payee:' text={loadById?.payable?.payeeName} width={45}/>
                <Items styles={{marginRight: 0}} title='Payment Method:' text={loadById?.payable?.pmtMethod} width={125}/>
            </div>
            <div className={classes.itemsSection}>
                <Items title='Payment Term:' text={loadById?.payable?.pmtTerm}  width={105}/>
                <Items title='Amount:' text={loadById?.carrierRate ? loadById?.carrierRate : 0} width={60} price={true}/>
                <Items title='Collected Debt:' text={loadById?.debtCollected} width={105} price={true}/>
                <Items styles={{marginRight: 0}} title='Final Amount:' text={loadById?.carrierRate - loadById?.carrierCharges + loadById?.payable?.corporateCharges}  width={97} price={true}/>
            </div>
            <div className={classes.itemsSection}>
                <Items title='Aging:' text={handleGetDate(loadById?.payable?.verifiedDate)} width={42}/>
                <Items title='Due Date:' text={renderDates(loadById?.payable?.dueDate)} width={70}/>
                <Items title='Paid Date:' text={renderDates(loadById?.payable?.lastPaidDate)} width={72}/>
                <Items styles={{marginRight: 0}} title='Paid Amount:' text={loadById?.carrierPaid } width={95} price={true}/>
            </div>
        </>
    )
}