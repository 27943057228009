import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MinLoader } from 'components';
import { FindLoad, FindSuccess, useModal } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';

export const CustomDeleteModal = ({
                                    text,
                                    info,
                                    handleDel,
                                    actionType,
                                    type,
                                    modalText,
                                    cancelButton,
                                    closeButton,
                                    afterSuccess,
                                  }) => {
  const loader = FindLoad(actionType);
  const success = FindSuccess(actionType);
  const dispatch = useDispatch();
  const { close } = useModal();

  useEffect(() => {
    if (success?.length && afterSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      close();
      afterSuccess();
    }
  }, [success]);

  return (
    <div className="delete-modal-wrapper">
      <p className="delete-modal-title">{text}</p>
      <p className="delete-modal-text">
        {modalText ? modalText
          :
          type === 'void' ?
            'Are you sure?  You wont Void transaction ?'
            :
            <span>Are you sure?  You wont delete  <span className="delete-info-text">{info}</span></span>

        }
      </p>
      <div className="flex">
        <button className="close-or-delete-btn" onClick={handleDel}>
          {loader?.length ?
            <MinLoader margin={'0'} color={'white'} position={'relative'} />
            :
            cancelButton ? cancelButton :
              type === 'void' ? 'Void' :
                type === 'close' ? 'Close' :
                  'Delete'
          }

        </button>
        <button className="modal-close-btn" onClick={close}>
          {closeButton ? closeButton : 'Cancel'}
        </button>
      </div>
    </div>

  );
};