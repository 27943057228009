import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FullTable, TableWrapper } from 'components';
import { officeActions, vendorProspectsActions } from 'store';
import { ACTION_TYPE_DELETE, ACTION_TYPE_GET, vendorProspectsBody, vendorProspectsHeader } from './fragments/constants';
import { PermissionsList, RolePermission, useModal } from 'utils';
import { CreateVendorProspect } from './fragments';
import { CustomDeleteModal } from '../../fragments';


export const VendorProspects = () => {
  const { vendorProspects } = useSelector((state) => ({
    vendorProspects: state.vendorProspects.vendorProspects,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const { open } = useModal();
  const info = history?.location?.state;

  useEffect(() => {
    if(RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_OFFICE?.code, PermissionsList.CREATE_UPDATE_OFFICE?.code], 'officeManager')) {
      dispatch(officeActions.getOffices({ status: 'ACTIVE' }));
    }
  }, []);



  const renderParams = () => {
    const params = {
      ...info,
    };
    params.skip = info?.skip ? info?.skip : 0;
    params.limit = 100;
    delete params.page;
    delete params.activeTab;
    return params;
  };

  const getVendorProspects = (loading) => {
    dispatch(vendorProspectsActions.getVendorProspects(renderParams(), loading));
  };

  useEffect(() => {
    getVendorProspects();
  }, [info]);

  const handleCLick = (item, name) => {
    if (name === 'edit') {
      open(<CreateVendorProspect
        info={item}
        afterSuccess={() => {
          getVendorProspects('noLoad');
        }}
      />);
    }
    if (name === 'delete') {
      open(
        <CustomDeleteModal
          actionType={ACTION_TYPE_DELETE}
          text="Delete Vendor?"
          modalText="Deleting this vendor will remove all associated information.This action cannot be undone."
          handleDel={() => {
            dispatch(vendorProspectsActions.deleteVendorProspects(item?.id));
          }}
          afterSuccess={() => {
            getVendorProspects('noLoad');
            close();
          }}
        />,
      );
    }
  };

  return (
    <div className="vendor-prospects-page">
      <TableWrapper
        addButton="Add Vendor"
        buttonsTabAddButton={true}
        buttonsTab={true}
        tab={'none'}
        handleClick={() => open(<CreateVendorProspect afterSuccess={() => getVendorProspects('noLoad')} />)}
        createPermisson={PermissionsList.MANAGE_VENDOR_PROSPECTS}
        type="officeOrBranchManager"
      >
        <FullTable
          head={vendorProspectsHeader}
          body={vendorProspectsBody}
          loadingType={ACTION_TYPE_GET}
          list={vendorProspects?.vendorProspects}
          listCount={vendorProspects?.count}
          handleClickButton={handleCLick}
          noText={'Vendor Prospects'}
        />
      </TableWrapper>
    </div>
  );
};

