import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './agent.service';
import {
  ACTIVATE_AGENT,
  ADD_BRACKET, CHANGE_DEFAULT_BRACKET,
  CREATE_AGENT,
  DELETE_BRACKET,
  EDIT_AGENT,
  EDIT_AGENT_BRACKET,
  GET_AGENT_BY_BRANCH,
  GET_AGENT_BY_BRANCH_SUCCESS,
  GET_AGENT_BY_ID,
  GET_AGENT_BY_ID_BRACKET,
  GET_AGENT_BY_ID_SUCCESS,
  GET_AGENT_BY_OFFICE,
  GET_AGENT_BY_OFFICE_SUCCESS,
  GET_AGENTS,
  GET_AGENTS_SUCCESS,
  GET_BRACKET,
  GET_BRACKET_SUCCESS,
  GET_BY,
  GET_BY_SUCCESS,
  GET_DEFAULT_BRACKET,
  GET_DEFAULT_BRACKET_SUCCESS,
  GET_MANAGER_BY_BRANCH,
  GET_MANAGER_BY_BRANCH_SUCCESS,
  GET_MANAGER_BY_OFFICE,
  GET_MANAGER_BY_OFFICE_SUCCESS,
  INACTIVATE_AGENT, RESEND_AGENT_INVITE,
} from './agent.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { agentActions } from './index';

/** Create, Edit Agent */

function* createAgent(action) {
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  try {
    yield call(authService.createAgentService, action.payload.body);
    window.location.replace('/agents');
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(action.type, err.data.message));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  }
}

function* editAgent({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editAgentService, payload.id, payload.body);

    // yield put({
    //     type: GET_AGENT_BY_ID,
    //     payload: {id: payload.body, load: 'noLoad'}
    // })
    if (payload.type === 'branch') {
      yield put(agentActions.getAgentByBranch(payload.id.branchId));
      const data = {
        officeId: payload.id.officeId,
        branchId: payload.id.branchId,
      };
      yield put(agentActions.getManagersByBranch(data));
    }
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* resendInvite({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.resendInviteService, payload.id);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

/** End */

/** Get Agents */

function* getAgents({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getAgentsService, payload.params);
    yield put({
      type: GET_AGENTS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* getBy({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getOfficeManagerBranch, payload.id);
    yield put({
      type: GET_BY_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* getAgentById(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  if (action?.payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  }
  try {
    const res = yield call(authService.getAgentByIdService, action.payload);
    yield put({
      type: GET_AGENT_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* getAgentByIdBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  try {
    const res = yield call(authService.getAgentByIdService, action.payload);
    yield put({
      type: GET_AGENT_BY_ID_SUCCESS,
      payload: res.data,
    });
  } catch (err) {

  }
}

/** End */

/** Get Agents and Managers */

function* getAgentByOffice({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getAgentByOfficeIdService, payload.officeId);
    yield put({
      type: GET_AGENT_BY_OFFICE_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* getAgentByBranch({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getAgentByBranchIdService, payload.branchId);
    yield put({
      type: GET_AGENT_BY_BRANCH_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put({
      type: GET_AGENT_BY_BRANCH_SUCCESS,
      payload: [],
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  }
}

function* getManagersByOffice({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getManagersByOfficeIdService, payload.officeId);
    yield put({
      type: GET_MANAGER_BY_OFFICE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

function* getManagersByBranch({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getManagersByBranchIdService, payload.data);
    yield put({
      type: GET_MANAGER_BY_BRANCH_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

/** End */

/** Activate or Inactivate Agent */

function* activateAgent(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.activateAgentService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    if (action?.payload?.branchId) {
      yield put({
        type: GET_AGENT_BY_BRANCH,
        payload: { branchId: action.payload.branchId },
      });
    }
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* inactivateAgent(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.inactivateAgentService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    if (action?.payload?.branchId) {
      yield put({
        type: GET_AGENT_BY_BRANCH,
        payload: { branchId: action.payload.branchId },
      });
    }
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

/** Payroll Brackets */

function* addBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.addBracketService, action?.payload?.id, action?.payload?.data);
    yield put({
      type: GET_DEFAULT_BRACKET,
      payload: { id: action.payload.id },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* editAgentBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.editBracketService, action?.payload?.id, action?.payload?.data, action?.payload?.bracketId);
    yield put({
      type: GET_DEFAULT_BRACKET,
      payload: { id: action.payload.id },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
  }
}

function* deleteBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.deleteBracketService, action.payload.id, action.payload.bracketId);
    yield put({
      type: GET_DEFAULT_BRACKET,
      payload: { id: action.payload.id },
    });
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* getBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  try {
    const res = yield call(authService.getBracketService, action.payload.id);
    yield put({
      type: GET_BRACKET_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
  }
}

function* getDefaultBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    const res = yield call(authService.getDefaultBracketService, action.payload.id);
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put({
      type: GET_DEFAULT_BRACKET_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

function* changeDefaultBracket(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.changeDefaultBracketService, action.payload.id, action.payload.idDefault);
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put({
      type: GET_DEFAULT_BRACKET,
      payload: { id: action.payload.id },
    });
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  }
}

/** End */


export const watchAgent = function* watchAgentSaga() {
  /** Create, Edit Agent */
  yield takeLatest(CREATE_AGENT, createAgent);
  yield takeLatest(EDIT_AGENT, editAgent);
  yield takeLatest(RESEND_AGENT_INVITE, resendInvite);
  /** End */

  /**Get Agents */
  yield takeLatest(GET_AGENTS, getAgents);
  yield takeLatest(GET_AGENT_BY_ID, getAgentById);
  yield takeLatest(GET_AGENT_BY_ID_BRACKET, getAgentByIdBracket);
  /** End */

  /** Get Agents and Managers */
  yield takeLatest(GET_AGENT_BY_OFFICE, getAgentByOffice);
  yield takeLatest(GET_MANAGER_BY_OFFICE, getManagersByOffice);
  yield takeLatest(GET_AGENT_BY_BRANCH, getAgentByBranch);
  yield takeLatest(GET_MANAGER_BY_BRANCH, getManagersByBranch);
  /** End */

  /** Activate or Inactivate Agent */
  yield takeLatest(ACTIVATE_AGENT, activateAgent);
  yield takeLatest(INACTIVATE_AGENT, inactivateAgent);
  /** End */

  /** Payroll Brackets */
  yield takeLatest(ADD_BRACKET, addBracket);
  yield takeLatest(EDIT_AGENT_BRACKET, editAgentBracket);

  yield takeLatest(DELETE_BRACKET, deleteBracket);
  yield takeLatest(GET_BRACKET, getBracket);
  yield takeLatest(GET_DEFAULT_BRACKET, getDefaultBracket);
  yield takeLatest(CHANGE_DEFAULT_BRACKET, changeDefaultBracket);
  yield takeLatest(GET_BY, getBy);
  /** End */
};
