import { Images } from 'utils';
import { IconAndTitle, TypeRow, TextRow } from 'components';
import { carrierStatuses } from '../carriers/constants';

const customerTypeEnums = [
  { name: 'Broker', id: 'BROKER' },
  { name: 'Shipper', id: 'SHIPPER' },
  { name: 'Freight Forwarder', id: 'FORWARDER' },
  { name: 'Medical Transportation', id: 'MEDICAL_TRANSPORTATION' },
  { name: 'Waste Management', id: 'WASTE_MANAGEMENT' },
  { name: 'Individual customer', id: 'INDIVIDUAL_CUSTOMER' },
];


export const customersHead = [
  { name: 'name', title: 'Name', searchKey: 'name' },
  { name: 'mc', title: 'MC Number', searchKey: 'mc', custom: false },
  {
    name: 'type', title: 'Type',
    filterList: [
      { name: 'All', id: '' },
      ...customerTypeEnums,
    ],
  },
  { name: 'email', title: 'Email Address' },
  { name: 'address', title: 'Address', custom: false },
];

const currentType = (type) => {
  const current = customerTypeEnums.find((item) => item.id === type);
  return current?.name;

};
export const customersBody = [
  { rowText: (item) => <IconAndTitle icon={Images.customer} title={item?.name} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.mc} textWidth={10} /> },
  { rowText: (item) => <TypeRow text={currentType(item?.type)} /> },
  { rowText: (item) => <TextRow name={item?.email} textWidth={10} /> },
  { rowText: (item) => <TextRow name={item?.address?.formattedAddress} textWidth={10} /> },
];

export const ACTION_TYPE = 'GET_CUSTOMERS';

export const customerStatuses = [
  { id: 'All', name: 'All' },
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' },
];

export const customersCsv = {
  statusEnum: 'status',
  statusList: customerStatuses,
  type: 'customer',
  requiredStatus: true,
};