import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddModalButton, MaskInput, SelectInput, ValidationInput } from 'components';
import {
  CountryList,
  EmailValidator,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccess,
  useGlobalTextStyles,
  useModal,
} from 'utils';
import { directoryActions, httpRequestsOnSuccessActions } from 'store';
import { directoryTypes } from './constants';

export const CreateDirectory = ({ info, afterSuccess }) => {
  const actionType = info ? 'EDIT_DIRECTORY' : 'CREATE_DIRECTORY';
  const globalText = useGlobalTextStyles();
  const dispatch = useDispatch();
  const loading = FindLoad(actionType);
  const success = FindSuccess(actionType);
  const { close } = useModal();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const backError = FindErrorItem(actionType);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;

  useEffect(() => {
    if (info?.id) {
      setInputs({
        companyType: directoryTypes().find((item) => item?.id === info?.companyType)?.id,
        department: info?.department,
        name: info?.name,
        title: info?.title,
        phoneNumber: info?.phone,
        email: info?.email,
        extension: info?.extension,
      });
    }
  }, [info]);

  useEffect(() => {
    if (success?.length) {
      afterSuccess();
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      close();
    }
  }, [success]);

  const create = () => {
    const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '';

    const data = {
      department: inputs?.department,
      name: inputs?.name,
      companyType: inputs?.companyType,
    };

    inputs?.title ? data.title = inputs?.title : delete data.title;
    inputs?.email ? data.email = inputs?.email : delete data.email;
    inputs?.phoneNumber ? data.phone = phoneNumber : delete data.phone;
    inputs?.extension ? data.extension = inputs?.extension : delete data.extension;


    if (!emailError.length) {
      if (inputs?.department && inputs?.name) {
        if (info) {
          dispatch(directoryActions.editDirectory(info.id, data));
        } else {
          dispatch(directoryActions.createDirectory(data));
        }
      } else {
        setError(
          !inputs?.companyType ? 'companyType' :
            !inputs.department ? 'department' : !inputs.name ? 'name' : '',
        );
      }
    }
  };


  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error && setError('');
  };

  return (
    <div style={{ width: 500 }}>
      <p className={globalText.modalTitle}>{info ? 'Edit Directory' : 'Add Directory'}</p>
      <p className={globalText.modalText}>{!info && 'Please fulfill the below fields to add a Directory.'}</p>

      <SelectInput
        name={'companyType'}
        label={'Choose Company*'}
        handleSelect={handleChange}
        // loader={loading}
        value={inputs?.companyType}
        list={directoryTypes()}
        type={'id'}
        typeError={error === 'companyType' && ErrorText.field}
        showErrorAlways={true}
      />

      <ValidationInput
        onChange={handleChange}
        value={inputs.department}
        typeError={error === 'department' && ErrorText.field}
        label={'Department*'}
        type={'text'}
        variant={'outlined'}
        name={'department'}
      />
      <ValidationInput
        onChange={handleChange}
        value={inputs.name}
        typeError={error === 'name' && ErrorText.field}
        label={'Name*'}
        type={'text'}
        variant={'outlined'}
        name={'name'}
      />
      <ValidationInput
        onChange={handleChange}
        value={inputs.title}
        typeError={error === 'title' && ErrorText.field}
        label={'Title'}
        type={'text'}
        variant={'outlined'}
        name={'title'}
      />

      <div className='align-center' style={{ gap: 8 }}>
        <MaskInput
          border={'black'}
          name='phoneNumber'
          label='Phone Number'
          value={inputs?.phoneNumber}
          handleChange={handleChange}
          info={info}
          error={
            error === 'phoneNumber' ? ErrorText.field :
              errorText === 'phone must be a valid phone number' && ErrorText.phone}
        />
        <ValidationInput
          onChange={handleChange}
          value={inputs?.extension}
          typeError={error === 'extension' && ErrorText.field}
          label={'Ext'}
          type={'number'}
          variant={'outlined'}
          name={'extension'}
        />
      </div>
      <ValidationInput
        validator={EmailValidator}
        variant={'outlined'}
        name={'email'}
        type={'email'}
        label={'Email Address'}
        typeError={error === 'email' ? ErrorText.field : emailError === 'Not valid email' ? 'Not valid email' : ''}
        sendBoolean={(bool) => bool === true ? setEmailError('Not valid email') : setEmailError('')}
        onChange={handleChange}
        value={inputs?.email}
      />
      <AddModalButton
        loading={!!loading?.length}
        handleClick={create}
        text={info ? 'Save' : 'Add'}
      />
    </div>
  );
};