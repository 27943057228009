import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './offices.service';
import {
  CREATE_OFFICE,
  GET_OFFICE_BY_ID,
  GET_OFFICE_BY_ID_SUCCESS,
  GET_OFFICES,
  GET_OFFICES_SUCCESS,
  EDIT_OFFICE,
  ACTIVATE_OFFICE,
  INACTIVATE_OFFICE,
  CREATE_OFFICE_MANAGERS,
  SELECT_OFFICE_MANAGERS,
  REMOVE_OFFICE_MANAGERS,
  GET_OFFICE_BRANCHES,
  GET_OFFICE_BRANCHES_SUCCESS,
  GET_OFFICES_FOR_AGENTS,
  ADD_OFFICE_EXPENSES,
  SUBTRACT_OFFICE_EXPENSES,
  CHANGE_DIRECTORY_ACCESS_STATUSES,
  EDIT_OFFICE_EXPENSES,
  DELETE_OFFICE_EXPENSES,
} from './offices.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { agentActions } from '../agents';

/** Create, Edit Office */

function* createOffice({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.createOfficeService, payload.body);
    window.location.replace('/offices');
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}


function* editOffice({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editOfficeService, payload?.body, payload?.id);
    yield put({
      type: GET_OFFICE_BY_ID,
      payload: { id: payload?.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

/** End */

/** Get Offices */

function* getOffice({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    const res = yield call(authService.getOfficesService, payload.params);
    yield put({
      type: GET_OFFICES_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getOfficeForAgents({ type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.removeLoading(type));
  try {
    const res = yield call(authService.getOfficesService);
    yield put({
      type: GET_OFFICES_SUCCESS,
      payload: res.data.reverse(),
    });
  } catch (err) {
  }
}

function* getOfficeById({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(authService.getOfficeByIdService, payload.id);
    yield put({
      type: GET_OFFICE_BY_ID_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

/** End */

/** Activate or Inactivate Office */

function* activateOffice(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {

    yield call(authService.activateOfficeService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));

  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

function* inactivateOffice(action) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
  yield put(httpRequestsOnErrorsActions.removeError(action.type));
  yield put(httpRequestsOnLoadActions.appendLoading(action.type));
  try {
    yield call(authService.inactivateOfficeService, action.payload.id);
    yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
  }
}

/** End */

/** Office Management */

function* createOfficeManager({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.officeManagerCreateService, payload.data);
    const officeId = payload.data.officeId;
    yield put(agentActions.getAgentByOffice(officeId));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

function* selectOfficeManager({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.selectOfficeManagerService, payload.agentId, payload.officeId);
    yield put(agentActions.getAgentByOffice(payload.officeId));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
  }
}

function* removeOfficeManager({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.removeOfficeManagerService, payload.data);
    yield put(agentActions.getAgentByOffice(payload.data.officeId));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
  }
}

function* getOfficeBranches({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    const res = yield call(authService.getOfficeBranchesService, payload.id, payload?.params);
    yield put({
      type: GET_OFFICE_BRANCHES_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  } catch (err) {
    yield put({
      type: GET_OFFICE_BRANCHES_SUCCESS,
      payload: [],
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */

function* officeExpenses({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.addExpensesService, payload.id, payload?.params);
    yield put({
      type: GET_OFFICE_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editOfficeExpenses({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.editExpensesService, payload.id, payload?.expenseId, payload?.info);
    yield put({
      type: GET_OFFICE_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteOfficeExpenses({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.deleteExpensesService, payload.id, payload?.expenseId);
    yield put({
      type: GET_OFFICE_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* officeSubExpenses({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.addSubtractExpensesService, payload.id, payload?.params);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    yield put({
      type: GET_OFFICE_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */


/** Office Directory */

function* changeDirectoryStatus({ payload, type }) {
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  try {
    yield call(authService.changeDirectoryStatusesService, payload.id, payload?.params);
    yield put(httpRequestsOnLoadActions.removeLoading(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));

    yield put({
      type: GET_OFFICE_BY_ID,
      payload: { id: payload.id, load: 'noLoad' },
    });
  } catch (err) {
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

/** End */


export const watchOffice = function* watchOfficeSaga() {
  /** Create, Edit Office */
  yield takeLatest(CREATE_OFFICE, createOffice);
  yield takeLatest(EDIT_OFFICE, editOffice);
  /** End */

  /** Get Offices */
  yield takeLatest(GET_OFFICES, getOffice);
  yield takeLatest(GET_OFFICES_FOR_AGENTS, getOfficeForAgents);
  yield takeLatest(GET_OFFICE_BY_ID, getOfficeById);
  /** End */

  /** Activate or Inactivate Office */
  yield takeLatest(ACTIVATE_OFFICE, activateOffice);
  yield takeLatest(INACTIVATE_OFFICE, inactivateOffice);
  /** End */

  /** Office Management */
  yield takeLatest(CREATE_OFFICE_MANAGERS, createOfficeManager);
  yield takeLatest(SELECT_OFFICE_MANAGERS, selectOfficeManager);
  yield takeLatest(REMOVE_OFFICE_MANAGERS, removeOfficeManager);
  /** End */

  /** Get Office Branches */
  yield takeLatest(GET_OFFICE_BRANCHES, getOfficeBranches);
  /** End */

  /** Office Expenses */
  yield takeLatest(ADD_OFFICE_EXPENSES, officeExpenses);
  yield takeLatest(EDIT_OFFICE_EXPENSES, editOfficeExpenses);
  yield takeLatest(DELETE_OFFICE_EXPENSES, deleteOfficeExpenses);
  yield takeLatest(SUBTRACT_OFFICE_EXPENSES, officeSubExpenses);
  /** End */

  /** Office Directory */
  yield takeLatest(CHANGE_DIRECTORY_ACCESS_STATUSES, changeDirectoryStatus);
  /** End */
};
