import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { DeleteElement, MinLoader, SelectInput, SimpleModal, ToastScreen } from 'components';
import { screensStyle } from './styles';
import { Box, LinearProgress, Popper } from '@material-ui/core';
import { tableStyle } from '../table/styles';
import { UploadServices } from '../../store/authServices/uplodService';
import { AntSwitch, Colors, fileMaxSize, FindLoad, FindSuccess, Images, PermissionsList, RolePermission } from 'utils';
import { httpRequestsOnSuccessActions, httpRequestsOnLoadActions } from 'store';
import { Download } from '../buttons';
import moment from 'moment';
import button from 'react-rte/lib/ui/Button';
import { CARRIER_DOCK_STATUSES, CARRIER_DOCKS } from '../../pages/carriers/constants';
import { toast } from 'react-toastify';

export const DocumentsComponent = ({
                                     docTypes,
                                     fileTypeList,
                                     documents,
                                     handleUpload,
                                     handleDeleteFile,
                                     handleFilter,
                                     actionType,
                                     removeActionType,
                                     editActionType,
                                     name,
                                     handleSwitch,
                                     switchButton,
                                     noDel,
                                     permissionForDelete,
                                     noUpload,
                                     noDemo,
                                     dockType,
                                     notUpload
                                   }) => {
  const customClasses = tableStyle();
  const classes = screensStyle();
  const dispatch = useDispatch();
  const [docs, setDocks] = useState([]);
  const [fileType, setFileType] = useState('');
  const [docType, setDocType] = useState('All DocumentsSection');
  const [fileId, setFileId] = useState('');
  const [openDel, setOpenDel] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const addLoader = FindLoad(actionType);
  const deleteLoader = FindLoad(removeActionType);
  const editLoader = FindLoad(editActionType);
  const deleteSuccess = FindSuccess(removeActionType);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBool = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if (deleteSuccess?.length) {
      setOpenDel(false);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(removeActionType));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (documents?.length) {
      setDocks(documents);
    }
  }, [documents]);


  const handleChange = async (e, k) => {
    if (e?.size > fileMaxSize) {
      toast(<ToastScreen type={'error'} text={'File size must be less than 4MB.'} />);
      return;
    }

    if(dockType && dockType === 'text/csv' && e?.type !== "text/csv"){
      return alert('Please upload csv file')
    }

    if(notUpload){
      const name = fileType ? fileType : e?.name?.replace(',', '').replace(/\s/g, '').replace('#', '');
      const myNewFile = new File([e], name, { type: e?.type });
      let formData = new FormData();
      formData.append('files', myNewFile);
      handleUpload(formData);
    }else {
      if (e) {

        const name = fileType ? fileType : e?.name?.replace(',', '').replace(/\s/g, '').replace('#', '');
        const myNewFile = new File([e], name, { type: e?.type });

        let formData = new FormData();
        formData.append('files', myNewFile);

        try {
          dispatch(httpRequestsOnLoadActions.appendLoading(actionType));
          const res = await UploadServices.UploadImage(formData);

          const pos = e.type.slice(e.type.search('/'));
          const fileFormat = pos.substring(1);

          const info = {
            file: {
              ...res.data,
              name: e.name,
              originalName: e.name,
              format: fileFormat,
            },
          };
          if (fileTypeList) {
            info.type = fileType;
            info.name = e.name;
          } else {
            info.name = e.name;
          }
          handleUpload(info);
        } catch (e) {
          dispatch(httpRequestsOnLoadActions.removeLoading(actionType));
        }
      }
    }
  };

  const handleOpenCloseDel = (id) => {
    setFileId(id);
    setOpenDel(!openDel);
  };

  const handleRemoveDoc = () => {
    if (handleDeleteFile) {
      handleDeleteFile(fileId);
    }
  };

  const handleFilterChange = (ev) => {
    setDocType(ev.target.value);
    handleFilter(ev);
  };

  const handleChangeStatus = (doc, type) => {
    setSelectedId(doc.id);
    if (handleSwitch) {
      handleSwitch(doc, type);
    }
  };
  const handleSelectFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setFileType(ev.target.value);
  };


  const handleClick = (event, dock) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setSelectedId(dock.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function formatKey(key) {
    return key
      .toLowerCase() // Convert to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
  }


  return (
    <div className={noDemo ? '' : "space-between"}>
      {!noDemo &&
        <div className={classes.uploadedCard} style={noUpload ? { width: '100%', margin: 0 } : {}}>
          {docTypes &&
            <div className={classes.uploadedCardHeader}>
              <SelectInput
                name={'docType'}
                handleSelect={handleFilterChange}
                style={classes.filterInput}
                value={docType}
                list={docTypes}
                type={'mc'}
              />
            </div>
          }
          <div className={classes.uploadedDocumentsWrapper}>
            {addLoader?.length ?
              <div className={classes.loader}>
                <LinearProgress />
              </div> : ''
            }
            {documents?.length ? docs?.map((doc, i) => (
                <div className={classes.uploadedDocWrapper} key={i}>
                  <div style={{ width: '70%' }}>
                    <div className={classes.dockLinkBox}>
                      <button
                        className={classes.dockLink}
                        onClick={() => open(doc?.file?.url || doc?.url)}
                      >
                        {name === 'typeName' ?
                          `(${formatKey(doc?.type)})-${doc?.name}`
                          :
                          doc.type ? `${doc.type} ${doc?.index ? `- ${doc?.index}` : ''}` : doc?.name}
                      </button>
                    </div>

                    <div className="align-center">
                      {(doc?.createdDate || doc?.creator) &&
                        <p className={classes.documentDate}>
                          {`${doc?.createdDate ? `${moment.utc(doc?.createdDate).format('MM/DD/YYYY')} ${moment(doc?.createdDate).format('LT')}` : ''} ${doc?.creator ? `Uploaded by ${doc?.creator}` : ''}`}
                        </p>
                      }
                    </div>

                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    {doc?.type === CARRIER_DOCKS?.PROOF_OF_INSURANCE && doc?.status === CARRIER_DOCK_STATUSES?.PENDING &&
                      <div className="align-center" style={{ gap: 12 }}>
                        <button
                          style={editLoader?.length && selectedId === doc.id ? { opacity: 0.5 } : {}}
                          onClick={() => handleChangeStatus(doc, 'approve')}
                          className={classes.approveBtn}
                        >
                          Approve
                        </button>
                        <button
                          style={editLoader?.length && selectedId === doc.id ? { opacity: 0.5 } : {}}
                          onClick={() => handleChangeStatus(doc, 'deny')}
                          className={classes.denyBtn}
                        >
                          Deny
                        </button>
                      </div>
                    }

                    {switchButton && doc?.status !== CARRIER_DOCK_STATUSES?.PENDING &&
                      <div className={classes.switchWrapper} style={{ height: 24 }}>
                        {editLoader?.length && selectedId === doc.id ?
                          <MinLoader
                            margin={'0'}
                            position={'relative'}
                            color={Colors.ThemeBlue}
                          />
                          :
                          <AntSwitch
                            onClick={() => handleChangeStatus(doc)}
                            checked={doc.status === 'ACTIVE'}
                          />
                        }
                      </div>
                    }

                    <button
                      style={{ height: 24 }}
                      aria-describedby={id} type="button" onClick={(e) => handleClick(e, doc)}
                      className="reset-btn"
                    >
                      <img src={Images.more} alt="icon" />
                    </button>

                    <Popper
                      id={id}
                      open={openBool && selectedId === doc.id}
                      anchorEl={anchorEl}
                      className={'document-select-paper-box'}
                      onClose={handleClose}
                      style={{ position: 'absolute', zIndex: 9999 }}
                    >
                      <Box>
                        <div className={classes.carrierCsvButtonsBox}>
                          <Download
                            fullFile={doc}
                          />
                          {RolePermission(permissionForDelete ? [PermissionsList.ALL_ACCESS?.code, permissionForDelete] : [PermissionsList.ALL_ACCESS?.code]) && !noDel &&
                            <button className="delete-document-btn" onClick={() => handleOpenCloseDel(doc)}>
                              <img src={Images.removeRed} alt="remove" />
                              <p>Remove</p>
                            </button>
                          }
                        </div>
                      </Box>
                    </Popper>
                    {openBool && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
                  </div>
                </div>
              )) :
              <div className={classes.noItem}>
                <p>No Uploaded Documents</p>
              </div>
            }
          </div>
        </div>
      }
      {!noUpload &&
        <div style={{ width: noDemo ? '100%' : '50%' }}>
          <div className="drag-drop">
            <img src={Images.upload} alt="upload" />
            <>
              {fileTypeList &&
                <>
                  <p className="select-text">To upload document please, select the document type.</p>
                  <SelectInput
                    style={customClasses.selectType}
                    name={'fileType'}
                    handleSelect={(ev) => handleSelectFile(ev)}
                    value={fileType}
                    list={fileTypeList}
                    type={'name'}
                    noHelper={true}
                  />
                </>
              }
              <FileUploader
                name="file"
                handleChange={fileTypeList ? fileType ? handleChange : '' : handleChange}
                disabled={fileTypeList ? !fileType : false}
                draggable={fileTypeList ? !fileType : false}
              >
                <label className="custom-file-upload">
                  <input
                    onClick={event => event.target.value = null}
                    disabled={fileTypeList ? !fileType : false}
                    onChange={handleChange}
                    type="file"
                    id="file"
                  />
                  <i className="fa fa-cloud-upload" />
                  <span className={fileTypeList && !fileType ? 'upload-err' : 'upload'}>
                                 {fileTypeList && !fileType ?
                                   'Select file type please'
                                   :
                                   ' Drag & Drop or Upload File'
                                 }</span>
                </label>
              </FileUploader>
            </>
          </div>
        </div>
      }

      {noDemo && !!addLoader?.length &&
        <div className={classes.loader}>
          <LinearProgress />
        </div>
      }

      <SimpleModal
        handleOpenClose={handleOpenCloseDel}
        openDefault={openDel}
        content={
          <DeleteElement
            loading={!!deleteLoader.length}
            text={'Delete File?'}
            handleClose={handleOpenCloseDel}
            handleDel={handleRemoveDoc}
            info={fileId?.name}
          />
        }
      />
    </div>
  );
};