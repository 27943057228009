import { TextToolTip } from 'components';
import { Images, PermissionsList, RolePermission } from 'utils';
import { EmailsAndTooltip } from './emailsAndTooltip';
import { vendorTypes } from '../../vendors/fragments/constants';
import { vendorEnums } from '../../vendors';
import { ContactInfoPopover } from './contactInfoPopover';


export const ACTION_TYPE_GET = 'GET_VENDOR_PROSPECTS';
export const ACTION_TYPE_DELETE = 'DELETE_VENDOR_PROSPECTS';

export const loadTypeEnum = {
  MAIN: 'MAIN',
  AIR: 'AIR',
  SEA: 'SEA',
  CUSTOMS: 'CUSTOMS',
  DRAYAGE: 'DRAYAGE',
  REEFER_DRAYAGE: 'REEFER_DRAYAGE',
  WAREHOUSE: 'WAREHOUSE',
  COLD_STORAGE: 'COLD_STORAGE',
  DRY: 'DRY',
  REEFER: 'REEFER',
  BOX_TRUCK: 'BOX_TRUCK',
  RAIL: 'RAIL',
  RAIL_REEFER: 'RAIL_REEFER',
  POWER: 'POWER',
  FLATBED: 'FLATBED',
  AUTO_CARRIER: 'AUTO_CARRIER',
  SPRINTER_VAN: 'SPRINTER_VAN',
  OTHER: 'OTHER',
};

export const loadTypeList = [
  { name: 'Main', id: loadTypeEnum.MAIN },
  { name: 'Air', id: loadTypeEnum.AIR },
  { name: 'Sea', id: 'SEA' },
  { name: 'Customs', id: loadTypeEnum.CUSTOMS },
  { name: 'Drayage', id: loadTypeEnum.DRAYAGE },
  { name: 'Reefer Drayage', id: loadTypeEnum.REEFER_DRAYAGE },
  { name: 'Warehouse', id: loadTypeEnum.WAREHOUSE },
  { name: 'Cold Storage', id: loadTypeEnum.COLD_STORAGE },
  { name: 'Dry', id: loadTypeEnum.DRY },
  { name: 'Reefer', id: loadTypeEnum.REEFER },
  { name: 'Box Truck', id: loadTypeEnum.BOX_TRUCK },
  { name: 'Rail', id: loadTypeEnum.RAIL },
  { name: 'Rail Reefer', id: loadTypeEnum.RAIL_REEFER },
  { name: 'Power Only', id: loadTypeEnum.POWER },
  { name: 'Flatbed or Step Deck', id: loadTypeEnum.FLATBED },
  { name: 'Auto Carrier', id: loadTypeEnum.AUTO_CARRIER },
  { name: 'Sprinter Van', id: loadTypeEnum.SPRINTER_VAN },
  {name: 'Other', id: loadTypeEnum.OTHER}
];


export const vendorProspectsHeader = [
  { name: 'name', title: 'Vendor', custom: true },
  {
    name: 'vendorType', title: 'Vendor Type', filterList: [
      { name: 'All', id: '' },
      ...vendorTypes.filter((type) => type?.id !== vendorEnums.FACTORED),
    ],
  },
  { name: 'modes', title: 'Modes', custom: false },
  { name: '', title: 'Contacts', custom: false },
  { name: 'address', title: 'Address', custom: true },
  { name: '', title: 'Service Areas', custom: false },
  { name: '', title: 'Comments', custom: false },
  { name: 'officeName', title: 'Office', custom: true },

  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.MANAGE_VENDOR_PROSPECTS?.code], 'officeOrBranchManager') &&
  { name: '', title: 'Action', custom: false },
];

const vendorTypeConvertor = (type) => {
  switch (type) {
    case vendorEnums.CARRIER:
      return 'Carrier';
    case vendorEnums.CO_BROKER:
      return 'Co-Broker';

    case vendorEnums.WAREHOUSE:
      return 'Warehouse';

    case vendorEnums.CUSTOM_BROKER:
      return 'Custom Broker';

    case vendorEnums.FREIGHT_FORWARDER:
      return 'Freight Forwarder';

    case vendorEnums.INTERMODAL_FREIGHT_FORWARDER:
      return 'Int. Freight Forwarder';

    case vendorEnums.FACTORED:
      return 'Factored Vendor';

    case vendorEnums.VENDOR:
      return 'Vendor';

    case vendorEnums.INHOUSE_PROFILE:
      return 'Inhouse Profile';

    default:
      return type;
  }
};
export const vendorProspectsBody = [
  { rowText: (item) => <TextToolTip text={item?.name} content={item?.name} /> },
  {
    rowText: (item) =>
      <TextToolTip
        text={vendorTypeConvertor(item?.vendorType)}
        content={vendorTypeConvertor(item?.vendorType)}
      />,
  },

  {
    rowText: (item) =>
      <EmailsAndTooltip
        emails={loadTypeList.filter((type) => item?.modes?.includes(type?.id))?.map((type) => type?.name)} />,
  },

  {
    rowText: (item) =>
      <ContactInfoPopover items={item?.contacts} />,
  },

  {
    rowText: (item) =>
      <TextToolTip text={item?.address} content={item?.address} />,
  },

  { rowText: (item) => <TextToolTip text={item?.serviceAreas} content={item?.serviceAreas} /> },

  {
    rowText: (item) =>
      <TextToolTip text={item?.comments} content={item?.comments} />,
  },

  {
    rowText: (item) =>
      <TextToolTip text={item?.officeName} content={item?.officeName} />,
  },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.MANAGE_VENDOR_PROSPECTS?.code], 'officeOrBranchManager') &&
  {
    button: (item) =>
      <div className='align-center' style={{ gap: 8 }}>
        <button
          onClick={() => item('edit')}
          className='reset-btn'>
          <img src={Images.edit} alt='edit' />
        </button>

        <button
          onClick={() => item('delete')}
          className='reset-btn'>
          <img src={Images.clear} alt='edit' />
        </button>
      </div>, notClickable: true, width: '7%',
  },
];