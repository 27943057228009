import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  agentActions,
  branchActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
  officeActions,
} from 'store';
import {
  createInputsWrapper,
  EmailValidator,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccessItem, PermissionsList, RolePermission,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import {
  CreateChancel,
  Line,
  ValidationInput,
  EditSaveButtons,
  SelectInput,
  MaskInput,
  FormatAddress,
} from 'components';

const CREATE_TYPE = 'CREATE_AGENT';
const EDIT_TYPE = 'EDIT_AGENT';

export const CreateAgentInputs = ({ handleChangeFirstName, handleChangeLastName, info }) => {
  const { officesList, officeBranchesList } = useSelector((state) => ({
    officesList: state.offices.officesList,
    officeBranchesList: state.offices.officeBranchesList,
  }));
  const globalText = useGlobalText();
  const globalStyles = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const dispatch = useDispatch();
  const history = useHistory();
  const [disables, setDisabled] = useState(!!info);
  const [fullAddress, setFullAddress] = useState(info?.address ? info?.address : null);
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState({});
  const [email, setEmail] = useState('');
  const actionType = info ? EDIT_TYPE : CREATE_TYPE;
  const loader = FindLoad(CREATE_TYPE);
  const success = FindSuccessItem(EDIT_TYPE);
  const backError = FindErrorItem(actionType);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;
  const getOfficeBranchLoader = FindLoad('GET_OFFICE_BRANCHES');

  useEffect(() => {
    dispatch(officeActions.getOffices({ status: 'ACTIVE' }));
    dispatch(branchActions.getBranches({ status: 'ACTIVE' }));
  }, []);

  useEffect(() => {
    if (info) {
      const params = {
        ...info,
      }

      if(params?.brokerageInfo){
        delete params.brokerageInfo;
        params.brokerageName = info?.brokerageInfo?.name;
        params.brokeragePhone = info?.brokerageInfo?.phoneNumber;
        params.brokerageEmail = info?.brokerageInfo?.email;
      }
      setInputs(params);
    }
  }, [info]);

  useEffect(() => {
    if (info) {
      if (info.office) {
        const officeId = info?.office?.id;
        dispatch(officeActions.getOfficeBranches(officeId));
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      removeError();
    };
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      setDisabled(true);
    }
  }, [success]);

  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError(''),
    e.target.name === 'firstName' && handleChangeFirstName(e.target.value),
    e.target.name === 'lastName' && handleChangeLastName(e.target.value),
      removeError();
  };

  const handleChangeOffice = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    dispatch(officeActions.getOfficeBranches(e.target.value));
  };

  const handleFullAddress = (ev) => {
    setFullAddress(ev);
    removeError();
    if (error === 'address') setError('');
  };

  const handleCreate = () => {
    const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '';
    const addressIsValid = fullAddress?.street && fullAddress?.city && fullAddress?.country;

    const data = {
      'email': inputs.email,
      'firstName': inputs.firstName,
      'lastName': inputs.lastName,
      'username': inputs?.username ? inputs?.username : `${inputs?.firstName} ${inputs?.lastName}`,
      'phoneNumber': phoneNumber,
      'address': fullAddress,
      'officeId': inputs?.office?.id ? inputs?.office?.id : inputs.office,
      'branchId': inputs?.branch?.id ? inputs?.branch?.id : inputs.branch,
    };

    inputs?.brokerageName ? data.brokerageName = inputs.brokerageName : delete data.brokerageName;
    inputs?.brokeragePhone ? data.brokeragePhone = inputs.brokeragePhone : delete data.brokeragePhone;
    inputs?.brokerageEmail ? data.brokerageEmail = inputs.brokerageEmail : delete data.brokerageEmail;

    const id = info && info.id;

    if (!email.length) {
      if (inputs.firstName && inputs.lastName && inputs?.phoneNumber && inputs.office && inputs.branch && inputs.email && addressIsValid) {
        if (info) {
          dispatch(agentActions.editAgent(id, data));
        } else {
          dispatch(agentActions.createAgent(data));
        }
      } else {
        setError(
          !inputs.firstName ? 'firstName' :
            !inputs.lastName ? 'lastName' :
              !inputs.phoneNumber ? 'phoneNumber' :
                !inputs.office ? 'office' :
                  !inputs.branch ? 'branch' :
                    !inputs.email ? 'email' :
                      !addressIsValid ? 'address' :
                        'Required field',
        );
      }
    }
  };

  const handleChancel = () => {
    setDisabled(true);
    setInputs({ ...info });
    setFullAddress(info?.address);
    setError('');
    removeError();
  };

  return (
    <div className={globalScreens.createInputsWrapper}>
      <div className={globalStyles.spaceBetween}>
        <div className={globalStyles.centerItem}>
          {!info && <p className={globalText.title}>Agent Information</p>}
        </div>
        {info && RolePermission([PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeManager') &&
          <EditSaveButtons
            margin={true}
            type={'EDIT_AGENT'}
            handleChancel={handleChancel}
            handleSetEdit={() => setDisabled(false)}
            handleSaveInfo={handleCreate}
          />
        }
      </div>
      <div className={globalStyles.flexAble}>
        {info && <Line height={'auto'} />}
        <div className={globalStyles.fullWidth} style={{paddingBottom: '20px'}}>
          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Basic Information</p>
            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'firstName'}
                type={'text'}
                label={'First Name*'}
                typeError={error === 'firstName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.firstName}
                disabled={disables}
                className={info && globalStyles.inputTextFieldBlue}
              />
              <ValidationInput
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'lastName'}
                type={'text'}
                label={'Last Name*'}
                typeError={error === 'lastName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.lastName}
                disabled={disables}
                className={info && globalStyles.inputTextFieldBlue}
              />
              <ValidationInput
                style={globalStyles.simpleInputNoRight}
                variant={'outlined'}
                name={'username'}
                type={'text'}
                label={'User Name'}
                typeError={error === 'username' ? ErrorText.field : ''}
                onChange={handleChange}
                value={
                  inputs.username ? inputs?.username :
                    inputs.username === '' ? inputs.username :
                      `${inputs?.firstName ? inputs?.firstName : ''} ${inputs?.lastName ? inputs?.lastName : ''}`
                }
                disabled={disables}
                className={info && globalStyles.inputTextFieldBlue}
              />
            </div>

            <div className={'flex'} style={{ marginTop: '12px' }}>
              <MaskInput
                name="phoneNumber"
                label="Phone Number*"
                value={inputs.phoneNumber}
                disables={disables}
                handleChange={handleChange}
                info={info}
                error={
                  error === 'phoneNumber' ? ErrorText.field :
                    errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
              />
              <SelectInput
                style={globalStyles.simpleInput}
                name={'office'}
                label={'Office*'}
                handleSelect={handleChangeOffice}
                type={'mc'}
                value={inputs?.office?.id ? inputs?.office?.id : inputs.office}
                list={officesList?.offices?.length ? officesList?.offices : []}
                typeError={error === 'office' ? ErrorText.field : ''}
                disabled={disables}
                className={info && globalStyles.inputTextFieldBlue}
              />
              <SelectInput
                loader={getOfficeBranchLoader?.length}
                style={globalStyles.simpleInput}
                name={'branch'}
                label={'Branch*'}
                type={'mc'}
                handleSelect={handleChange}
                value={inputs?.branch?.id ? inputs?.branch?.id : inputs.branch}
                list={officeBranchesList?.branches?.length ? officeBranchesList.branches : []}
                typeError={error === 'branch' ? ErrorText.field : ''}
                disabled={disables}
                className={info && globalStyles.inputTextFieldBlue}
              />
              <ValidationInput
                style={globalStyles.simpleInputNoRight}
                className={
                  errorText === 'User already exists' ||
                  errorText === 'Agent already exists' ||
                  errorText === 'An agent with this email exists' ? globalStyles.inputTextFieldError :
                    info && globalStyles.inputTextFieldBlue
                }
                validator={EmailValidator}
                variant={'outlined'}
                name={'email'}
                type={'email'}
                label={'Email Address*'}
                typeError={
                  errorText === 'User already exists' ? errorText :
                    errorText === 'An agent with this email exists' ? errorText :
                      errorText === 'Agent already exists' ? errorText :
                        error === 'email' ? ErrorText.field :
                          email === 'Not valid email' ? 'Not valid email' : ''
                }
                sendBoolean={(bool) => bool === true ? setEmail('Not valid email') : setEmail('')}
                onChange={handleChange}
                value={inputs.email}
                disabled={disables}
              />
            </div>
          </div>

          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Address</p>
            <div className={globalScreens.basicInfoInputs}>
              <FormatAddress
                noBlue={!info?.address}
                setCurrentAddress={handleFullAddress}
                selectedAddress={info?.address}
                errorBoolean={error === 'address'}
                disabled={disables}
              />
            </div>
          </div>

            {info &&
          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Brokerage Information</p>
            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'brokerageName'}
                type={'text'}
                label={'Name'}
                typeError={error === 'brokerageName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.brokerageName}
                disabled={disables}
                className={info && globalStyles.inputTextFieldBlue}
              />
              <ValidationInput
                style={globalStyles.simpleInput}
                className={info && globalStyles.inputTextFieldBlue}
                validator={EmailValidator}
                variant={'outlined'}
                name={'brokerageEmail'}
                type={'email'}
                label={'Email Address'}
                typeError={
                  error === 'brokerageEmail' ? ErrorText.field :
                    email === 'Not valid brokerage email' ? 'Not valid email' : ''
                }
                sendBoolean={(bool) => bool === true ? setEmail('Not valid brokerage email') : setEmail('')}
                onChange={handleChange}
                value={inputs?.brokerageEmail}
                disabled={disables}
              />
              <MaskInput
                styles={{margin: 0}}
                name="brokeragePhone"
                label="Phone Number"
                value={inputs?.brokeragePhone}
                disables={disables}
                handleChange={handleChange}
                info={info}
                error={
                  error === 'brokeragePhone' ? ErrorText.field :
                    errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
              />
            </div>
          </div>
            }
        </div>
      </div>

      {!info &&
        <CreateChancel
          loader={!!loader.length}
          classes={globalStyles.buttonsStyle}
          create={'Create'}
          chancel={'Cancel'}
          onCreate={handleCreate}
          onClose={() => history.push('/agents')}
        />
      }
    </div>
  );
};

