import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const officesInfoFragments = makeStyles(() => ({

  lineWrapper: {
    display: 'flex',
  },

  managementHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  officeSelectInput: {
    marginBottom: '24px',
    width: '100%',
    marginTop: '32px',
  },

  inputsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '24px',
  },

  officeManagement: {
    width: '100%',
  },

  officeManagementHead: {
    height: '335px',
    overflow: 'auto',
    marginTop: '24px',
    '@media (min-width: 1920px)': {
      marginTop: '32px',
    },
  },
  officeManagementSmall: {
    height: '230px',

    overflow: 'auto',
    // marginTop: '24px',
    '@media (min-width: 1920px)': {
      // marginTop: '32px',
      height: '270px',
    },
  },
  authoritesManagementHead: {
    marginTop: '31px',
    '@media (min-width: 1920px)': {
      marginTop: '31px',
    },
    '& .authorites-item': {
      height: '20px',
    },
    height: '240px',
    overflow: 'auto',
  },
  authorityRow: {
    height: '48px',
    background: '#E6ECF380 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    padding: '0 16px!important',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  authoritiesManagementHeadTitle: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& button': {
      border: 'none',
      outline: 'none',
      background: 'none',
    },
  },
  officeManagementHeadTitle: {
    height: '48px',
    background: '#E6ECF380 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginBottom: '8px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: '16px',

    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& button': {
      border: 'none',
      outline: 'none',
      background: 'none',
    },
  },
  loadDiv: {
    borderBottom: `1px solid ${Colors.TextLight}`,
    width: '500px',
    '& .MuiLinearProgress-barColorPrimary': {
      background: Colors.ThemeBlue,
    },
  },
  officeDocHeadTitle: {
    height: '48px',
    background: 'none',
    borderBottom: `1px solid ${Colors.TextLight}`,
    marginBottom: '8px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',


    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& button': {
      border: 'none',
      outline: 'none',
      background: 'none',
    },
  },

  branchesWrapper: {
    width: '100%',
    marginLeft: '24px',
    '@media (min-width: 1920px)': {
      marginLeft: '32px',
    },
  },

  tableWrapper: {
    '@media (min-width: 1920px)': {
      marginLeft: '32px',
    },
    marginLeft: '24px',
    height: '100%',
    minHeight: '80vh',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '16px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-50px',
  },
  simpleInput: {
    marginRight: '30px',
    width: '233px',
    '@media (min-width: 1919px)': {
      marginRight: '56px',
      width: '344px',
    },
  },
  basicInfoInputs: {
    display: 'flex',
    marginTop: '12px',
    // "@media (min-width: 1920px)": {
    //     marginTop: "24px",
    // },
  },

  managementWrapper: {
    marginRight: '16px',
    width: '100%',
  },

  dragDrop: {
    background: '#F7F9FC 0% 0% no-repeat padding-box',
    border: `3px dashed ${Colors.TextWhiteBlue}`,
    borderRadius: '8px',
    height: '244px',
    width: '100%',
    // marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '@media (min-width: 1920px)': {
      height: '284px',
    },
    '& img': {
      marginBottom: '16px',
    },
  },
  selectType: {
    background: 'white',
    width: '70%',
    marginBottom: '20px',
  },
  selectText: {
    fontSize: '16px',
    color: Colors.TextPrimary,
    marginBottom: '16px',
  },
  drag: {
    fontSize: '24px',
    fontWeight: '600',
    color: Colors.TextPrimary,
  },
  upload: {
    fontSize: '24px',
    fontWeight: '600',
    color: `${Colors.ThemeBlue}!important`,
    marginLeft: '8px',
  },
  downloadButton: {
    '& a': {
      width: '50px',
    },
    width: '50px',
    background: 'none',
    border: 'none',
    outline: 'none',
  },

  selectBranchInput: {
    marginBottom: '24px',
  },

  expensesWrapper: {
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },

  expensesItem: {
    padding: '13px 32px',
    background: Colors.BackgroundPrimary,
    borderRadius: '8px',
    width: '100%',
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',

    '& p': {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '20px',
      color: Colors.TextPrimary,
    },
  },

  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
  },

  addButton: {
    width: '24px',
    height: '24px',
    background: Colors.ThemeBlue,
    borderRadius: '32px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  subtractButton: {
    width: '24px',
    height: '24px',
    background: Colors.ThemeGreen,
    borderRadius: '32px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonText: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.TextPrimary,
    marginLeft: '8px',
  },

  directoryItemWrapper: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '17px',
    marginTop: '29px',
  },

  directoryItem: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    padding: '12px 16px',
    background: 'rgba(67, 138, 254, 0.09)',
  },

  directoryItemText: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.TextSecondary,
    textTransform: 'capitalize',
  },
  suiteInput: {
    marginTop: '18px',
  },

  expenseModalWrapper: {
    width:'450px',

    '@media (min-width: 1920px)': {
      width:'600px',
    },
  },
}));