import { makeStyles, Switch, withStyles } from '@material-ui/core';
import { Backgrounds, Colors } from './globalColors';

export const useGlobalStyles = makeStyles({
  flexAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  receivablesTotalsBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '8px',
    '@media (min-width: 1919px)': {
      gap: '16px',
    },
  },
  totalWrapper: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 6px #8A8A8A29',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignItems: 'center',
    '@media (min-width: 1919px)': {
      padding: '16px 32px',
    },
  },
  totalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: Colors.TextSecondary,
    marginRight: '30px',
    '@media (min-width: 1919px)': {
      marginRight: '64px',
    },
  },

  inputWrapper: {
    width: '100%',
    marginRight: '16px',
  },


  tableStyle: {
    background: 'red',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '16px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },
  flexAndFullWidth: {
    display: 'flex',
    width: '100%',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  maxCharacter: {
    fontSize: '12px',
    color: Colors.TextLight,
  },
  tableWrapper: {
    height: '80vh',
    // minHeight: '80vh',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '25px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },
  tableWrapperNoShadow: {
    height: '100%',
    // minHeight: '80vh',
    width: '100%',
    borderRadius: '8px',
    marginTop: '25px',
    marginBottom: '24px',
  },

  tableWrapperSmallHeight: {
    height: '100%',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '24px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },
  tableWrapperSmall: {
    height: '100%',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '16px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },
  tableWrapperMedium: {
    height: '100%',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '16px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },

  tableWrapperAutoHeight: {
    height: '100%',
    maxHeight: '80vh',
    minHeight: '50vh',
    width: '100%',
    boxShadow: '0px 0px 12px #0052E01A',
    borderRadius: '8px',
    marginTop: '16px',
    marginBottom: '24px',
    '& .MuiTableContainer-root': {
      boxShadow: 'none',
    },
  },


  addressSimpleInput: {
    marginRight: '24px',
    width: '100%',
    '@media (min-width: 1919px)': {
      marginRight: '24px',
    },
  },

  simpleInput: {
    marginRight: '16px',
    width: '100%',

    '@media (min-width: 1919px)': {
      marginRight: '25px',
    },
  },


  simpleInputNoRight: {
    width: '100%',
  },

  inputStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
  },

  simpleLoadInput: {
    marginBottom: '16px',
  },
  loadSimpleInput: {
    marginRight: '16px',
    width: '100%',
    '@media (min-width: 1919px)': {
      marginRight: '16px',
    },
  },
  loadSimpleInputFull: {
    width: '100%',
  },

  simpleInputNoPadding: {
    width: '100%',
  },
  simpleInputFull: {
    width: '100%',
  },

  InfoAndImage: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '14px',
      lineHeight: '19px',
      fontWeight: 'bold',
      color: Colors.TextSecondary,
    },
    '& img': {
      width: '24px',
      height: '24px',
      marginRight: '4px',
      '@media (min-width: 1920px)': {
        marginRight: '8px',
      },
    },
  },

  buttonsStyle: {
    display:'flex',
    gap:'16px',
    marginTop: '14px',
  },
  buttonsStyleFullWidth: {
    marginTop: '14px',
    display: 'flex',
  },
  createOfficeTableHead: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: Colors.TextSecondary,

      '@media (max-width: 1919px)': {
        marginLeft: '4px',

      },
      '@media (min-width: 1920px)': {
        marginLeft: '8px',
      },
    },
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },

  spaceBetweenNoCentered: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  centerItem: {
    display: 'flex',
    alignItems: 'center',
  },

  allCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  fullScreen: {
    display: 'flex',
    width: '100%',
    height: '100%',
    // alignItems:'end'
  },

  flexAble: {
    display: 'flex',
    width: '100%',
  },

  columnAble: {
    display: 'flex',
    flexDirection: 'column',
  },

  fullWidth: {
    width: '100%',
  },

  deleteModal: {
    width: '500px',
    height: 'auto',
    background: Backgrounds.whiteModal,
    borderRadius: '8px',
    padding: '8px 0 40px 0',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    // height: '550px',
    // "@media (min-width: 1919px)": {
    //     height: '738px',
    // },
  },

  tableContainerSmall: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: '368px',
    '@media (min-width: 1919px)': {
      maxHeight: '592px',
    },
  },
  tableContainerMedium: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'auto',
    maxHeight: '368px',
    minHeight: '300px',
    '@media (min-width: 1919px)': {
      maxHeight: '592px',
    },
  },

  tableContainerAutoHeight: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    // height:'650px'

    height: 'auto',
    // "@media (min-width: 1919px)": {
    //     height: '85vh',
    // },
  },

  tableContainerBig: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '110vh',
    '@media (min-width: 1919px)': {
      height: '90vh',
    },
  },

  item: {
    background: Backgrounds.tableActive,
    borderRadius: '4px',
    height: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    marginBottom: '8px',
    cursor: 'pointer',
  },
  activeItem: {
    background: Backgrounds.tableActiveDark,
    borderRadius: '4px',
    height: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    marginBottom: '8px',
    cursor: 'pointer',
  },
  inputTextFieldBlue: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeWhiteBlue,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeWhiteBlue}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiSelect-icon': {
      color: Colors.ThemeBlue,
    },
  },

  inputBlue: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    outline: 'none',
    border: '1px solid rgba(0, 0, 0, 0.56)',
    padding: '0 12px',
    fontSize: '16px',
  },

  inputFieldBlue: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    outline: 'none',
    border: '1px solid #438AFE',
    padding: '0 12px',
    fontSize: '16px',
  },

  inputTextFieldError: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeError,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeError,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeError}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: Colors.ThemeError,
    },
  },

  maxCharacters: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& p': {
      fontSize: '12px',
      color: Colors.TextLight,
      marginRight: '20px',
      marginTop: '4px',
    },
  },

  filtersWrapper: {
    right: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: '#EAF2FF 0% 0% no-repeat padding-box',
    },
    height: '50px',
    '& .makeStyles-membersTableWrapper-26 .MuiTableContainer-root': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 8px 12px #0052E01A',
    },
    '& .MuiTableCell-sizeSmall': {
      fontSize: '14px',
      lineHeight: '22px',
      color: Colors.TextPrimary,

      padding: '8px 16px 8px 16px',
      '@media (min-width: 1920px)': {
        padding: '16px',
      },
    },

    '& .MuiTableCell-sizeSmall:last-child': {
      paddingTop: '11px',
      fontSize: '16px',
      lineHeight: '30px',
      color: '#545F7E',
    },

    '& MuiSwitch-track': {
      borderRadius: '12px',
      background: 'lightgray',
    },

    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: 'white',
    },

    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      background: '#387DFF',
      borderRadius: '12px',
      opacity: '1',
    },
  },
  consign: {
    width: '100px',
    height: '32px',
    background: '#438AFE 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #438AFE4D',
    borderRadius: '18px',
    color: 'white',
    fontSize: '14px',
    textTransform: 'capitalize',
    border: 'none',
    '& :hover': {
      width: '100px',
      height: '32px',
      background: '#3F51B5 0% 0% no-repeat padding-box',
      borderRadius: '18px',
    },
  },
});


export const useGlobalTextStyles = makeStyles({

  downloadText: {
    textDecoration: 'underline',
    fontSize: '16px',
  },

  modalTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '43px',
    color: Colors.TextSecondary,
  },

  modalTitleWithMargin: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '48px',
    color: Colors.TextSecondary,
    marginBottom: '32px',

    '@media (min-width: 1920px)': {
      marginBottom: '40px',
    },
  },

  modalText: {
    color: Colors.TextPrimary,
    fontSize: '16px',
    lineHeight: '24px',
    margin: '16px 0 20px 0',
    '@media (min-width: 1920px)': {
      margin: '16px 0 40px 0',
    },
  },

  orText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '600',
    textAlign: 'center',
    margin: '16px 0',
    '@media (min-width: 1920px)': {
      margin: '24px 0',
    },
  },

  itemTitle: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '19px',
    color: Colors.TextPrimary,
    marginRight: '8px',
    whiteSpace: 'nowrap',
  },
  itemText: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    color: Colors.TextPrimary,

    '& .text-style': {
      '& p': {
        lineHeight: '19px',
      },
    },
  },


});


export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    display: 'flex',
    marginTop: 0,
    marginLeft: '8px',
  },
  switchBase: {
    paddingTop: '2.2px',
    padding: 3,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: Colors.ThemeBlue,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop: 0,
    boxShadow: 'none',
  },
  track: {
    border: 'none',
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
  },
  checked: {},
}))(Switch);
