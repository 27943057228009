import { call, put, takeLatest } from 'redux-saga/effects';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import {
  CREATE_VENDOR_PROSPECTS,
  DELETE_VENDOR_PROSPECTS,
  EDIT_VENDOR_PROSPECTS,
  GET_VENDOR_PROSPECTS,
  GET_VENDOR_PROSPECTS_SUCCESS,
} from './vendorProspects.types';
import { vendorProspectsService } from './vendorProspects.service';

function* getVendorProspects({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  if (payload?.load !== 'noLoad') {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
  }
  try {
    const res = yield call(vendorProspectsService.getVendorProspectsService, payload?.params);
    yield put({
      type: GET_VENDOR_PROSPECTS_SUCCESS,
      payload: res.data,
    });
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* deleteVendorProspects({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(vendorProspectsService.deleteVendorProspectsService, payload?.id);
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* createVendorProspects({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(vendorProspectsService.createVendorProspectsService, payload?.body);
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}

function* editVendorProspects({ payload, type }) {
  yield put(httpRequestsOnSuccessActions.removeSuccess(type));
  yield put(httpRequestsOnErrorsActions.removeError(type));
  yield put(httpRequestsOnLoadActions.appendLoading(type));
  try {
    yield call(vendorProspectsService.editVendorProspectsService, payload?.id, payload?.body);
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.appendSuccess(type));
  } catch (err) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
  } finally {
    yield put(httpRequestsOnLoadActions.removeLoading(type));
  }
}


export const watchVendorProspects = function* watchVendorProspectsSaga() {
  yield takeLatest(GET_VENDOR_PROSPECTS, getVendorProspects);
  yield takeLatest(DELETE_VENDOR_PROSPECTS, deleteVendorProspects);
  yield takeLatest(CREATE_VENDOR_PROSPECTS, createVendorProspects);
  yield takeLatest(EDIT_VENDOR_PROSPECTS, editVendorProspects);
};