import {
    activateMc,
    assignMs,
    createMc,
    deleteAssignedMs,
    editMc,
    getAgentAssignedMcs,
    getAssignedMcs,
    getAssignedMs,
    getBranchAssignedMs,
    getMcById,
    getMcs, getOfficeAssignedMcs,
    inactivateMc,
    editTermsAndConditionsMc,
    setDefaultTermsAndConditionsMc
} from "./mc.action";

export {mcReducer} from './mc.reducer';
export {watchMc} from './mc.saga';

export const mcActions = {
    /** Create, Edit Mc */
    createMc,
    editMc,
    /** End */

    /** Get Mcs */
    getMcs,
    getMcById,
    getAssignedMcs,
    /** End */

    /** Activate or Inactivate Mc */
    activateMc,
    inactivateMc,
    /** End */

    /** Office Assigned Mc */
    assignMs,
    getOfficeAssignedMcs,
    getAgentAssignedMcs,
    getBranchAssignedMs,

    getAssignedMs,
    deleteAssignedMs,
    /** End */

  editTermsAndConditionsMc,
  setDefaultTermsAndConditionsMc
}

