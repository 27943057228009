import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FindLoad, Images, isFactored, usePrimaryModal } from 'utils';
import { loadActions } from 'store';
import { Loader } from 'components';
import { FactoredLoadDetails } from '../factored';
import { CreateLoadById } from './createLoadById';
import { loadsStyle } from './styles';

const ACTION_TYPE = 'GET_LOAD_BY_ID';

export const LoadDetailSwitcher = ({ loadId, groupId }) => {
  const { loadById } = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const classes = loadsStyle();
  const loader = FindLoad(ACTION_TYPE);
  const dispatch = useDispatch();
  const {closePrimary} = usePrimaryModal();
  const factored = isFactored ? true : loadById?.isFactored

  useEffect(() => {
    return () => {
      dispatch(loadActions.removeLoadById());
    }
  }, []);

  useEffect(() => {
    if(loadId || loadById?.id) {
      dispatch(loadActions.getLoadById(loadId));
      const interval = setInterval(() => {
        dispatch(loadActions.getLoadById(loadId, 'noLoad'));
      }, 30000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div className={classes.loadModalWrapper}>
      {loader?.length ?
        <Loader height={'90vh'} />
        :
        <div>
          <div className={classes.loadModalHeader}>
            <p>{loadById?.displayId ? `Load/${loadById?.displayId}` : ''} </p>
            <div className="align-center" style={{ gap: 20 }}>
              {/*<button className="reset-btn" >*/}
              {/*  <img src={Images.fullScreen} alt={'fullScreen'} />*/}
              {/*</button>*/}
              <button className="reset-btn" onClick={closePrimary}>
                <img src={Images.closeBtn} alt={'print'} />
              </button>
            </div>
          </div>
          <div style={{ height: 95 }} />
          {factored ? (
            <FactoredLoadDetails loadId={loadId} />
          ) : (
            <CreateLoadById groupId={groupId} loadId={loadId}  />
          )}
        </div>
      }
    </div>
  );
};