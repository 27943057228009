import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Line, MinLoader } from 'components';
import { officeActions } from 'store';
import { AntSwitch, Colors, FindLoad, PermissionsList, RolePermission } from 'utils';
import { officesInfoFragments } from './styles';
import { directories } from './constant';

const ACTION_OFFICE_BY_ID = 'GET_OFFICE_BY_ID';

export const DirectoryAccess = () => {
  const [directory, setDirectory] = useState('');
  const [directoryStatuses, setDirectoryStatuses] = useState(null);
  const { directoryAccess } = useSelector((state) => ({
      directoryAccess: state.offices.officeById?.directoryAccess,
    }),
  );
  const dispatch = useDispatch();
  const params = useParams();
  const classes = officesInfoFragments();
  const getOfficeLoad = FindLoad(ACTION_OFFICE_BY_ID);

  useEffect(() => {
    if (directoryAccess && !directoryStatuses) {
      setDirectoryStatuses(directoryAccess);
    }
  }, [directoryAccess]);

  const changeStatuses = (path) => {
    const directoryStatusesSend = {
      ...directoryStatuses,
    };

    directoryStatusesSend[path] = !directoryStatusesSend[path];
    setDirectory(path);
    setDirectoryStatuses(directoryStatusesSend);
    dispatch(officeActions.changeDirectoryAccess(params?.id, directoryStatusesSend));
  };

  return (
    <div className={classes.lineWrapper} style={{ width: '100%' }}>
      <Line height={'100px'} />
      <div className={classes.directoryItemWrapper}>
        {directories.map(({ name, directoryName }) => (
          <div className={classes.directoryItem}>
            <p className={classes.directoryItemText}>
              {name}
            </p>

            {getOfficeLoad && directory === directoryName ?
              <MinLoader
                margin={'0'}
                color={Colors.ThemeBlue}
                position={'static'}
              />
              :
              <div style={{ opacity: RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) ? 1 : 0.5 }}>
                <AntSwitch
                  name={directoryName}
                  onClick={() => changeStatuses(directoryName)}
                  checked={!!directoryAccess?.[directoryName]}
                  disabled={!RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code])}
                />
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

