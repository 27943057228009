import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import PlaceIcon from '@material-ui/icons/Place';
import { inputsStyle } from './styles';
import { Colors, CountryList, useGlobalStyles } from 'utils';
import axios from 'axios';
import { ValidationInput } from './validationInput';
import { SelectInput } from './selectInput';
import { TextField } from '@material-ui/core';
import { ErrMessage } from '../messages';
import { InputMinLoader } from './inputMiniLoader';

export const FormatAddress = ({
                                selectedAddress,
                                setCurrentAddress,
                                oneInput,
                                placeholder,
                                disabled,
                                errorBoolean,
                                noBlue,
                                noSuite,
                                column,
                                notRequired,
                                netValue,
                                value,
                              }) => {
  const classes = inputsStyle();
  const globalStyles = useGlobalStyles();

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    city: '',
    country: '',
    formattedAddress: '',
    lat: '',
    lng: '',
    state: '',
    street: '',
    zip: '',
  });

  const handleSelect = async (value, ev) => {
    setLoading(true);
    await axios.post(`/address`, { address: value }, { auth: true }).then((response) => {
      const params = {
        ...inputs,
      };
      params['formattedAddress'] = response.data.formattedAddress;
      params['lat'] = response.data.lat;
      params['lng'] = response.data.lng;
      params['city'] = response.data.city;
      params['country'] = response.data.country;
      params['state'] = response.data.state;
      params['street'] = response.data.street;
      params['zip'] = response.data.zip;
      if (netValue) params.netValue = response?.data.formattedAddress;
      setCurrentAddress(params);
      setInputs(params);
    })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (value && !inputs?.netValue) {
      setInputs(prev => ({
        ...prev,
        netValue: value,
      }));
    }
  }, [value]);

  useEffect(() => {
    if (selectedAddress) {
      setInputs(selectedAddress);
    }
  }, [selectedAddress]);

  const handleChange = (e, name) => {
    const currentName = e?.target?.name ? e.target.name : name;
    const value = name === 'street' ? e : e?.target?.value;
    const params = {
      ...inputs,
    };
    params[currentName] = value;
    setInputs(params);

    let formattedAddress = '';
    if (params?.street) formattedAddress += `${params.street}, `;
    if (params?.city) formattedAddress += ` ${params.city}, `;
    if (params?.state) formattedAddress += ` ${params.state}, `;
    if (params?.zip) formattedAddress += ` ${params.zip}, `;
    if (params?.country) formattedAddress += ` ${params.country}`;
    params['formattedAddress'] = formattedAddress;

    if (netValue) params.netValue = value;

    setCurrentAddress(params);
  };

  const addressIsValid = errorBoolean ? !inputs?.street ? 'street' : !inputs?.city ? 'city' : !inputs?.country ? 'country' : '' : ' ';

  return (
    <div style={{ position: 'relative' }}>
      <div className={column ? 'flex-column' : 'flex'}>
        <PlacesAutocomplete
          value={inputs?.netValue ? inputs?.netValue : inputs?.street ? inputs?.street : ''}
          onChange={(e) => handleChange(e, 'street')}
          onSelect={(ev) => handleSelect(ev)}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loadSuggestions }) => {
            return (
              <div className={!oneInput ? globalStyles.addressSimpleInput : globalStyles.simpleInputFull}>
                <TextField
                  label={placeholder ? placeholder : 'Physical Address*'}
                  value={inputs?.netValue ? inputs?.netValue : inputs?.street ? inputs?.street : ''}
                  variant='outlined'
                  fullWidth
                  className={
                    noBlue ? classes.inputTextField :
                      selectedAddress?.street || inputs?.netValue ? globalStyles.inputTextFieldBlue : classes.inputTextField}
                  {...getInputProps({
                    disabled: disabled,
                  })}
                  error={addressIsValid === 'street'}
                  InputProps={{
                    endAdornment: loading && <InputMinLoader />,
                  }}

                />
                <div style={{ position: 'relative' }}>
                  <ErrMessage text={addressIsValid === 'street' ? 'Street is required' : ' '} />
                </div>

                <div style={oneInput ? { position: 'relative' } : {}}>
                  {loadSuggestions && <div>Loading...</div>}

                  <div className={classes.valuesContainer}>
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                      const style = { margin: '15px', cursor: 'pointer' };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PlaceIcon style={{ color: Colors.ThemeGreen }} />
                            <span className={classes.searchfAddressDescriptionText}>{suggestion.description}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }}
        </PlacesAutocomplete>

        {!noSuite &&
          <ValidationInput
            className={(!noBlue && selectedAddress?.unitNumber) && globalStyles.inputTextFieldBlue}
            style={globalStyles.addressSimpleInput}
            variant={'outlined'}
            name={'unitNumber'}
            type={'text'}
            label={'Suite'}
            onChange={handleChange}
            value={inputs?.unitNumber}
            disabled={disabled}
          />
        }

        {!oneInput &&
          <div className={classes.inputs}>
            <ValidationInput
              className={selectedAddress?.city && globalStyles.inputTextFieldBlue}
              style={globalStyles.addressSimpleInput}
              variant={'outlined'}
              name={'city'}
              type={'name'}
              label={notRequired ? 'City' : 'City*'}
              onChange={handleChange}
              value={inputs?.city}
              loader={loading}
              disabled={disabled}
              typeError={addressIsValid === 'city' ? 'City is required' : ''}
            />
            <ValidationInput
              className={selectedAddress?.state && globalStyles.inputTextFieldBlue}
              style={globalStyles.addressSimpleInput}
              variant={'outlined'}
              name={'state'}
              type={'name'}
              label={'State'}
              onChange={handleChange}
              value={inputs?.state}
              loader={loading}
              disabled={disabled}
            />
            <SelectInput
              className={selectedAddress?.country && globalStyles.inputTextFieldBlue}
              style={globalStyles.addressSimpleInput}
              name={'country'}
              label={notRequired ? 'Country' : 'Country*'}
              handleSelect={handleChange}
              loader={loading}
              value={inputs?.country}
              list={CountryList}
              disabled={disabled}
              typeError={addressIsValid === 'country' ? 'Country is required' : ''}
              showErrorAlways={true}
            />
            <ValidationInput
              className={selectedAddress?.zip && globalStyles.inputTextFieldBlue}
              style={globalStyles.simpleInputNoRight}
              variant={'outlined'}
              name={'zip'}
              type={'text'}
              label={'Zip Code'}
              onChange={handleChange}
              value={inputs?.zip}
              loader={loading}
              disabled={disabled}
            />
          </div>
        }
      </div>
    </div>
  );
};