import React from 'react';
import { DateRow, PriceRow, TextRow, TypeRow, LinkRow } from 'components';
import { LoadDetailSwitcher } from '../loads';

export const payrollHead = [
  {
    name: 'loadDisplayId',
    title: 'Load ID',
    searchKey: 'LOAD_DISPLAY_ID',
    width: '110px',
    icon: 'date',
    custom: false,
  },
  { name: 'createdDate', title: 'Date', icon: 'date', type: 'date' },
  {
    name: 'type', title: 'Type',
    filterList: [
      { name: 'All', id: 'ALL' },
      { name: 'Credit', id: 'CREDIT' },
      { name: 'Charge', id: 'CHARGE' },
      { name: 'Gross Profit', id: 'GROSSPROFIT' },
      { name: 'Service Fee', id: 'SERVICEFEE' },
      { name: 'Other', id: 'OTHER' },
    ],
  },
  { name: 'amount', title: 'Amount', custom: false },
  { name: 'agent', nameRow: 'firstName', title: 'Agent', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  { name: 'description', title: 'Description', custom: false, smallSize: 6 },
  { name: '', title: 'Method', custom: false },
];

export const payrollHeadAgent = [
  { name: 'id', title: 'Load ID', searchKey: 'LOAD_DISPLAY_ID', width: '110px', icon: 'date', custom: false },
  { name: 'date', title: 'Date', icon: 'date' },
  {
    name: 'type', title: 'Type',
    filterList: [
      { name: 'All', id: 'ALL' },
      { name: 'Credit', id: 'CREDIT' },
      { name: 'Charge', id: 'CHARGE' },
      { name: 'Gross Profit', id: 'GROSSPROFIT' },
      { name: 'Other', id: 'OTHER' },
    ],
  },
  { name: 'amount', title: 'Amount', custom: false },
  { name: 'agent', title: 'Agent', custom: false },
  { name: 'office', title: 'Office', custom: false },
  { name: 'branch', title: 'Branch', custom: false },
  { name: 'description', title: 'Description', custom: false, smallSize: 6 },
  { name: '', title: 'Method', custom: false },
];

export const payrollBody = (openPrimary) => [
  // {rowText: (item) =>  <LinkRow id={ item?.loadDisplayId} anotherLink={`/load/${item?.load}`} target={true}/>},
  {
    rowText: (item) =>
      item?.loadDisplayId ?
        <button
          className="link-btn"
          onClick={() => openPrimary(<LoadDetailSwitcher loadId={item?.load} />)}
        >
          {item?.loadDisplayId}
        </button>
        : 'N/A',
  },
  { rowText: (item) => <DateRow date={item?.createdDate} /> },
  {
    rowText: (item) => <TypeRow text={
      item?.type === 'GROSSPROFIT' ? 'Gross Profit' :
        item?.type === 'SERVICEFEE' ? 'Service Fee' :
          item?.type === 'OFFICE_EXPENSE' ? 'Office Expense' :
            item?.type
    } />,
  },
  {
    rowText: (item) => <div className={item?.amount < 0 ? 'negative-price' : 'positive-price'}><PriceRow
      info={item?.amount} /></div>,
  },
  {
    rowText: (item) => <TextRow name={item?.agent ? `${item?.agent?.firstName} ${item?.agent?.lastName}` : 'Not Set'}
                                textWidth={12} />,
  },
  { rowText: (item) => <TextRow name={item?.office ? item?.office?.name : 'Not Set'} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.branch ? item?.branch?.name : 'Not Set'} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.description ? item?.description : 'Not Set'} textWidth={12} /> },
  { rowText: (item) => <TypeRow text={item?.method ? item?.method : 'N/A'} textWidth={12} /> },
];

export const ACTION_TYPE = 'GET_PAYROLLS';