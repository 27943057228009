import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FindSuccessItem, useGlobalStyles, usePrimaryModal } from 'utils';
import { FullTable } from 'components';
import { loadsHead, ACTION_TYPE, loadsBody } from './constants';
import { httpRequestsOnSuccessActions, loadActions } from 'store';
import { LoadDetailSwitcher, RENDER_LOADS } from '../../../../pages';

export const CarrierLoadsTable = ({}) => {
  const { carrierLoads } = useSelector((state) => ({
      carrierLoads: state.loads.carrierLoads,
    }),
  );
  const globalStyle = useGlobalStyles();
  const history = useHistory();
  const info = history?.location?.state;
  const dispatch = useDispatch();
  const params = useParams();
  const { openPrimary } = usePrimaryModal();
  const renderLoads = FindSuccessItem(RENDER_LOADS);

  const renderParams = ( ) => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  }

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    dispatch(loadActions.getCarrierLoads(params.id, { ...filteredInfo }, loader));
  };

  useEffect(() => {
    handleGet('loading');
  }, [info]);

  useEffect(() => {
    if (renderLoads) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_LOADS))
      handleGet('noLoad');
    }
  }, [renderLoads]);

  // useEffect(() => {
  //   const filteredInfo = {
  //     ...info,
  //   };
  //   filteredInfo.skip = info?.skip ? info?.skip : 0;
  //   filteredInfo.limit = 100;
  //   delete filteredInfo.page;
  //   delete filteredInfo.activeTab;
  //   dispatch(loadActions.getCarrierLoads(params.id, { ...filteredInfo }));
  // }, [info]);

  return (
    <div style={{ marginBottom: '16px' }} className={globalStyle.tableWrapperNoShadow}>
      <div style={{ margin: '16px 0' }}>
        <FullTable
          height={'medium'}
          head={loadsHead}
          body={loadsBody}
          loadingType={ACTION_TYPE}
          list={carrierLoads?.loads}
          listCount={carrierLoads?.count}
          handleClick={(item) =>
            openPrimary(<LoadDetailSwitcher loadId={item?.id} />)
            // history.push(`/load/${item.id}`)
          }
          noText={'Loads'}
          dateFilter={true}
          startEnd={true}
          clickType={'item'}
        />
      </div>
    </div>
  );
};
