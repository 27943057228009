export {
    Colors,
    Shadow,
    Backgrounds,
    useGlobalStyles,
    useGlobalTextStyles,
    useGlobalText,
    createInputsWrapper,
    AntSwitch,
    globalModals
} from './globalStylesUi';
export {Images} from './images'
export {EmailValidator, PasswordValidator} from './validation'
export {SettingsAndLoads, ProfileRouters, AllRouting} from './routers'
export {
    PermissionsList,
    paginate,
    filterByFirstName,
    initAxiosInterceptors,
    activeInactive,
    searchByType,
    sortByAz,
    ToastSuccess,
    ToastFail,
    FindSuccess,
    FindSuccessItem,
    FindLoad,
    FindError,
    FindErrorItem,
    SavePage,
    SendPageSave,
    PascalCase,
    RolePermission,
    officeManager,
    branchManager,
    textResizer,
    format,
    useModal,
    AllModals,
    ModalProvider,
    makeCapitalize,
    DownloadFile,
    SaveParams,
    AllSecondaryModals,
    SecondaryModalProvider,
    useSecondaryModal,
    usePrimaryModal,
    PrimaryModal,
    PrimaryModalProvider,
} from './hooks'
export {ErrorText} from './errorTexts'
export {CountryList} from './jsons'
export {ACCOUNT_ENUMS, docTypes, isNotEmpty, userType, userInfo, CheckEdit, SubmittedLoadStatus, RenderStatus, Status, RenderEquipmentType, isAgent, isAdmin, isFactored} from './constants'
export const fileMaxSize = 4 * 1024 * 1024;
