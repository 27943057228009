import React, {useState, Fragment, useContext, createContext} from 'react';
import {CloseButton, SimpleModal} from 'components';
export const PrimaryModalContext = createContext();
export const PrimaryModalProvider = ({children}) => {
  const [activeModal, setActiveModal] = useState('');
  const [params, setParams] = useState({});
  return (
    <Fragment>
      <PrimaryModalContext.Provider
        value={{
          activeModal,
          setActiveModal,
          params,
          setParams,
        }}>
        {children}
      </PrimaryModalContext.Provider>
    </Fragment>
  );
};
export const usePrimaryModal = () => {
  const {setActiveModal, params, setParams} = useContext(PrimaryModalContext);
  return {
    openPrimary: ( params = {}) => {
      setActiveModal(true);
      setParams(params);
    },
    closePrimary: () => {
      setActiveModal('');
      setParams({});
    },
    params,
  };
};
export const PrimaryModal = () => {
  const {activeModal} = useContext(PrimaryModalContext);
  const {closePrimary, params} = usePrimaryModal();
  return (
    <SimpleModal
      handleOpenClose={closePrimary}
      openDefault={activeModal ? activeModal : false}
      content={
        <div style={{outline:'none'}} className='modal-wrapper-style'>
          {/*<div className='modal-header-style'>*/}
          {/*  <CloseButton handleCLic={closePrimary}/>*/}
          {/*</div>*/}
          <div className='load-modal-body-style'>
            {params}
          </div>
        </div>
      }
    />
  );
};