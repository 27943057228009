import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions, loadActions } from 'store';
import { Router } from 'root';
import { TopBar, LeftBar, navBarStyles } from './core';
import { useTheme } from '@material-ui/core/styles';
import { isFactored, userType } from '../../utils';
import { FactoredRouter } from '../../root/factoredRouter';

export const MenuBar = ({}) => {
  const openInfo = sessionStorage.getItem('open');
  const dispatch = useDispatch();
  const classes = navBarStyles(),
    theme = useTheme(),
    [open, setOpen] = React.useState(openInfo ? true : true),
    handleDrawerClose = () => {
      if (!open) {
        sessionStorage.setItem('open', 'true');
      } else {
        sessionStorage.removeItem('open');
      }
      setOpen(!open);
    };

  useEffect(() => {
    dispatch(authActions.getMyAuth());

    if (userType === 'ADMIN') {
      dispatch(loadActions.getLoadTotals());
      setInterval(() => {
        dispatch(loadActions.getLoadTotals());
      }, 30000);
    }
  }, []);


  return (
    <div className={classes.root}>
      <TopBar open={open} handleClick={handleDrawerClose} />
      <LeftBar
        handleDrawerClose={handleDrawerClose}
        open={open}
        theme={theme}
      />
      <main className={classes.content}>
        {isFactored ?
          <FactoredRouter />
          :
          <Router />
        }
      </main>
    </div>
  );
};
