import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import {
  Colors,
  createInputsWrapper,
  ErrorText,
  FindError, RenderEquipmentType,
  useGlobalStyles,
  useGlobalText,
} from 'utils';
import {
  Circle,
  CreateChancel,
  EditSaveButtons,
  ErrMessage,
  Line,
  MinLoader,
  SelectTypeAutocomplete,
  ValidationInput,
  Download, SimpleModal,
} from 'components';
import { createLoadStyle } from './styles';
import {
  carrierActions,
  equipmentActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
  loadActions,
  LoadServices,
} from 'store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CANCELLED, PENDING } from '../../../../pages/accounting/constants';
import { CarrierEmail } from './common';
import moment from 'moment';
import { LOAD_TYPES } from './constants';
import { RENDER_LOADS, vendorEnums } from 'pages';

export const RequestACarrier = ({ destination, handleChangeScreen, carriers, tonu }) => {
  const { info, equipmentList } = useSelector((state) => ({
    equipmentList: state.equipment.equipmentList,
    info: state.loads.loadById,
  }));
  const globalScreens = createInputsWrapper();
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [disables, setDisabled] = useState(info && info?.carrier?.id ? true : tonu === true);
  const [inputs, setInputs] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadAwait, setLoadAwait] = useState(false);
  const [open, setOpen] = useState(false);
  const err = FindError('NOT_CARRIER');
  const awaitInfo = info?.awaitingCarrier ? info?.awaitingCarrier === false : true;
  const [carrierFiltered, setCarrierFiltered] = useState([]);
  const currentVendor = carriers?.find((i) => i?.id === inputs?.carrierId);
  const checkEditBtn = info?.status === CANCELLED ? false : tonu === true ? true : info?.carrier?.id;
  const checkRequiredEquipment = currentVendor?.type === vendorEnums.FACTORED || currentVendor?.type === vendorEnums.VENDOR || currentVendor?.type === vendorEnums.INHOUSE_PROFILE;

  const selectedVendor = info?.carrier?.id ? [
    {
      docketNumber: info?.carrier?.mc,
      companyName: info?.carrier?.name,
      id: info?.carrier?.id,
    },
  ] : [];


  useEffect(() => {
    if (carriers?.length) {
      const currentCarrier = info?.carrier?.id && carriers?.find((i) => i?.id === info?.carrier?.id);
      const newList = carriers?.filter((i) =>  {
        if(i?.doNotLoad === false && (i?.rmisRequired ? i?.rmisStatus === 'ACTIVE' : true)){
          return i
        }
      })

      const list =
        currentCarrier?.id ? newList :
        info?.carrier?.id ? [...newList, {
            docketNumber: info?.carrier?.mc,
            companyName: info?.carrier?.name,
            id: info?.carrier?.id,
          }]
          :
          newList;
      setCarrierFiltered(list);
    }
  }, [carriers]);

  useEffect(() => {
    if (info?.carrier?.id) {
      getEquipments(info?.carrier?.id);
      const carrierInfo = {
        carrierId: info.carrier.id,
        rate: info.carrierRate,
        instruction: info.carrierInstruction,
      };
      info?.carrierReference ? carrierInfo.carrierReference = info?.carrierReference : '';
      info?.equipment?.id ? carrierInfo.equipmentId = info?.equipment?.id : '';
      setInputs(carrierInfo);
    }
  }, []);

  const getEquipments = (id) => {
    dispatch(equipmentActions.getEquipments({ carrier: id, favorites: false, assigned: false, status: 'ACTIVE' }));
  };

  const handleChange = e => {
    if (e.target.name === 'carrierId') {
      getEquipments(e.target.value);
      const params = { ...inputs };
      delete params.equipmentId;
      setInputs(params);
      error === 'equipmentId' && setError('');
    }
    if (e.target.name === 'rate') {
      if (+e.target.value >= 0) {
        setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      }
    } else {
      setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
    error === e.target.name && setError('');
    error === 'noLoadSub' && setError('');
    err.length && dispatch(httpRequestsOnErrorsActions.removeError('NOT_CARRIER'));
  };


  const handleCreate = async () => {

    const checkEquipment =
      info?.isTonu === true ? true :
        checkRequiredEquipment ? true :
          inputs?.equipmentId;
    const carrierRate = inputs?.rate === 0 ? '0' : inputs?.rate;

    if (inputs?.carrierId && carrierRate && checkEquipment) {
      const list = {
        ...inputs,
      };
      list.rate = +inputs.rate;
      list.instruction = inputs?.instruction ? inputs?.instruction : null;
      list.carrierReference = inputs?.carrierReference ? inputs?.carrierReference : null;
      list.equipmentId = inputs?.equipmentId ? inputs?.equipmentId : null;

      setLoader(true);
      try {
        await LoadServices.AssignCarrier(info?.id, list);
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
        dispatch(loadActions.getLoadById(info?.id, 'noLoad'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess('REQUEST_CARRIER'));
        setLoader(false);
        handleChangeScreen();
        setDisabled(true);
      } catch (e) {
        setLoader(false);
      }
    } else {
      setError(
        !inputs.carrierId ? 'carrierId' :
          !inputs.rate ? 'rate' :
            info?.isTonu === true ? '' :
              checkRequiredEquipment ? '' :
                !inputs.equipmentId ? 'equipmentId' :
                  '',
      );
    }
  };

  const handleAwait = async () => {
    setLoadAwait(true);
    try {
      await LoadServices.AwaitCarrier(info?.id);
      dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      dispatch(loadActions.getLoadById(info?.id, 'noLoad'));
      dispatch(httpRequestsOnSuccessActions.appendSuccess('AWAIT_CARRIER'));
      setLoadAwait(false);
    } catch (e) {
      setLoadAwait(false);
    }
  };

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const renderValue = (item) => {
    return `${RenderEquipmentType(item?.equipmentType)} ${item?.trailerLength ? `(${item?.trailerLength}\`)` : ''}${item?.originCity || item?.originState ? ' - ' : ''}${item?.originCity ? `${item?.originCity}${item.originCity && item.originState ? ',' : ''} ` : ''}${item?.originState ? item?.originState : ''}${item?.availableDate ? ` - ${moment.utc(item?.availableDate).format('MM/DD/YYYY')}` : ''}`;
  };

  const handleSearchVendor = (e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        dispatch(carrierActions.getCarriers({
          statuses: ['ACTIVE'],
          searchKey: 'companyName',
          searchValue: e.target.value,
        }));
      }, 500);
    }
  };

  const handleChancel = ( ) => {
    setDisabled(true)
    setError('')
    if(info?.carrier?.id){
    getEquipments(info?.carrier?.id);
    const carrierInfo = {
      carrierId: info.carrier.id,
      rate: info.carrierRate,
      instruction: info.carrierInstruction,
    };
    info?.carrierReference ? carrierInfo.carrierReference = info?.carrierReference : '';
    info?.equipment?.id ? carrierInfo.equipmentId = info?.equipment?.id : '';
    setInputs(carrierInfo);
    }
  }

  return (
    <div>
      <div className={classes.titleAndSwitcher}>
        <div className={globalStyles.centerItem}>
          <Circle number={4} back={Colors.ThemeAqua} />
          <p className={globalText.title}>Request a Vendor</p>
        </div>
        <div style={{ display: 'flex' }}>
          {info?.status === PENDING && tonu !== true && !info?.carrier?.id && awaitInfo &&
            <Button
              onClick={handleAwait}
              className={classes.awaitCarrier}>
              {loadAwait === true ?
                <MinLoader margin={'0'} color={Colors.TextWhite} />
                :
                'Awaiting Carrier'}
            </Button>
          }
          {
            checkEditBtn &&
            <EditSaveButtons
              type={'REQUEST_CARRIER'}
              handleChancel={handleChancel}
              handleSetEdit={() => setDisabled(false)}
              handleSaveInfo={handleCreate}
              tableType={'load'}
              loadLoading={loader}
            />
          }
        </div>
      </div>
      <div className={globalStyles.flexAndFullWidth}>
        {destination &&
          <div className={'MuiAccordionDetails-root'}>
            <Line height={destination === true ? '290px' : '160px'} />
          </div>
        }
        <div style={destination === false ? { width: '100%', marginLeft: '48px' } : { width: '100%' }}>
          <div className={globalScreens.basicInfoInputs} style={{ marginTop: '24px' }}>
            <div className={classes.basicInfoInputsWrapper}>
              <SelectTypeAutocomplete
                loadType={'GET_CARRIERS'}
                style={globalStyles.simpleInput}
                name={'carrierId'}
                label={'companyName'}
                type={'docketNumber'}
                title={'Vendor/MC*'}
                handleSelect={handleChange}
                handleType={handleSearchVendor}
                defaultValue={inputs.carrierId}
                list={
                  info && !carrierFiltered?.length ? selectedVendor :
                    carrierFiltered
                }
                disabled={info?.status === PENDING ? disables : true}
                error={error}
              />
              <div className={classes.carrierRate}>
                <ValidationInput
                  style={globalStyles.simpleInput}
                  className={inputs.rate && globalStyles.inputTextFieldBlue}
                  variant={'outlined'}
                  name={'rate'}
                  label={'Rate*'}
                  type={'number'}
                  typeError={error === 'rate' && ErrorText.field}
                  onChange={handleChange}
                  value={inputs.rate === 0 ? '0' : inputs.rate}
                  disabled={info?.status === PENDING ? disables : true}
                />
              </div>

              <ValidationInput
                style={globalStyles.simpleInput}
                className={inputs.carrierReference && globalStyles.inputTextFieldBlue}
                variant={'outlined'}
                name={'carrierReference'}
                label={'Vendor Reference'}
                type={'text'}
                typeError={error === 'carrierReference' && ErrorText.field}
                onChange={handleChange}
                value={inputs?.carrierReference}
                disabled={disables}
              />

              {!checkRequiredEquipment &&
                <>
                  {equipmentList?.items?.length ?
                    <SelectTypeAutocomplete
                      loadType={'GET_EQUIPMENT'}
                      style={globalStyles.simpleInput}
                      name={'equipmentId'}
                      label={'name'}
                      title={info?.isTonu === true ? 'Assign Equipment' : 'Assign Equipment*'}
                      handleSelect={handleChange}
                      defaultValue={inputs.equipmentId}
                      list={equipmentList?.items}
                      disabled={info?.status === PENDING ? disables : true}
                      typeError={error === 'equipmentId' && ErrorText.field}
                      error={error}
                      custom={(e) => renderValue(e)}
                    />
                    :
                    <SelectTypeAutocomplete
                      loadType={'GET_EQUIPMENT'}
                      style={globalStyles.simpleInput}
                      name={'equipmentId'}
                      title={info?.isTonu === true ? 'Assign Equipment' : 'Assign Equipment*'}
                      list={[]}
                      disabled={info?.status === PENDING ? disables : true}
                      typeError={error === 'equipmentId' && ErrorText.field}
                      error={error}
                    />
                  }
                </>
              }

            </div>
            <div style={{ width: '50%' }}>
              <ValidationInput
                style={classes.simpleInput}
                className={inputs.instruction ? classes.inputDescBlue : classes.inputDesc}
                variant={'outlined'}
                name={'instruction'}
                label={'Specific Instruction'}
                type={'text'}
                multiline={true}
                Length={200}
                typeError={error === 'instruction' && ErrorText.field}
                onChange={handleChange}
                value={inputs.instruction}
                disabled={disables}
              />
              <div className={classes.maxCharacters}>
                <p>Max 200 characters</p>
              </div>
            </div>
          </div>
          {err && err.length && error === 'noLoadSub' ?
            <ErrMessage type={'Pass'} text={'Please add carrier'} /> :
            ''
          }
          {info?.status !== CANCELLED && destination === false &&
            <CreateChancel
              loader={loader}
              classes={globalStyles.buttonsStyle}
              create={'Continue'}
              chancel={'Cancel'}
              onCreate={handleCreate}
            />
          }
          {destination === true &&
            <div className={classes.flexEnd} style={{ gap: '20px' }}>

              {info?.status !== PENDING &&
                <>
                  {(info?.type === LOAD_TYPES?.DRAYAGE || info?.type === LOAD_TYPES?.RAIL) &&
                    <Download
                      text={'Download DO'}
                      type={'other'}
                      fullFile={{ link: `/loads/${info?.id}/do`, name: 'do-document.pdf', file: { _id: info?.id } }}
                      customFile={true}
                    />
                  }

                  <Download
                    text={'Download BOL'}
                    type={'other'}
                    fullFile={{ link: `/loads/${info?.id}/bol`, name: 'bol-document.pdf', file: { _id: info?.id } }}
                    customFile={true}
                  />

                </>
              }

              {info?.carrierRateConPdf?.url &&
                <div className={classes.flex}>
                  <div className={classes.visibleIcon}>
                    <a href={info?.carrierRateConPdf?.url} target={'_blank'}>
                      <VisibilityIcon style={{ color: Colors.ThemeBlue }} />
                    </a>
                  </div>
                  <Download
                    text={'Download template'}
                    type={'other'}
                    fullFile={{ file: info?.carrierRateConPdf, name: 'rate-confirmation.pdf' }}
                  />
                </div>
              }
              {info?.status !== CANCELLED &&
                <button
                  onClick={() => handleOpenClose()}
                  className={classes.sendButton}
                >
                  Send Email to Carrier
                </button>
              }
            </div>
          }
        </div>
      </div>

      <SimpleModal
        openDefault={open}
        handleOpenClose={handleOpenClose}
        content={
          <CarrierEmail
            handleClose={handleOpenClose}
            loadById={info}
          />
        }
      />
    </div>
  );
};