import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { History, Loader, NoteDrawer, SimpleTabs } from 'components';
import { BillingDetails, CreateLoadTable } from 'fragments';
import { FindLoad } from 'utils';
import { Transactions } from '../../fragments/loads/transactions';
import { loadsStyle } from './styles';
import { PaymentVerificationDetails } from '../../fragments/paymentVerification';
import { PENDING } from '../accounting/constants';

const ACTION_TYPE = 'GET_LOAD_BY_ID';

export const CreateLoadById = ({ loadId, groupId }) => {
  const { loadById } = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const classes = loadsStyle();
  const history = useHistory();
  const loader = FindLoad(ACTION_TYPE);
  const [verification, setVerification] = useState(false);
  const [revision, setRevision] = useState(false);
  const time = loadById && loadById?.expiryCountFrom;
  let now = moment(new Date());
  let end = moment(time);
  let duration = moment.duration(now.diff(end));
  let hours = duration.asHours() > 0 ? 24 - duration.asHours() : Math.abs(duration.asHours());
  let minutes = duration.asMinutes();
  let min = Math.abs(minutes % 60);
  const check = hours <= 0 && (minutes < 0 ? min : 60 - min) <= 0;
  const [defaultTab, setDefaultTab] = useState('Details');
  const [tab, setTab] = useState(defaultTab ? defaultTab : 'Details');

  useEffect(() => {
    if (loadById?.id) {
      setRevision(!!loadById?.isRevision);
      setVerification(!!loadById?.isVerification);
    }
  }, [loadById]);

  useEffect(() => {
    if (history?.location?.state?.detail === 'billing') {
      setDefaultTab('Billing Details');
    }
    if (history?.location?.state?.detail === 'payableDetails') {
      setDefaultTab('Payable Details');
    }
  }, [history]);

  const tabsLabels = [
    {
      label: 'Details',
    },
    {
      label: 'Transactions',
    },
    {
      label: 'Billing Details',
    },
    {
      label: 'Payable Details',
    },
    {
      label: 'History',
    },
  ];

  const tabsContent = [
    {
      tabComponent: <CreateLoadTable bredcrump={'noBread'}/>,
      tab: 'Details',
    },
    {
      tabComponent: <Transactions load={loadById} />,
      tab: 'Transactions',
    },
    {
      tabComponent: <BillingDetails loadById={loadById} />,
      tab: 'Billing Details',
    },
    {
      tabComponent: <PaymentVerificationDetails loadById={loadById} />,
      tab: 'Payable Details',
    },
    {
      tabComponent: <History onModel={'load'} resourceId={loadById?.id} />,
      tab: 'History',
    },
  ];

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div>
      <>
        {loadById && loadById.status === PENDING && loadById?.expiryCountFrom &&
          <div className={classes.breadAndTime}>
            <p className={classes.timing}>
              {check ? 'Expired' : `Remaining ${hours > 1 ? Math.trunc(hours) : 0}h ${Math.trunc(minutes < 0 ? min : 60 - min)}m`}
            </p>
          </div>
        }

        {loadById?.id ?
          <SimpleTabs
            verification={verification || revision}
            head={<NoteDrawer title={'Load'} loadId={loadId} />}
            noBread={true}
            border={'blue'}
            height={'full'}
            tabsLabels={tabsLabels}
            tabsContent={tabsContent}
            defaultTab={tab}
            getTab={(tab) => setTab(tab)}
            useDefault={true}
          />
          :
          <CreateLoadTable groupId={groupId} />
        }

        {/*<SimpleTabs*/}
        {/*  verification={verification || revision}*/}
        {/*  head={*/}
        {/*    <div className="space-between" style={{ gap: 15 }}>*/}
        {/*      <div className={classes.loadIdWrapper}>*/}
        {/*        <img src={Images.loadFillBlue} alt={'icon'} />*/}
        {/*        <p>{loadById?.displayId} </p>*/}
        {/*      </div>*/}
        {/*      <NoteDrawer title={'Load'} />*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*  noBread={true}*/}
        {/*  border={'blue'}*/}
        {/*  height={'full'}*/}
        {/*  tabsLabels={tabsLabels}*/}
        {/*  tabsContent={tabsContent}*/}
        {/*  defaultTab={defaultTab}*/}
        {/*/>*/}

        {/*{RolePermission([PermissionsList.CANCEL_LOAD?.code], 'agentView') &&*/}
        {/*  loadById?.bill?.totalPaid === 0 && loadById?.payable?.totalPaid === 0 &&*/}
        {/*  loadById?.status !== COMPLETED &&*/}
        {/*  loadById?.status !== CANCELLED &&*/}
        {/*  loadById?.status !== DENIED &&*/}
        {/*  loadById?.status !== REJECTED &&*/}
        {/*  <div className={classes.cancelLoadWrapper}>*/}
        {/*    <button onClick={() => handleOpenCloseDel()} className={classes.cancelLoad}>*/}
        {/*      Cancel Load*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*}*/}

        {/*<SimpleModal*/}
        {/*  handleOpenClose={() => handleOpenCloseDel()}*/}
        {/*  openDefault={open}*/}
        {/*  content={*/}
        {/*    <DeleteElement*/}
        {/*      cancelButton={'Cancel'}*/}
        {/*      closeButton={'Go Back'}*/}
        {/*      modalText={'Are you sure you want to cancel this load?'}*/}
        {/*      loading={!!cancelLoader?.length}*/}
        {/*      text={'Cancel Load?'}*/}
        {/*      handleClose={() => handleOpenCloseDel()}*/}
        {/*      handleDel={handleRemoveDoc}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
      </>
    </div>
  );
};