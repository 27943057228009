import { PermissionsList, RolePermission } from 'utils';
import { CANCELLED, COMPLETED, DENIED, REJECTED } from '../../../../pages/accounting/constants';

export const LOAD_TYPES = {
  DRY: 'DRY',
  DRAYAGE: 'DRAYAGE',
  FLATBED: 'FLATBED',
  REEFER: 'REEFER',
  BOX_TRUCK: 'BOX_TRUCK',
  AIR: 'AIR',
  SEA: 'SEA',
  RAIL: 'RAIL',
  POWER: 'POWER',
  OTHER: 'OTHER',
}


export const checkCancelLoad = (loadById) => {
  return RolePermission([PermissionsList.CANCEL_LOAD?.code], 'agentView') &&
    loadById?.bill?.totalPaid === 0 && loadById?.payable?.totalPaid === 0 &&
    loadById?.status !== COMPLETED &&
    loadById?.status !== CANCELLED &&
    loadById?.status !== DENIED &&
    loadById?.status !== REJECTED;
}
export const checkCancelFactoredLoad = (loadById) => {
  return loadById?.bill?.totalPaid === 0 && loadById?.payable?.totalPaid === 0 &&
  loadById?.status !== COMPLETED &&
  loadById?.status !== CANCELLED &&
  loadById?.status !== DENIED &&
  loadById?.status !== REJECTED;
}