import {
  getVendorProspects,
  deleteVendorProspects,
  createVendorProspects,
  editVendorProspects,
} from './vendorProspects.action';

export { vendorProspectsReducer } from './vendorProspects.reducer';
export { watchVendorProspects } from './vendorProspects.saga';


export const vendorProspectsActions = {
  getVendorProspects,
  deleteVendorProspects,
  createVendorProspects,
  editVendorProspects,
};
