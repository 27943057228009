import { GET_VENDOR_PROSPECTS_SUCCESS } from './vendorProspects.types';

const initialState = {
  vendorProspects: null,
};

export const vendorProspectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDOR_PROSPECTS_SUCCESS: {
      return {
        ...state,
        vendorProspects: action?.payload
      };
    }

    default:
      return state;
  }
};