import { DateRow, PriceRow, TypeRow } from '../../../../components';
import React from "react";
import { NumericFormat } from 'react-number-format';


export const FactoredLoadSummary = ({loadById}) => {

    const renderColors = () => {
        switch (loadById?.status) {
            case 'PROCESSING':
                return {
                    color: 'rgb(0, 188, 212)',
                    background: 'rgb(0, 188, 212, 0.2)'
                }
            case 'PENDING':
                return {
                    color: 'rgb(255, 163, 48)',
                    background: 'rgb(255, 163, 48, 0.2)'
                }

            case 'INTRANSIT':
                return {
                    color: 'rgb(63, 81, 181)',
                    background: 'rgb(63, 81, 181, 0.2)'
                }

            case 'REJECTED':
                return {
                    color: 'rgb(215, 37, 37)',
                    background: 'rgb(215, 37, 37, 0.2)'
                }

            case 'COMPLETED':
                return {
                    color: 'rgb(0, 200, 81)',
                    background: 'rgb(0, 200, 81, 0.2)'
                }

            default:
                return {
                    color: '#51566D',
                    background: '#E6ECF3'
                }
        }

    }

        return (
            <div className='load-summary-wrapper'>
                <div className='load-details-wrapper'>
                    {/*<div className='summary-row-wrapper'>*/}
                    {/*    <p className='load-detail-title'>Rate Total</p>*/}
                    {/*    <p className='load-detail-subtitle'>*/}
                    {/*        <NumericFormat value={100} displayType={'text'} thousandSeparator={true} prefix={'$'}/>*/}
                    {/*    </p>*/}
                    {/*</div>*/}

                    <div className='summary-row-wrapper'>
                        <p className='load-detail-title'>Submitted Date</p>
                        <p className='load-detail-subtitle'>
                            <DateRow date={loadById?.submittedAt ? loadById?.submittedAt : null}/>
                        </p>
                    </div>
                    {loadById?.isConsignment &&
                      <div className="status-wrapper"
                           style={{ color: 'rgb(0, 188, 212)', background: 'rgb(0, 188, 212, 0.2)',marginRight: 10 }}
                      >
                          <TypeRow text={'On Consignment'} />
                      </div>
                    }
                    <div className='status-wrapper'
                         style={{color: renderColors()?.color, background: renderColors()?.background}}
                    >
                        {loadById?.status === "INTRANSIT" ? 'In Transit' :
                            loadById?.status === "CREDITCHECK" ? 'Credit Check' :
                                loadById?.status === "CONSIGNMENT_REQUEST" ? 'Consignment Request' :
                                    <TypeRow text={loadById?.status}/>
                        }
                    </div>
                </div>
            </div>
        )
    }