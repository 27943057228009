import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { SaveParams } from '../../utils/hooks/paginate';
import { useWidth } from '../dropdowns/userInputsDropdown';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export const SimpleTabs = ({
                             tabsLabels,
                             tabsContent,
                             height,
                             head,
                             verification,
                             headerWidth,
                             defaultTab,
                             getTab,
                             useDefault,
                             tabType,
                           }) => {
  const history = useHistory();
  const info = useDefault ? null : history?.location?.state;
  const tabStyle = useStyles();
  const [value, setValue] = React.useState(defaultTab);
  const screenWidth = useWidth();

  useEffect(() => {
    if (info?.activeTab) {
      setValue(info?.activeTab);
    }
  }, [info]);

  // const handleChange = (newValue) => {
  //     setValue(newValue);
  //     const anotherInfo = {
  //         ...info,
  //         activeTab: newValue
  //     }
  //     delete anotherInfo.sortType
  //     delete anotherInfo.type
  //     delete anotherInfo.status
  //     SaveParams(history, {...anotherInfo})
  // };

  const handleChange = (newValue) => {
    setValue(newValue);
    const anotherInfo = {
      ...info,
      activeTab: newValue,
    };
    delete anotherInfo.sortType;
    delete anotherInfo.type;
    delete anotherInfo.status;
    if (getTab) {
      getTab(newValue);
    } else {
      SaveParams(history, { ...anotherInfo });
    }
  };


  return (
    <div className={tabStyle.root}>
      <AppBar className={
        verification === true ? tabStyle.tabHeaderVerification :
          // tabType === 'reset' ?  tabStyle.tabHeaderReset :
          tabStyle.tabHeader
      } position="static"
      >
      {/*<AppBar className={verification === true ? tabStyle.tabHeaderVerification : tabStyle.tabHeader} position="static">*/}
        <Tabs
          value={value}
          style={headerWidth ? { width: screenWidth > 1919 ? '100%' : headerWidth } : {}}
          indicatorColor="primary"
          className={tabStyle.collor}
          aria-label="scrollable force tabs example"
          scrollButtons="on"
          variant="scrollable"
        >
          {tabsLabels &&
            tabsLabels.map((tabLabel, index) => {
              if (tabLabel) {
                return (
                  <Tab
                    key={index}
                    onClick={() => {
                      handleChange(tabLabel?.label);
                    }}
                    className={tabStyle.tabLabel}
                    label={tabLabel?.label}
                    value={tabLabel?.label}
                    {...a11yProps(tabLabel?.label)}
                  />
                );
              }
            })}
        </Tabs>
        <div>
          {head}
        </div>
      </AppBar>
      <div className={height === 'full' ? tabStyle.tabPanelFull : tabStyle.tabPanel}>
        {tabsContent &&
          tabsContent.map((tabContent, index) => {
            if (tabContent && tabContent?.tab === value) {
              return (
                <div key={index}>
                  <TabPanel
                    index={tabContent?.tab}
                    boxClassName={tabStyle?.boxStyle}
                    value={tabContent?.tab}
                  >
                    {tabContent?.tabComponent}
                  </TabPanel>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};
