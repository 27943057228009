import { makeStyles } from '@material-ui/core/styles';
import { Colors, Shadow } from 'utils';

export const inputsStyle = makeStyles(() => ({
  dateFiled: {
    marginRight: '16px',
    background: 'white',
    width: '180px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `${Colors.ThemeBorder}!important`,
    },
    '& .MuiOutlinedInput-root': {
      height: '36px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
  },

  noChip: {
    '& .MuiAutocomplete-tag': {},
  },

  borderBlue: {
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${Colors.ThemeBlue}`,
      },

      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root': {
          '.MuiSvgIcon-root': {
            fill: 'red !important',
          },
        },
      },
    },
    '& .MuiFormLabel-root':{
      color:`${Colors.ThemeBlue}`
    },
  },

  noChipLoads: {
    background: 'white',
    width: '175px',
    '@media (min-width: 1919px)': {
      width: '245px',
    },


    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiOutlinedInput-root': {
      height: '36px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-10px',
    },


    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 3px) scale(0.75) !important',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      marginTop: '-10px',
    },


    // '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':{
    //     padding:0
    // },


    '& .MuiAutocomplete-tag': {
      display: 'none',
    },
  },

  noChipTrucks: {
    background: 'white',
    width: '170px',
    marginRight: '8px',

    '@media (min-width: 1919px)': {
      width: '286px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
      minHeight: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 3px) scale(0.75) !important',
      marginTop: '-8px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      marginTop: '-4px',
    },
    '& .MuiAutocomplete-tag': {
      display: 'none',
    },
  },

  noChipTrucksBlue: {
    background: 'white',
    width: '170px',
    marginRight: '8px',

    '@media (min-width: 1919px)': {
      width: '286px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#438AFE !important',
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
      minHeight: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 3px) scale(0.75) !important',
      marginTop: '-8px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      marginTop: '-4px',
    },
    '& .MuiAutocomplete-tag': {
      display: 'none',
    },
  },


  smallDesk: {
    '@media (max-width: 1918px)': {
      display: 'block',
    },
    '@media (min-width: 1919px)': {
      display: 'none',
    },
  },

  bigDesk: {
    '@media (max-width: 1918px)': {
      display: 'none',
    },
    '@media (min-width: 1919px)': {
      display: 'block',
    },
  },

  search: {
    width: '92px',
    height: '36px',
    boxShadow: '0px 0px 6px #438AFE4D',
    borderRadius: '4px',
    background: Colors.ThemeBlue,
    color: Colors.BackgroundWhite,
    fontSize: '14px',
    border: 'none',
    marginRight: '40px',
    textTransform: 'capitalize',
    '&:hover': {
      background: Colors.ThemeBorderBlue,
    },
  },

  SignInInput: {
    width: '100%',
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      color: `${Colors.TextPrimary}`,
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: `${Colors.ThemeRed}`,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: `#9C9EA9!important`,
    },

  },
  searchfAddressDescriptionText: {
    fontSize: '16px',
    marginLeft: '8px',
    color: Colors.TextPrimary,
  },
  suggestionActive: {
    background: 'green',
    width: '200px',
    height: 'auto',
  },

  searchInputTitle: {
    display: 'flex',
    '& p': {
      fontSize: '12px',
      lineHeight: '19px',
      fontWeight: '600',
      color: Colors.TextSecondary,
      marginRight: '4px',
      '@media (min-width: 1440px)': {
        fontSize: '14px',
      },
    },
    '& span': {
      fontSize: '12px',
      lineHeight: '19px',
      fontWeight: '600',
      color: Colors.TextSecondary,
      marginRight: '4px',
      '@media (min-width: 1440px)': {
        fontSize: '14px',
      },
    },
    '& img': {
      width: '18px',
      height: '18px',
      cursor: 'pointer',
    },

  },

  searchInput: {
    width: '100%',

    '@media (max-width: 1918px)': {
      marginBottom: '8px',
    },
    '@media (min-width: 1919px)': {
      marginBottom: '16px',
    },

    '& .MuiFormLabel-root': {
      fontSize: '16px',
      color: `${Colors.TextPrimary}`,
    },

    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: `${Colors.ThemeRed}`,
    },
  },

  actionStyle: {
    width: '100%',

    '@media (max-width: 1918px)': {
      marginBottom: '8px',
    },
    '@media (min-width: 1920px)': {
      marginBottom: '16px',
    },
    '& .MuiInput-underline:before': {
      // borderBottom: "none",
    },
  },

  PasswordInput: {
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      fontSize: '16px',
      color: `${Colors.TextPrimary}`,
      opacity: 1,
    },
  },


  inputTextField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      background: 'none',
    },
  },
  inputTextFieldBlue: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBlue,
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
    '& .MuiInputLabel-outlined': {
      marginTop: '-3px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
      color: `${Colors.ThemeBlue}!important`,
    },
  },
  inputTextFieldAutoHeight: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.ThemeBorder,
    },
    '& .MuiOutlinedInput-root': {},
    '& .MuiInputLabel-outlined': {},
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -2px) scale(0.75)',
    },
  },

  searchAddress: {
    height: '48px',
    border: '1px solid #51566DB3',
    borderRadius: '4px',
    padding: '18.5px 14px',
    fontSize: '16px',
    width: '100%',
    '&:disabled': {
      background: 'none',
      borderColor: 'rgba(0, 0, 0, 0.26)',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  searchAddressFill: {
    height: '48px',
    border: `1px solid ${Colors.ThemeBlue}`,
    borderRadius: '4px',
    padding: '18.5px 14px',
    fontSize: '16px',
    width: '100%',
    '&:disabled': {
      background: 'none',
      borderColor: Colors.ThemeWhiteBlue,
      color: 'black',
    },
  },
  searchAddressError: {
    height: '48px',
    border: `1px solid ${Colors.ThemeError}`,
    borderRadius: '4px',
    padding: '18.5px 14px',
    fontSize: '16px',
    width: '100%',
    '&::placeholder': {
      color: Colors.ThemeError,
    },

  },
  errorText: {
    color: Colors.ThemeError,
    fontSize: '14px',
    fontWeight: 600,
    margin: '3px 0',
    position: 'absolute',
  },
  selectErrorText: {
    color: `${Colors.ThemeError} !important`,
    fontSize: '14px',
    fontWeight: 600,
    margin: '3px 0',
    lineHeight: 'normal',
    // position: 'absolute',
    // marginTop: '49px'
  },

  inputShrink: {
    background: 'white',
    padding: '0 6px',
  },

  valuesContainer: {
    backgroundColor: 'white',
    boxShadow: Shadow.modalShadow,
    height: 'auto',
    maxHeight: '300px',
    overflow: 'auto',
    position: 'absolute',
    zIndex: '10',
    width: '292px',
  },

  blueBorder: {
    borderColor: 'blue',
  },

  itemButton: {
    border: 'none',
    background: 'none',
    outline: 'none',
    marginTop: '-1px',
  },

  filterIcon: {
    width: '12px !important',
    height: '8px !important',
  },

  pickInputs: {
    '& div': {
      marginBottom: '6px',
      marginTop: '0',
    },
  },
  inputs: {
    display: 'contents',
    width: '100%',
  },
  calendarInput: {
    width: '163px',
    height: '36px',
    background: '#FFFFFF',
    border: '1px solid #9C9EA9',
    borderRadius: '4px',
    padding: '0 16px',
  },
  searchInputWrapper: {
    width: '266px',
    height: '36px',
    border: '1px solid #9C9EA9',
    borderRadius: '4px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '0 2px',
    '& input': {
      width: '219px',
      height: '34px',
      border: 'none',
      outline: 'none',
      color: '#9C9EA9',
      padding: '0 12px',
    },
    '& button': {
      border: 'none',
      borderLeft: '1px solid #9C9EA9',
      background: 'transparent',
      height: '100%',
    },

  },
}));


