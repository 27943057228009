import React from 'react';
import { TextToolTip } from 'components';
import { Tooltip } from '@material-ui/core';

export const EmailsAndTooltip = ({ emails }) => {
  const showFirstEmail = emails?.[0];
  return (
    <div className='emails-and-tooltip-wrapper'>
      <TextToolTip
        classNameWrapper='emails-and-tooltip-wrapper_first-box'
        text={showFirstEmail ? showFirstEmail : 'Not Set'}
        content={showFirstEmail ? showFirstEmail : ''}
      />

      {!!emails?.slice(1)?.length &&
        <Tooltip
          title={
          <div className='show-emails-tooltip'>
            {emails?.slice(1).map((email) => (
              <p className='show-emails-tooltip-text'>
                {email}
              </p>
            ))}
          </div>
        }
          className={'emails-and-tooltip-box'}
          classes={{ tooltip: 'emails-and-tooltip-content-box' }}
        >
          <div className='emails-and-tooltip-box-quantity'>
            +{emails?.slice(1)?.length}
          </div>
        </Tooltip>
      }
    </div>
  );
};

