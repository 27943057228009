import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {agentActions, carrierActions, customerActions, loadActions, mcActions, templateActions} from "store";
import {LoadDetails, CustomerCredit, Destination, RequestACarrier} from "./core";
import { Colors, FindLoad, FindSuccessItem, officeManager, useGlobalStyles, useGlobalText, userType } from 'utils';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Circle, EditSaveButtons} from "components";
import { loadTypeEnums } from '../../../fragments/loads/createLoad/core/common/constants';

const ACTION_TYPE = 'EDIT_TEMPLATE'
export const CreateLoadTable = ({ }) => {
    const {  carriersList, templateById } = useSelector((state) => ({
        carriersList: state.carriers.carriersList,
        templateById: state.templates.templateById,
    }));
    const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));
    const dispatch = useDispatch();
    const params = useParams();
    const globalInputs = useGlobalStyles();
    const globalText = useGlobalText();
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const [disables, setDisables] = useState(true);
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccessItem(ACTION_TYPE)

    useEffect(() => {
        if (success && !disables) {
            setDisables(true)
        }
    }, [success])

    useEffect(() => {
        if(templateById?.customerId){
            dispatch(customerActions.getCustomerById(templateById?.customerId))
        }
    }, [templateById])

    useEffect(() => {
        if(templateById) {
            const newList = {
                "bookedAsMC": templateById?.bookedAsMCId,
                "soldAsMC": templateById?.soldAsMCId,
                "serviceBooked": templateById?.serviceBooked,
                "serviceGiven": templateById?.serviceGiven,
                "modeBooked": templateById?.modeBooked,
                "modeGiven": templateById?.modeGiven,
                "loadType": templateById?.loadType,
                "temperature": templateById?.temperature,
                "commodity": templateById?.commodity,
                "weight": templateById?.weight,
                "mc": templateById?.mc,
                "customer": templateById?.customerId,
                "carrier": templateById?.carrierId,
                "customerRate": +templateById?.customerRate,
                "carrierRate": +templateById?.carrierRate,
            }
            templateById?.customerReference ? newList.customerReference = templateById?.customerReference : ''
            templateById?.contactName ? newList.contactName = templateById?.contactName : ''
            templateById?.description ? newList.description = templateById?.description : ''
            templateById?.ft ? newList.ft = templateById?.ft : ''
            templateById?.containerNumber ? newList.containerNumber = templateById?.containerNumber : ''
            templateById?.carrierInstruction ? newList.carrierInstruction = templateById?.carrierInstruction : ''
            templateById?.carrierReference ? newList.carrierReference = templateById?.carrierReference : ''
            setInputs(newList)
        }
    }, [templateById])

    useEffect(() => {
        dispatch(loadActions.removeSearchedList());
        // dispatch(carrierActions.getCarriers({statuses: ['ACTIVE']}));
        dispatch(agentActions.getAgents({status: 'ACTIVE'}));

        if (userType === 'ADMIN' && !templateById) {
            dispatch(mcActions.getMcs({status: 'ACTIVE'}));
        }
    }, []);

    useEffect(() => {
        if (userInfo && userType !== 'ADMIN' || !officeManager) {
            dispatch(mcActions.getAgentAssignedMcs(userInfo?.id, 'AGENT', {status: 'ACTIVE'}))
        }
    }, [])

    const handleChange = e => {
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value,}),)
        if (error === e.target.name) {
            setError('')
        }
    };

    const handleCreate = () => {
        let errDrayCheck = inputs.loadType === loadTypeEnums.DRAYAGE ? !!inputs.containerNumber : true;
        let errRailCheck = inputs.loadType === loadTypeEnums.RAIL ? !!inputs.containerNumber : true;
        let errTempCheck = (
          inputs.loadType === loadTypeEnums.REEFER ||
          inputs.loadType === loadTypeEnums.REEFER_DRAYAGE ||
          inputs.loadType === loadTypeEnums.COLD_STORAGE ||
          inputs.loadType === loadTypeEnums.RAIL_REEFER) ? (!!inputs.temperature || inputs.temperature === 0) : true;

        if (
            inputs?.bookedAsMC && inputs?.customer && inputs?.customerRate &&
            inputs.loadType && errDrayCheck && errRailCheck && errTempCheck &&
            inputs.commodity && inputs.serviceBooked && inputs.serviceGiven && inputs.modeBooked && inputs.modeGiven && inputs.weight && inputs.soldAsMC &&
            inputs.customerReference
            // && inputs.carrier && inputs.carrierRate
        ) {

            const obj = {
                "name": templateById?.name,
                "description": templateById?.description,
                "color": templateById?.color,
                "bookedAsMC": inputs.bookedAsMC,
                "customer": inputs.customer,
                "customerRate": +inputs.customerRate,
                "serviceBooked": inputs.serviceBooked,
                "serviceGiven": inputs.serviceGiven,
                "modeBooked": inputs.modeBooked,
                "modeGiven": inputs.modeGiven,
                "soldAsMC": inputs.soldAsMC,
                "loadType": inputs.loadType,
                "temperature": inputs.temperature,
                "commodity": inputs.commodity,
                "weight": inputs.weight,
                "carrier": inputs.carrier,
                "carrierRate": +inputs.carrierRate,
            }

            if (inputs?.customerReference) {
                obj.customerReference = inputs.customerReference
            }

            (inputs?.temperature || inputs?.temperature === 0) ? obj.temperature = +inputs.temperature : delete obj.temperature
            inputs?.weight ? obj.weight = inputs.weight : delete obj.weight
            inputs?.ft ? obj.ft = +inputs?.ft : delete obj.ft
            inputs?.containerNumber ? obj.containerNumber = inputs.containerNumber : delete obj.containerNumber
            inputs?.description ? obj.description = inputs.description : delete obj.description
            inputs?.contactName ? obj.contactName = inputs.contactName : delete obj.contactName
            inputs?.carrierInstruction ? obj.carrierInstruction = inputs.carrierInstruction : delete obj.carrierInstruction
            inputs?.carrierReference ? obj.carrierReference = inputs?.carrierReference : delete obj.carrierReference

            dispatch(templateActions.editTemplate(templateById?.id, obj, {}, true))

        } else {
            let errDray = inputs?.loadType === 'DRAYAGE' ? !inputs.containerNumber && 'containerNumber' : null
            let errRail = inputs?.loadType === 'RAIL' ? !inputs.containerNumber && 'containerNumber' : null
            let errTempCheck = (inputs.loadType ===
              inputs.loadType === loadTypeEnums.REEFER ||
              inputs.loadType === loadTypeEnums.REEFER_DRAYAGE ||
              inputs.loadType === loadTypeEnums.COLD_STORAGE ||
              inputs.loadType === loadTypeEnums.RAIL_REEFER) ? !inputs.temperature && 'temperature' : null;

            setError(
                !inputs?.bookedAsMC ? 'bookedAsMC' :
                    !inputs?.customer ? 'customer' :
                        !inputs?.customerRate ? 'customerRate' :


                            !inputs.loadType ? 'loadType' :
                                errDray ? errDray :
                                    errRail ? errRail :
                                        errTempCheck ? errTempCheck :
                                            !inputs.commodity ? 'commodity' :


                                                !inputs.serviceBooked ? 'serviceBooked' :
                                                    !inputs.serviceGiven ? 'serviceGiven' :
                                                      !inputs.modeBooked ? 'modeBooked' :
                                                         !inputs.modeGiven ? 'modeGiven' :


                                                                !inputs.weight ? 'weight' :
                                                                    !inputs.soldAsMC ? 'soldAsMC' :


                                                                        !inputs.customerReference ? 'customerReference' :
                                                                            // !inputs.carrierId ? 'carrierId' :
                                                                            //     !inputs.carrierRate ? 'carrierRate' :

                                                                                    ''
            )
        }
    }

    const handleCancel = () => {
        setDisables(true)
        setError('')
    }


    return (
        <div>
            <div className='flex-end'>
                <EditSaveButtons
                    type={ACTION_TYPE}
                    handleChancel={ handleCancel}
                    handleSetEdit={() => setDisables(false)}
                    handleSaveInfo={handleCreate}
                    tableType={'load'}
                    loadLoading={!!loader?.length}
                />
            </div>
            <Accordion
              expanded={true}
              // defaultExpanded={true}
            >
                <AccordionSummary
                    className={'accordion'}
                    // expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        <Circle number={1} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Customer Credit Request</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <CustomerCredit
                        info={templateById}
                        handleChange={handleChange}
                        inputs={inputs}
                        disables={disables}
                        error={error}
                        backError={''}
                    />
                </AccordionDetails>
            </Accordion>


            <Accordion expanded={true}>
                <AccordionSummary
                    className={'accordion'}
                    // expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        <Circle number={2} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Load Details</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <LoadDetails
                        info={templateById}
                        handleChange={handleChange}
                        inputs={inputs}
                        disables={disables}
                        error={error}
                        setInputs={setInputs}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={true}>
                <AccordionSummary
                    className={'accordion'}
                    // expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        <Circle number={3} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Destination</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Destination
                        info={templateById}
                        id={params.id}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={true}>
                <AccordionSummary
                    className={'accordion'}
                    // expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={globalInputs.centerItem}>
                        <Circle number={3} back={Colors.ThemeAqua}/>
                        <p className={globalText.title}>Request a Vendor</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <RequestACarrier
                        carriers={carriersList?.carriers?.filter((i) => i?.doNotLoad === false && (i?.rmisRequired ? i?.rmisStatus === "ACTIVE" : true))}
                        info={templateById}
                        handleChange={handleChange}
                        inputs={inputs}
                        disables={disables}
                        error={error}
                        setInputs={setInputs}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}