import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AntSwitch,
  Colors,
  Images, isFactored,
  PermissionsList,
  RolePermission,
  useGlobalStyles,
  useGlobalText,
  userType,
} from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, loadActions, LoadServices } from 'store';
import { CorrectionsModal, MinLoader, SimpleModal } from 'components';
import { CANCELLED } from '../../../../pages/accounting/constants';
import { RENDER_LOADS } from '../../../../pages';

export const LoadActions = ({ loadById }) => {
  const dispatch = useDispatch();
  const globalText = useGlobalText();
  const globalStyles = useGlobalStyles();
  const [actionStatuses, setActionStatuses] = useState({});
  const [openCorrection, setOpenCorrection] = useState(false);

  useEffect(() => {
    if (loadById?.id) {
      const obj = {
        doNotPay: loadById.doNotPay,
        doNotBill: loadById.doNotBill,
        isVerification: loadById?.isVerification,
        onClaim: loadById?.onClaim,
        onBond: loadById?.isBond,
        requestFuel: loadById?.requestFuel,
        isConnLoads: loadById?.isConnLoads,
      };
      setActionStatuses(obj);
    }
  }, [loadById]);
  const changeStatuses = async (e) => {
    const statuses = {
      ...actionStatuses,
    };
    statuses[e.target.name] = !statuses[e.target.name];
    setActionStatuses(statuses);

    try {
      if (e.target.name === 'onBond') {
        await LoadServices.SetToBound(loadById?.id, !actionStatuses?.onBond);
        dispatch(httpRequestsOnSuccessActions.appendSuccess('ON_BOUND'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      }
      if (e.target.name === 'onClaim') {
        await LoadServices.SetToClaim(loadById?.id, !actionStatuses?.onClaim);
        dispatch(httpRequestsOnSuccessActions.appendSuccess('ON_CLAIM'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      }
      if (e.target.name === 'doNotPay') {
        await LoadServices.DoNotPay(loadById.id, !actionStatuses?.doNotPay);
        dispatch(httpRequestsOnSuccessActions.appendSuccess('DO_NOT_PAY'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      }
      if (e.target.name === 'requestFuel') {
        await LoadServices.AllowFuel(loadById.id, !actionStatuses?.requestFuel);
        dispatch(httpRequestsOnSuccessActions.appendSuccess('ALLOW_FUEL'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      }
      if (e.target.name === 'isConnLoads') {
        await LoadServices.isConnLoads(loadById.id, !actionStatuses?.isConnLoads);
        dispatch(httpRequestsOnSuccessActions.appendSuccess('IS_CONNECTED_LOAD'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      }
      if (e.target.name === 'doNotBill') {
        await LoadServices.DoNotBill(loadById.id, !actionStatuses?.isConnLoads);
        dispatch(httpRequestsOnSuccessActions.appendSuccess('DO_NOT_BILL'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
      }

    } catch (e) {
      dispatch(httpRequestsOnErrorsActions.appendError('WENT_WRONG'));
    } finally {
      dispatch(loadActions.getLoadById(loadById.id, 'noLoad'));
    }
  };

  const handleOpenClose = async () => {
    if (loadById?.isVerification) {
      const statuses = { ...actionStatuses };
      statuses.isVerification = false;
      setActionStatuses(statuses);

      const data = { 'correction': '' };
      try {
        await LoadServices.LoadToVerification(loadById?.id, data, false);
        dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
        dispatch(loadActions.getLoadById(loadById.id, 'noLoad'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess('LOAD_VERIFICATION'));
      } catch (e) {
        dispatch(httpRequestsOnErrorsActions.appendError('WENT_WRONG'));
        setOpenCorrection(!openCorrection);
      }
    } else {
      setOpenCorrection(!openCorrection);
    }
  };

  const checkBound =
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList?.SET_BOND_ON.code], 'agentView') && !actionStatuses?.onBond ? false :
     !(RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList?.SET_BOND_OFF.code], 'agentView') && actionStatuses?.onBond)

  const checkClaim =
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList?.SET_CLAIM_ON.code]) && !actionStatuses?.onClaim ? false :
    !RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList?.SET_CLAIM_OFF.code]) && actionStatuses?.onClaim

  const checkAllowFuel =   RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList?.ADD_TRANSACTION.code],'agentView' )


  return (
    <>
      <div className="load-actions-box">
        <div className="settings-icon-title">
          <img src={Images.settings} alt="icon" />
          <p>Settings</p>
        </div>

        <div className="action-switcher-box" style={{gap: 12}}>
          {!loadById?.isFactored &&
          <div className='align-center'>
            <span className={globalText.smallSwitchText}>{'Connecting Loads'}</span>
            <AntSwitch
              name={'isConnLoads'}
              onClick={changeStatuses}
              checked={!!actionStatuses?.isConnLoads}
            />
          </div>
          }

          {loadById?.status !== CANCELLED && !loadById?.isFactored &&
            <div style={{ opacity: !checkAllowFuel ? 0.5 : 1 }} className='align-center'>
              <span className={globalText.smallSwitchText}>{'Allow Fuel'}</span>
              <AntSwitch
                disabled={!checkAllowFuel}
                name={'requestFuel'}
                onClick={changeStatuses}
                checked={!!actionStatuses?.requestFuel}
              />
            </div>
          }
          {!loadById?.isFactored &&
            <div style={{opacity: checkBound ? 0.5 : 1 }} className="align-center">
            <span className={globalText.smallSwitchText}>{'Bond'}</span>
            <AntSwitch
              disabled={checkBound}
              name={'onBond'}
              onClick={changeStatuses}
              checked={!!actionStatuses?.onBond}
            />
          </div>
          }
          <div style={{ opacity: checkClaim ? 0.5 : 1 }} className="align-center">
            <span className={globalText.smallSwitchText}>{'Claim'}</span>
            <AntSwitch
              disabled={checkClaim}
              name={'onClaim'}
              onClick={changeStatuses}
              checked={!!actionStatuses?.onClaim}
            />
          </div>

          {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SEND_LOAD_TO_VERIFICATION?.code]) && !loadById?.isFactored &&
            <div  className={globalStyles.centerItem}>
              <span className={globalText.smallSwitchText}>{'Load Verification'}</span>
              <AntSwitch
                name={'isVerification'}
                onClick={handleOpenClose}
                checked={!!actionStatuses?.isVerification}
              />
            </div>
          }

          {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.DO_NOT_PAY?.code]) &&
            <div className="align-center">
              <span className={globalText.smallSwitchText}>{'Do Not Pay'}</span>
              <AntSwitch
                name={'doNotPay'}
                onClick={changeStatuses}
                checked={!!actionStatuses?.doNotPay}
              />
            </div>
          }
          {RolePermission([PermissionsList.DO_NOT_BILL?.code]) && !loadById?.isFactored &&
            <div className="align-center">
              <span className={globalText.smallSwitchText}>{'Do Not Bill'}</span>
              <AntSwitch
                name={'doNotBill'}
                onClick={changeStatuses}
                checked={!!actionStatuses?.doNotBill}
              />
            </div>
          }
        </div>
      </div>

      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={openCorrection}
        content={
          <CorrectionsModal
            handleClose={handleOpenClose}
            info={loadById}
          />
        }
      />
    </>
  );
};