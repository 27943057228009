import axios from "axios";

export const authService = {
    /** Create, Edit Mc */

    createMcService: (body) => axios.post('/mc', body, {auth: true}),

    editMcService: (id, body) => axios.patch(`/mc/${id}`, body, {auth: true}),

    /** End */

    /** Get Mcs */

    getMcsService: ( info ) => axios.get('/mc', {auth: true, params: { ...info, status: info?.status ? info?.status : 'ACTIVE' } }),

    getMcByIdService: (id) => axios.get(`/mc/${id}`, {auth: true}),

    /** End */

    /** Activate or Inactivate Mcs */

    activateMcService: (id) => axios.patch(`/mc/${id}/activate`, null,{auth: true}),

    inactivateMcService: (id) => axios.patch(`/mc/${id}/inactivate`, null,{auth: true}),

    /** End */

    /** Office Assigned Mc */

    assignMcService: (entityId, mcId, type) => axios.post(`/mc/${mcId}/mccontrol/add/${entityId}?entityType=${type}`, null,{auth: true}),

    getAssignedMcService: (mcId, type) => axios.get(`/mc/${mcId}/mccontrol?entityType=${type}`, {auth: true}),

    getAssignedSectionMcService: (id, type, params) => axios.get(`/mc/entitymcs/${id}?entityType=${type}`, {auth: true, params: { ...params }}),



    deleteAssignedMcService: (entityId, mcId, type) => axios.delete(`/mc/${mcId}/mccontrol/remove/${entityId}?entityType=${type}`, {auth: true}),

    addOfficeDocument: (doc, id) => axios.post(`/offices/${id}/documents`, doc, {auth: true}),

    deleteOfficeDocument: (id, docId) => axios.delete(`/offices/${id}/documents/${docId}`, {auth: true}),

    editTermsAndConditionsMc: (id, data) => axios.patch(`/mc/${id}/terms`, data,{auth: true}),

    setDefaultTermsAndConditionsMc: (id, data) => axios.patch(`/mc/${id}/setTermsDefault`, {},{auth: true, params:data}),

    /** End */

    /** Mc Documents*/

    addMcDocument: (doc, id) => axios.post(`/mc/${id}/documents`, doc, {auth: true}),

    deleteMcDocument: (id, docId) => axios.delete(`/mc/${id}/documents/${docId}`, {auth: true}),

    /** End */


};
